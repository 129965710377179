.p-sales-main {
  width: 100%;
  margin: 16px auto 136px;
  padding: 0 15px 0;
  @include mq(){
    max-width: 970px;
    padding: 0 35px 0;
    margin: 64px auto 130px;
  }
  &-title {
    display: none;
    @include mq() {
      display: block;
      font-size: 20px;
    }
  }

  &-text {
    font-size: 15px;
    @include mq() {
      margin-top: 32px;
    }
  }
}

.p-sales-item {
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-gap: 0 12px;
  @include mq() {
    margin-top: 32px;
    grid-gap: 0 46px;
  }

  &-image {
    grid-row: 1 / 2;
    grid-column: 1 / 2;
    width: 94px;
    box-shadow: 2px 2px 6px #00000029;
    @include mq() {
      grid-row: 1/3;
      width: 116px;
      margin-left: 23px;
    }
  }
}

.p-sales-item-detail {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  @include mq() {
    margin-left: 16px;
  }
  &-wrapper {
    display: flex;
    font-size: 16px;
    &:nth-child(n+2) {
      margin-top: 10px;
    }
  }

  &-label {
    font-weight: bold;
    flex-shrink: 0;
  }

  &-text {
    margin-left: 16px;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    @include mq(){
      margin-left: 57px;
    }
  }
}

.p-sales-item-totalPrice {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  margin-top: 29px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px 10px 16px;
  background-color: #f5f5f5;
  border-radius: 4px;
  color: #f25353;
  font-weight: bold;
  @include mq() {
    grid-row: 2/3;
    grid-column: 2/3;
    margin-top: 18px;
    justify-content: flex-start;
  }
  &-label {
    font-size: 16px;
  }
  &-text {
    font-size: 18px;
    @include mq(){
      margin-left: 57px;
    }
  }
}

.p-sales-caution-list {
  margin-top: 16px;
}

.p-sales-caution-list-item {
  font-size: 15px;
  margin-bottom: 5px;
  text-indent: -1em;
  padding-left: 1em;
}

.p-sales-caution-list-link {
  color: #f15353;
  text-decoration: none;
  padding: 0 4px;
  border-bottom: 1px solid #f15353;
  &:hover {
    border-bottom: none;
  }
  > .fas {
    text-indent: 0.1em;
  }
  &-icon {
    padding-left: 4px;
    &.--pdf {
      max-width: 24px;
      height: auto;
      vertical-align: sub;
    }
  }
}

.p-sales-caution {
  margin-top: 48px;
  padding: 24px;
  border-radius: 4px;
  background-color: #f5f5f5;
  &-title {
    font-size: 16px;
  }

  &-text {
    margin-top: 16px;
    font-size: 15px;
  }

  &-links {
    margin-top: 20px;
  }

  &-link {
    font-size: 15px;
    display: flex;
    align-items: center;
    > a {
      color: #f15353;
    }
    .fas {
      padding-right: 8px;
    }
    &:nth-child(n+2) {
      margin-top: 12px;
    }
    &-icon {
      padding-left: 4px;
      &.--pdf {
        max-width: 24px;
        height: auto;
        vertical-align: sub;
      }
    }
  }
}

.p-sales-payment {
  margin-top: 48px;
  &-note {
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    @include mq(){
       font-size: 15px;
       text-align: center;
     }
   }
   &-buttons {
     margin-top: 16px;
     display: flex;
     justify-content: space-between;
     @include mq(){
       justify-content: center
     }
   }
   &-button {
     width: 100%;
     @include mq(){
       max-width: 255px;
     }
     &:nth-child(n+2) {
       margin-left: 16px;
       @include mq(){
         margin-left: 20px;
       }
     }
     .c-button-primary {
       border: 1px solid #919191;
       background-color: #919191;
       &:hover {
         background-color: #fff;
         color: #919191;
       }
     }
     .c-button-primary,
     .c-button-secondary {
       padding: 9px 15px;
       font-size: 14px;
       @include mq(){
         font-size: 17px;
         padding: 13px 15px;
       }
     }
   }
}

.p-sales-result {
  width: 100%;
  margin: 0 auto 136px;
  padding: 0 15px 0;
  @include mq(){
    max-width: 970px;
    padding: 0 35px 0;
    margin: 64px auto 130px;
  }
  &-title {
    font-size: 18px;
    width: calc(100% + 30px);
    margin: 0 -15px;
    padding: 16px 15px;
    text-align: center;
    border-bottom: 1px solid #e5e5ea;
    @include mq() {
      font-size: 20px;
      width: 100%;
      margin: 0;
      padding: 0 0 5px;
      text-align: left;
    }
  }
}

.p-sales-error-message {
  background-color: #fbcfc9;
  margin-top: 16px;
  padding: 40px 17px 48px;
  border-radius: 4px;
  text-align: center;
  @include mq() {
    margin-top: 24px;
  }
  &-title {
    font-size: 16px;
    > .fa {
      padding-right: 8px;
    }
  }
  &-text {
    margin-top: 40px;
    font-size: 15px;
    color: #2b2b2b;
    > br {
      @include mq() {
        display: none;
      }
    }
  }
}

.p-sales-error-button {
  width: 100%;
  max-width: 164px;
  margin: 48px auto 0;
  @include mq() {
    max-width: 255px;
  }
  .c-button-primary {
    font-size: 14px;
    padding: 9px 15px;
    border: 1px solid #919191;
    background-color: #919191;
    @include mq(){
      font-size: 17px;
      padding: 13px 15px;
    }
    &:hover {
      background-color: #fff;
      color: #919191;
    }
  }
}

.p-sales-completed-message {
  margin-top: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.p-sales-completed-message-title {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  > i {
    font-size: 70px;
    color: #919191;
    margin-bottom: 44px;
  }
}

.p-sales-completed-message-text {
  color: #2b2b2b;
  font-size: 15px;
  text-align: center;
  margin-top: 30px;
}

.p-sales-completed-buttons {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;
  @include mq(){
    margin-top: 66px;
    justify-content: center
  }
}

.p-sales-completed-button {
  width: 100%;
  @include mq(){
    max-width: 255px;
  }
  &:nth-child(n+2) {
    margin-left: 16px;
    @include mq(){
      margin-left: 20px;
    }
  }
  .c-button-primary,
  .c-button-secondary {
    padding: 9px 15px;
    font-size: 14px;
    @include mq(){
      font-size: 17px;
      padding: 13px 15px;
    }
  }
}
