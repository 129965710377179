.p-series {
  width: 100%;
  margin: 24px auto 0;
  padding: 0 16px 116px;
  @include mq(){
    max-width: 1082px;
    padding: 0 35px 136px;
    margin-top: 60px;
  }
}

.p-series-header {
  margin-bottom: 48px;

  @include mq(md, max) {
    display: none;
  }
}

.p-series-title {
  font-size: 17px;
  font-weight: bold;
  text-align: center;
  @include mq(){
    font-size: 23px;
    text-align: left;
  }
}

.p-series-spTitle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  font-size: 17px;

  @include mq() {
    display: none;
  }
}

.p-series-backbuttonWrapper {
  position: relative;
  height: 48px;
  background-color: #f5f5f5;

  @include mq(md, max) {
    background-color: initial;
    border-bottom: 1px solid #e5e5ea;
  }

  &-inner {
    position: relative;
    padding:0 13px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    @include mq(md) {
      max-width: 1082px;
      margin: 0 auto;
      padding:0 35px;
    }
  }
}

.p-series-backbutton {
  >span{
    margin-left: 10px;
    font-weight: bold;
  }
}

.p-series-item {
  padding: 16px;
  background-color: #eff0eb;
  border-radius: 4px;
  @include mq(){
    padding: 24px;
  }
  & + .p-series-item {
    margin-top: 24px;
  }
}

.p-series-item-body {
  display: grid;
  grid-template-columns: auto minmax(0, 1fr);
  grid-template-rows: auto minmax(0, 1fr);
  grid-gap: 16px 16px;
  @include mq(){
    grid-gap: 17px 24px;
  }
}

.p-series-item-image {
  grid-row: 1 / 2;
  grid-column: 1 / 2;

  width: 100px;
  height: auto;
  @include mq(){
    grid-row: 1 / 3;
    grid-column: 1 / 2;
  }
}

.p-series-item-image-anchor {
  display: block;
}

.p-series-item-info {
  grid-row: 1 / 2;
  grid-column: 2 / 3;
  font-size: 15px;
  @include mq(){
    font-size: 16px;
  }
}

.p-series-item-info-title {
  margin: 0 0 8px 0;
  font-size: 16px;
}

.p-series-creator {
    margin-top: 13px;
    &-list {
      @include mq(){
        margin-bottom: 8px;
      }
      margin-bottom: 12px;
      li {
        position: relative;
        display: flex;
        font-size: 12px;
        padding-left: 24px;
        a {
          color: #8E8E93;
        }
        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          color: #8E8E93;
          font-family: "Font Awesome 5 Free";
          font-size: 16px;
          font-weight: 900;
        }
        &:first-child {
          margin-bottom: 6px;
        }
      }
    }
    &-type01::before {
      content: '\f304';
    }
    &-type02::before {
      content: '\f53f';
    }
    &-anchor {
      color: palette('scheme', 'gray');
    }
}

.p-series-item-price {
  display: flex;
  align-items: center;

  &-value {
    font-size: 16px;
    font-weight: bold;
    color: #F25353;

    &.-purchased {
      color: #C8C8C8;
    }
  }

  &-purchasedlabel {
    margin-right: 7px;
    padding: 4px 6px;
    color: #ffffff;
    background-color: #FF6160;
    font-size: 12px;
    border-radius: 2px;
    line-height: 1.4;
  }
}

.p-series-item-buttons {
  grid-row: 2 / 3;
  grid-column: 1 / 3;
  display: flex;
  align-items: flex-end;
  @include mq(){
    grid-row: 2 / 3;
    grid-column: 2 / 3;
  }
  li {
    width: 100%;
    @include mq(){
      max-width: 112px;
    }
  }
  li:last-child {
    margin-left: 16px;
  }
  .c-button-secondary {
    font-size: 14px;
    @include mq(){
      font-size: 15px;
    }
  }
}

.p-series-onLoadMore {
  @include mq(){
    margin-top: 48px;
    border-top: 1px solid #e5e5ea;
  }
  .p-button {
    margin-top: 32px;
    width: 163px;
  }
}

.p-series-empty {
  font-size: 16px;
  padding: 0 17px;
  margin: 20px 0;
  @include mq(){
    padding: 0;
    margin: 0;
  }
}
