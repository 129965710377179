.p-creatorUser-header {
  background: palette('scheme', 'ash-blue');

  &-inner {
    position: relative;
    padding: 14px 52px;

    @include mq() {
      padding: 25px 90px;
    }
  }

  &-title {
    font-size: 17px;
    text-align: center;

    @include mq() {
      font-size: 22px;
    }
  }

  &-icon {
    display: block;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    text-decoration: none;
    transform: translateY(-50%);
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: palette('scheme', 'black');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      transform: translate(-50%, -50%);
    }
    &.-back {
      left: 6px;
      &::before {
        content: '\f104';
      }
    }
    &.-edit {
      right: 12px;
      &::before {
        content: '\f013';
      }
    }

    @include mq() {
      &.-back {
        left: 24px;
      }
      &.-edit {
        right: 26px;
      }
    }
    @include mq(lg) {
      &:hover {
        opacity: .6;
      }
    }
  }
}

// 11-1
.creatorUser-index {
  margin-top: 30px;

  @include mq() {
    margin-top: 50px;
  }
  @include mq(xl) {
    margin-top: 80px;
  }
}
.creatorUser-index-avatar {

  @include mq() {
    position: relative;
    padding-left: 220px;
  }

  &-head {
    display: flex;

    @include mq() {
      display: block;
    }
  }

  &-image {
    width: 100px;
    img {
      border-radius: 50%;
    }

    @include mq() {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
    }
  }

  &-row {
    flex: 1;
    padding: 5px 0 0 20px;

    @include mq() {
      padding: 20px 0 0 0;
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;

    @include mq() {
      font-size: 22px;
    }
  }

  &-id {
    margin: 2px 0 0 3px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      font-size: 14px;
    }
  }

  &-link {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: -10px;
    li {
      margin: 10px 0 0 10px;
    }
    &-button {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      &.-pixiv,
      &.-syosetu {
        width: auto;
        height: 36px;
        padding: 0 6px;
        border-radius: 18px;
      }
      &.-pixiv {
        background: palette('scheme', 'pixiv');
      }
      &.-syosetu {
        background: palette('scheme', 'syosetu');
      }
    }

    @include mq(lg) {
      &-button.-pixiv:hover,
      &-button.-syosetu:hover {
        opacity: .6;
      }
    }
  }

  &-profile {
    margin-top: 30px;
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-history {
    display: flex;
    margin-top: 35px;
    border: 1px solid palette('scheme', 'default');
    border-radius: 8px;
    &-label {
      width: 75px;
      padding: 10px;
      background: palette('scheme', 'default');
      border-radius: 7px 0 0 7px;
      color: #fff;
      font-size: 14px;
      &-inner {
        justify-content: center;
      }
    }
    &-text {
      flex: 1;
      padding: 15px;
      border-radius: 0 7px 7px 0;
      font-size: 12px;
    }
    &-label-inner,
    &-text-inner {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: left;
    }

    @include mq() {
      &-label {
        width: 90px;
        font-size: 15px;
      }
      &-text {
        padding: 22px 25px;
        font-size: 14px;
      }
      &-text-inner {
        // 高さを3行で固定
        display: block;
        height: 3em * 1.618;
        overflow-y: auto;
      }
    }
  }

  &-meta {
    margin-top: 30px;
    &-item {
      display: flex;
      margin-top: 12px;
    }
    &-label {
      width: 110px;
      padding: 0 5px 0 0;
      font-size: 14px;
    }
    &-text {
      flex: 1;
      > ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-top: -7px;
        li {
          margin: 7px 8px 0 0;
        }
      }
    }

    @include mq() {
      margin-top: 35px;
      &-item {
        margin-top: 12px;
      }
      &-label {
        width: 130px;
        padding: 1px 5px 0 0;
        font-size: 15px;
      }
    }
  }

  &-requestStatus {
    margin: 30px -17px 0;
    padding: 3px 17px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 13px;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
      padding-left: 24px;
      &::before {
        content: '\f500';
        position: absolute;
        top: 50%;
        left: 0;
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
        transform: translateY(-50%);
      }
    }

    @include mq() {
      margin: 30px 0 0;
    }
  }

  &-requestButton {
    width: 225px;
    margin: 17px auto 0;
  }

  &-login {
    > time {
      color: palette('scheme', 'gray');;
    }
  }
}
.creatorUser-index-work {
  margin-top: 30px;

  @include mq() {
    margin-top: 80px;
  }

  &-nav {
    position: relative;
    margin: 0 -17px;
    padding: 0 17px;
    font-size: 0;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: palette('scheme', 'light-gray-primary');
    }
    li {
      display: inline-block;
      width: 50%;
      max-width: 200px;
    }

    @include mq() {
      margin: 0;
      padding: 0;
    }
  }

  &-nav-anchor {
    position: relative;
    display: block;
    padding: 3px 10px 4px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
    }
    &.is-active::after {
      opacity: 1;
    }

    @include mq(lg) {
      &:hover::after {
        opacity: 1;
      }
    }
  }
}
.creatorUser-index-novel {
  &-list {
    margin: 13px -17px 0;

    @include mq() {
      margin: 0;
    }
  }

  &-item {
    display: flex;
    padding: 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    &:last-child {
      border-bottom: none;
    }
    &.is-disabled {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(#000, .5);
      }
    }

    @include mq() {
      padding: 30px 0;
    }
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }
    img {
      width: 100%;
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
    }
  }

  &-detail {
    flex: 1;
    margin-left: 15px;

    @include mq(xs) {
      display: flex;
      flex-direction: column;
      &-inner {
        padding-bottom: 20px;
      }
    }
    @include mq() {
      margin-left: 20px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 17px;
    }
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    @include mq() {
      margin-top: 6px;
    }
    li {
      margin: 7px 8px 0 0;
    }
  }

  &-tag.p-wordList-secondary {
    margin-top: 4px;
    @include mq() {
      margin-top: 6px;
    }
  }

  &-tag.p-wordList-primary {
    margin-top: 6px;
    @include mq() {
      margin-top: 6px;
    }
  }

  &-creator {
    position: relative;
    margin-top: 15px;
    padding-left: 14px;
    &::before {
      content: '\f304';
      position: absolute;
      top: 2px;
      left: 0;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 11px;
      font-weight: 900;
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 12px;
    }

    @include mq() {
      margin-top: 17px;
      padding-left: 16px;
      &::before {
        top: 1px;
        font-size: 12px;
      }
      &-anchor {
        font-size: 13px;
      }
    }
  }
}
.creatorUser-index-illust {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -5px;

    @include mq() {
      margin: -20px -15px 0;
    }
  }

  &-item {
    width: calc(33.33334% - 10px);
    margin: 25px 5px 0;
    &.is-disabled {
      position: relative;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        background: rgba(#000, .5);
      }
      .creatorUser-index-illust-title-anchor {
        text-decoration: none;
      }
    }

    @include mq() {
      width: calc(33.33334% - 30px);
      margin: 50px 15px 0;
    }
  }

  &-image {
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      display: block;
      position: relative;
    }
    img {
      width: 100%;
    }
  }

  &-title {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 12px;
      font-size: 17px;
    }
  }
}
