.notice {
  &-header {
    background: palette('scheme', 'ash-blue');

    &-inner {
      position: relative;
      padding: 14px 52px;

      @include mq() {
        padding: 25px 90px;
      }
    }

    &-title {
      font-size: 17px;
      text-align: center;

      @include mq() {
        font-size: 22px;
      }
    }

    &-icon {
      display: block;
      position: absolute;
      top: 50%;
      width: 32px;
      height: 32px;
      text-decoration: none;
      transform: translateY(-50%);
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        color: palette('scheme', 'black');
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        transform: translate(-50%, -50%);
      }
      &.-back {
        left: 6px;
        &::before {
          content: '\f104';
        }
      }
      &.-edit {
        right: 12px;
        &::before {
          content: '\f013';
        }
      }

      @include mq() {
        &.-back {
          left: 24px;
        }
        &.-edit {
          right: 26px;
        }
      }
      @include mq(lg) {
        &:hover {
          opacity: .6;
        }
      }
    }
  }

  &-body {
    max-width: 600px;
    margin: 40px auto 0;

    @include mq() {
      margin: 55px auto 0;
    }
    @include mq(xl) {
      margin: 85px auto 0;
    }
  }

  &-list {
    margin: 0 -17px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin: 0;
    }
  }

  &-item {
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
  }

  &-anchor {
    position: relative;
    display: block;
    padding: 12px 42px 12px 17px;
    text-decoration: none;

    &.-read {
      background: #ededed;
    }

    &::after {
      content: '\f105';
      position: absolute;
      top: 50%;
      right: 18px;
      color: palette('scheme', 'theme');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      transform: translateY(-50%);
    }

    @include mq() {
      padding: 15px 42px 15px 17px;
    }
    @include mq(lg) {
      &:hover {
        opacity: .6;
      }
    }
  }

  &-title {
    position: relative;
    padding-left: 26px;
    font-size: 13px;
    &::before {
      position: absolute;
      top: 1px;
      left: 0;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
    }
    &.-info::before {
      content: '\f05a';
    }
    &.-user::before {
      content: '\f007';
      left: 1px;
    }
    &.-review::before {
      content: '\f27a';
      font-weight: 400;
    }
    &.-book::before {
      content: '';
      left: 1px;
      width: 15px;
      height: 15px;
      background: url(/img/icn_book_01.svg) 0 0 no-repeat;
      background-size: cover;
    }
    &.-notice::before {
      content: '\f0eb';
      left: 3px;
      font-weight: 400;
    }

    @include mq() {
      padding-left: 28px;
      font-size: 15px;
      &::before {
        top: 2px;
      }
    }
  }

  &-detail {
    margin: 5px 0 0 26px;
    color: palette('scheme', 'gray');
    font-size: 12px;

    @include mq() {
      margin: 5px 0 0 28px;
      font-size: 13px;
    }
  }
}
