.privacySetting {
  max-width: 600px;
  margin: 30px auto 0;

  @include mq() {
    margin: 50px auto 0;
  }
  @include mq(xl) {
    margin: 80px auto 0;
  }

  &-inner {
    margin: 0 -17px;

    @include mq() {
      margin: 0;
    }
  }

  &-title {
    padding: 0 17px 20px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 17px;
    text-align: center;

    @include mq() {
      padding: 0 17px 30px;
      font-size: 22px;
    }
  }

  &-row {
    display: flex;
    align-items: center;
    padding: 9px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 13px 17px;
    }
  }

  &-label {
    width: 150px;
    padding: 2px 10px 0 0;

    @include mq() {
      width: 180px;
      font-size: 15px;
    }
  }

  &-text {
    flex: 1;
    font-size: 16px;
    line-height: 1.2;
    word-break: break-all;
  }

  &-user {
    padding-top: 30px;
    border-top: 14px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin-top: 60px;
      padding-top: 60px;
      border-top-width: 1px;
    }
  }

  &-birth {
    padding: 9px 17px 14px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      display: flex;
      align-items: center;
      padding: 13px 17px;
    }

    &-label {
      padding-bottom: 8px;

      @include mq() {
        width: 180px;
        padding: 2px 10px 0 0;
        font-size: 15px;
      }
    }

    &-action {

      @include mq() {
        flex: 1;
      }
    }
  }

  &-link {
    margin-top: 60px;

    @include mq() {
      margin-top: 80px;
    }
  }
}