.worksDetail {
  .l-single {
    @include mq(md,max,true){
      padding: 0 15px;
    }
  }
}

.worksDetail-index {
  margin-top: 22px;

  @include mq() {
   margin-top: 48px;
  }

  &-meta-inner {
    display: grid;
    grid-gap: 24px 16px;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
    grid-template-areas:
    "image heading"
    "detail detail";
    @include mq() {
      grid-template-areas:
        "image heading"
        "image detail";
      grid-gap: 0 40px;
    }
  }

  &-image {
    grid-area: image;
    width: 100%;
    height: auto;
    max-width: 114px;
    &-inner {
      cursor: pointer;
      position: relative;
      border: 1px solid palette('scheme', 'light-gray-primary');
      box-shadow: 2px 2px 6px #00000029;
      &:hover{
        opacity: .6;
      }
    }

    @include mq() {
      margin-left: 0;
      max-width: 200px;
    }
  }

  &-imageIcon{
    position: absolute;
    bottom: 3px;
    right: 3px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 35px;
    height: 35px;
    background: rgba(0,0,0,.6);
    border-radius: 4px;
    .fas{
      position: relative;
      top: 5px;
      color: white;
      font-size: 24px;
    }
  }

  &-heading {
    grid-area: heading;
  }

  &-labels {
    margin-bottom: 23px;
    @include mq() {
      margin-bottom: 15px;
    }
    &-label {
      display: inline-block;
      &:nth-child(n+2) {
        margin-left: 8px;
      }
    }
  }

  &-copy {
    color: #2b2b2b;
    font-size: 14px;
    display: none;
    @include mq() {
      display: block;
      margin: 8px 0 5px;
    }
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
  }

  &-creator {
    margin-top: 17px;
    &-list {
      li {
        position: relative;
        display: flex;
        font-size: 14px;
        padding-left: 24px;
        line-height: 1;
        a {
          color: #f15353;
        }
        &::before {
          position: absolute;
          top: -1px;
          left: 0;
          color: #c5c5c5;
          font-family: "Font Awesome 5 Free";
          font-size: 16px;
          font-weight: 900;
        }
        &:first-child {
          margin-bottom: 10px;
        }
      }
    }
    &-type01::before {
      content: '\f304';
    }
    &-type02::before {
      content: '\f53f';
    }
    &-anchor {
      color: palette('scheme', 'gray');
    }
  }

  &-price {
    font-size: 14px;
    margin-top: 12px;
    display: flex;
    @include mq() {
      margin-top: 26px;
    }
    &-amount {
      display: flex;
      align-items: baseline;
      line-height: 1;
    }
    &-amount-number {
      font-size: 24px;
      font-weight: bold;
      color: #f47e7d;
      padding: 0 4px;
    }
    &-purchased-label {
      font-size: 12px;
      color: #fff;
      background-color: #f15353;
      border-radius: 2px;
      padding: 0 6px;
      margin-right: 8px;
      height: 24px;
      display: flex;
      align-items: center;
    }
    &.-purchased {
      color: #c8c8c8;
      .worksDetail-index-price-amount-number {
        color: #c8c8c8;
      }
    }
  }

  &-detail {
    grid-area: detail;
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    @include mq() {
      margin: 24px 0 0 0;
    }
    li {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &-tag {
    margin: 8px 0 0;
    li {
      font-size: 14px;
      margin: 7px 8px 0 0;
    }
  }

  &-review {
    display: flex;
    align-items: center;
    margin-top: 20px;
    &-anchor {
      padding-top: 5px;
      text-decoration: none;
      padding-left: 10px;
      font-size: 14px;
      color: #3881cc;
      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  &-action01 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 32px;
    &-button {
      width: calc(50% - 8px);
      &:nth-child(odd) {
        margin-right: 16px;
      }
      &:nth-child(n+3) {
        margin-top: 24px;
      }
      @include mq() {
        max-width: 203px;
        width: calc(25% - 21px);
        &:nth-child(n+3) {
          margin-top: 0;
        }
        &:not(:nth-child(4n)) {
          margin-right: 28px;
        }
      }

      .c-button-secondary {
        @include mq(md,max,true){
          padding: 7px 0;
        }
        @include mq(xs,max,true){
          font-size: 15px;
        }
      }
      .displayNone_sp {
        display: none;
        @include mq() {
          display: inline;
        }
      }
    }
  }

  &-contents {
    position: relative;

    &-nav {
      position: relative;
      margin: 0 -17px;
      padding: 0 17px;
      font-size: 0;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: palette('scheme', 'light-gray-primary');
      }
      li {
        display: inline-block;
        width: 50%;
        max-width: 200px;
      }

      @include mq() {
        margin: 0;
        padding: 0;
      }
    }

    &-nav-anchor {
      position: relative;
      display: block;
      padding: 3px 10px 4px;
      font-size: 15px;
      text-align: center;
      text-decoration: none;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        z-index: 2;
        left: 0;
        width: 100%;
        height: 2px;
        background: palette('scheme', 'theme');
        opacity: 0;
      }
      &.is-active::after {
        opacity: 1;
      }

      @include mq(lg) {
        &:hover::after {
          opacity: 1;
        }
      }
    }

  }

  &-workData {
    &-title {
      margin-top: 48px;
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 700;
    }

    &-body {
      margin-bottom: 8px;
      font-size: 15px;
      @include mq(md,max,true){
        /* TableOfContents コンポーネントのスタイルを打ち消す */
        .creatorSearchNovelDetail-toc {
          margin: 0 auto;
        }
        .creatorSearchNovelDetail-toc-anchor {
          padding: 14px 8px;
        }
      }
      .p-button-col {
        width: auto;
        min-width: 163px;
      }
      .p-button-row {
        margin-top: 24px;
      }
    }

    &-meta {
      display: flex;
    }

    &-update {
      width: 100%;
      color: #c5c5c5;
      font-size: 14px;
      text-align: left;
    }
  }

  &-workNotice {
    &-title {
      margin-top: 20px;
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        margin-top: 30px;
      }
    }

    &-body {
      margin-top: 5px;
      font-size: 14px;
      li {
        position: relative;
        margin-top: 3px;
        padding-left: 1em;
        &::before {
          content: '\203b';
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @include mq() {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }

  &-other {
    &-image {
      width: 100%;
      border: 1px solid palette('scheme', 'light-gray-primary');
      &-anchor {
        position: relative;
        display: block;
      }
      img {
        width: 100%;
      }
      &-label {
        position: absolute;
        bottom: -1px;
        left: -1px;
        z-index: 2;
        width: calc(100% + 2px);
        padding: 1px 0 2px;
        background: palette('scheme', 'theme');
        color: #fff;
        font-size: 11px;
        letter-spacing: -.05em;
        text-align: center;
      }

      @include mq() {
        &-label {
          padding: 0 0 1px;
          font-size: 13px;
        }
      }
    }

    &-detail {
      margin-top: 5px;

      @include mq() {
        margin-top: 12px;
      }
    }

    &-title {
      font-size: 13px;
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq() {
        font-size: 15px;
      }
      @include mq(lg) {
        font-size: 17px;
      }
    }

    &-copy {
      font-size: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      @include mq() {
        margin-top: 3px;
        font-size: 14px;
        line-height: 1.4;
        white-space: normal;
        text-overflow: inherit;
        overflow: visible;
      }
    }
  }

  &-section03 {
    position: relative;
    margin-top: 74px;
    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 80px;
      &::before {
        content: none;
      }
    }

    &-header {

      @include mq() {
        padding-bottom: 10px;
      }
    }

    &-title {
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        font-size: 22px;
      }
    }
  }

  &-other01 {
    margin: 20px -17px 0 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @include mq() {
      margin: 20px -35px 0 0;
    }
    @include mq(lg) {
      margin: 0;
      overflow: visible;
    }

    &-inner {
      display: flex;
      margin: 0 -10px;
      padding-bottom: 15px;

      @include mq() {
        padding-bottom: 30px;
      }
      @include mq(lg) {
        flex-wrap: wrap;
        margin: -30px -10px 0;
        padding-bottom: 0;
      }
    }

    .worksDetail-index-other-item {
      flex: 0 0 calc(26.66667vw + 20px);
      max-width: 220px;
      padding: 0 10px;
      overflow: hidden;
      &:last-child {
        flex: 0 0 calc(26.66667vw + 27px);
        padding-right: 17px;
      }

      @include mq() {
        flex: 0 0 220px;
        &:last-child {
          flex: 0 0 245px;
          max-width: 245px;
          padding-right: 35px;
        }
      }
      @include mq(lg) {
        flex: inherit;
        width: calc(16.66667% - 20px);
        margin: 50px 10px 0;
        padding: 0;
        &:last-child {
          flex: inherit;
          padding: 0;
        }
      }
    }
  }

  &-section04 {
    position: relative;
    margin-top: 80px;
    &::before {
      content: '';
      position: absolute;
      top: -50px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 100px;
      &::before {
        content: none;
      }
    }

    &-title {
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        font-size: 22px;
      }
    }
  }

  &-other02 {
    &-inner {
      display: flex;
      flex-wrap: wrap;
      margin: -5px -10px 0;

      @include mq() {
        margin: -20px -10px 0;
      }
    }

    .worksDetail-index-other-item {
      width: calc(33.33334% - 20px);
      margin: 25px 10px 0;

      @include mq() {
        width: calc(25% - 20px);
        margin: 50px 10px 0;
      }
      @include mq(lg) {
        width: calc(16.66667% - 20px);
        margin: 50px 10px 0;
      }
    }
  }
}

.p-worksDetail-review {
  &-wrapper {
    margin-top: 62px;
    margin-bottom: 120px;

    @include mq() {
      margin-bottom: 44px;
    }
  }
  &-inner {
    @include mq() {
      display: flex;
      align-items: flex-start;
    }
  }

  &-title {
    padding: 13px 17px;
    font-size: 18px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      padding: 0;
      font-size: 22px;
    }
  }

  &-meta {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;

    @include mq() {
      flex-direction: column;
      margin-bottom: 0;
      margin-right: 18px;
    }
  }

  &-comment {
    flex-grow: 1;
  }

  &-count {
    @include mq() {
      margin-top: 0;
      margin-left: 12px;
    }
    &-rate {
      display: flex;
      align-items: center;
      line-height: 1;
      @include mq(xs,max,true){
        display: block;
      }
      @include mq() {
        display: block;
      }
    }

    &-number {
      font-size: 20px;
      font-weight: bold;
      padding-left: 10px;
      margin-top: 6px;
      @include mq(xs,max,true){
        padding-left: 0;
      }
      @include mq() {
        padding-left: 2px;
        line-height: 1;
      }
    }

    &-length {
      font-size: 13px;
      margin-top: 7px;
      @include mq() {
        padding-left: 2px;
      }
    }
  }

  &-button {
    @include mq() {
      margin-top: 34px;
    }
    .c-button-secondary {
      font-size: 14px;
      padding: 10px 8px;
      min-width: 139px;
      @include mq() {
        min-width: 163px;
        padding: 10px 16px;
      }
    }
  }
}

.p-worksDetail-confirm-modal {
  .modal-primary-body {
    padding: 30px 14px;
    @include mq() {
      padding: 32px;
    }
  }

  .p-modal-close {
    font-size: 28px;
    color: #fff;
    position: absolute;
    top: -40px;
    right: 0px;
    display: inline-block;
    line-height: 1;
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 26px;
    color: #2b2b2b;
  }

  &-text {
    font-size: 15px;
    color: #2b2b2b;
    margin-bottom: 20px;
    @include mq() {
      text-align: center;
    }
  }

  &-feature {
    &-article {
      padding: 14px 16px;
      background-color: #eff0eb;
      border-radius: 4px;
      display: flex;
      align-items: center;
      &:nth-child(n + 2) {
        margin-top: 16px;
      }
    }
    &-image {
      max-width: 41px;
      margin-right: 16px;
      @include mq() {
        margin-right: 24px;
      }
    }
    &-title {
      font-size: 15px;
      font-weight: bold;
      color: #2b2b2b;
      margin-bottom: 4px;
    }
    &-text {
      font-size: 15px;
      color: #2b2b2b;
    }
  }

  &-auth {
    margin-top: 32px;

    .c-button-secondary {
      font-size: 15px;
      max-width: 156px;
      padding: 8px 15px;
      @include mq() {
        max-width: 163px;
      }
      margin: 0 auto;
    }

    &-login {
      font-size: 15px;
      margin-top: 13px;
      text-align: center;
      line-height: 1.1;
      @include mq() {
        margin-top: 16q;
      }
      > button {
        color: #ff6160;
        margin-top: 8px;
        @include mq() {
          padding-left: 12px;
          margin-top: 0;
        }
        text-decoration: underline;
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

// 6-4
.worksDetail-official {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -5px 0;

    @include mq() {
      margin: -80px -15px 0;
    }
    @include mq(xl) {
      margin: -50px -15px 0;
    }
  }

  &-item {
    width: calc(33.33334% - 10px);
    margin: 25px 5px 0;

    @include mq() {
      width: calc(33.33334% - 30px);
      margin: 50px 15px 0;
    }
    @include mq(lg) {
      width: calc(20% - 30px);
    }
  }

  &-image {
    &-anchor {
      position: relative;
      display: block;
      height: 100%;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border: 1px solid palette('scheme', 'light-gray-primary');
      }
    }
    img {
      width: 100%;
    }
  }

  &-number {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 3;
    min-width: 16px;
    height: 16px;
    padding: 0 2px;
    background: palette('scheme', 'black');
    color: #fff;
    font-family: $ff-en;
    font-size: 10px;
    font-weight: 700;
    line-height: 17px;
    text-align: center;
    text-decoration: none;

    @include mq() {
      min-width: 24px;
      height: 24px;
      padding: 0 4px;
      font-size: 13px;
      line-height: 26px;
    }
  }

  &-title {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 12px;
      font-size: 17px;
    }
  }
}

// 6-5
.worksDetail-review {
  &-avatar {
    display: flex;
    align-items: center;

    &-image {
      width: 60px;
      img {
        border: 1px solid palette('scheme', 'light-gray-primary');
        border-radius: 50%;
      }
    }
    &-detail {
      flex: 1;
      padding-left: 12px;
    }
    &-name {
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2;
    }
    &-id {
      margin: 2px 0 0 2px;
      color: palette('scheme', 'gray');
      font-size: 12px;
      font-weight: 700;
    }

    @include mq() {
      &-image {
        width: 100px;
      }
      &-detail {
        padding-left: 20px;
      }
    }
  }

  &-head {
    margin-bottom: 6px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-star {
    display: inline-flex;
    flex-direction: row-reverse;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .worksDetail-review-star-icon,
      &:checked ~ .worksDetail-review-star-icon {
        &::before {
          opacity: 0;
        }
        &::after {
          opacity: 1;
        }
      }
    }
    &-icon {
      position: relative;
      display: inline-block;
      margin-right: 6px;
      font-size: 0;
      line-height: 1;
      cursor: pointer;
      &::before,
      &::after {
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
      }
      &::before {
        content: '\f005';
        color: palette('scheme', 'light-gray-secondary');
      }
      &::after {
        content: '\f005';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        color: palette('scheme', 'corn-yellow');
        opacity: 0;
      }
    }

    @include mq() {
      &-icon {
        &::before,
        &::after {
          font-size: 17px;
        }
      }
    }
    @include mq(lg) {
      &-input {
        &:hover + .worksDetail-review-star-icon,
        &:hover ~ .worksDetail-review-star-icon {
          &::before {
            opacity: 0;
          }
          &::after {
            opacity: 1;
          }
        }
      }
    }
  }

  &-share {
    margin-top: 17px;
    text-align: center;
  }
}
