.p-simplifiedModal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 301;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  backface-visibility: hidden;
  opacity: 0;
  visibility: hidden;
  transition: opacity .25s ease, visibility .25s ease;
  &.is-show {
    opacity: 1;
    visibility: visible;
  }

  &-inner {
    display: table;
    table-layout: fixed;
    width: 100%;
    height: 100%;
  }

  &-contents {
    display: table-cell;
    padding: 30px 17px 0;
    vertical-align: bottom;
    &-inner {
      position: relative;
      max-width: 600px;
      margin: 0 auto;
      background: #fff;
      border-radius: 5px;
    }

    @include mq() {
      padding: 35px 35px 0;
    }
  }

  &-overlay {
    position: fixed;
    top: -10px;
    right: 0;
    bottom: -10px;
    left: 0;
    z-index: 300;
    background: rgba(#000, .5);
    backface-visibility: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease, visibility .25s ease;
    &.is-show {
      opacity: 1;
      visibility: visible;
    }
  }
}

.p-simplified-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 30px;
}

.p-simplified {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: auto;
  padding-top: 35px;
  padding: 35px 0 0;
  background: #fff;

  @include mq() {
    padding-top: 60px;
  }

  &-close {
    position: absolute;
    top: 4px;
    right: 4px;

    @include mq() {
      top: 10px;
    }
  }

  &-wrapper {
    .slick-arrow {
      position: absolute;
      top: 50%;
      z-index: 2;
      width: 30px;
      height: 30px;
      background: #fff;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
      font-size: 0;
      cursor: pointer;
      transition: opacity .25s ease, visibility .25s ease;
      &::before {
        position: absolute;
        top: 50%;
        right: 18px;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 20px;
        font-weight: 900;
        line-height: 1;
        transform: translateY(-50%);
      }
    }
    .slick-prev {
      left: -15px;
      &::before {
        content: '\f104';
        left: 8px;
      }
    }
    .slick-next {
      right: -15px;
      &::before {
        content: '\f105';
        left: 10px;
      }
    }
    .slick-disabled {
      opacity: 0;
      visibility: hidden;
    }

    @include mq() {
      .slick-arrow {
        width: 40px;
        height: 40px;
        &::before {
          font-size: 22px;
        }
      }
      .slick-prev {
        left: -20px;
        &::before {
          left: 12px;
        }
      }
      .slick-next {
        right: -20px;
        &::before {
          left: 15px;
        }
      }
    }
    @include mq(lg) {
      .slick-arrow:hover {
        background: palette('scheme', 'theme');
        border-color: palette('scheme', 'theme');
        &::before {
          color: #fff;
        }
      }
    }
  }
}

// slick
.slick {
  &-slider {
    position: relative;
    display: block;
    -webkit-touch-callout: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;

    .slick-track,
    .slick-list {
      transform: translate3d(0, 0, 0);
    }
  }
  &-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0;
    &:focus {
      outline: none;
    }
    &.dragging {
      cursor: pointer;
      cursor: hand;
    }
  }
  &-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
    &::before,
    &::after {
      content: '';
      display: table;
    }
    &:after {
      clear: both;
    }
    .slick-loading & {
      visibility: hidden;
    }
  }
  &-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    [dir="rtl"] & {
      float: right;
    }
    img {
      display: block;
    }
    &.slick-loading img {
      display: none;
    }

    display: none;

    &.dragging img {
      pointer-events: none;
    }
    .slick-initialized & {
      display: block;
    }
    .slick-loading & {
      visibility: hidden;
    }

    .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
    }
  }
  &-arrow.slick-hidden {
    display: none;
  }
}

// 簡易小説・簡易作者
.p-novelSimplified {
  &-link {
    margin-top: 30px;
    li:last-child {
      border-bottom: none;
    }

    @include mq() {
      margin-top: 40px;
      &-anchor {
        padding: 10px 60px 10px 35px;
        &::after {
          right: 35px;
        }
      }
    }
  }
}

// 簡易小説
.p-novelSimplified {
  padding: 0 17px;

  @include mq() {
    padding: 0 35px;
  }

  &-row {
    display: flex;
    align-items: flex-start;
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
    }
    img {
      border: 1px solid palette('scheme', 'light-gray-primary');
      width: 100%;
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }
  }

  &-detail {
    flex: 1;
    margin-left: 15px;

    @include mq() {
      margin-left: 20px;
      padding-top: 5px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;
    &-anchor {
      text-decoration: none;
    }

    @include mq() {
      font-size: 17px;
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-creator {
    position: relative;
    display: block;
    margin: 10px 0 7px;
    text-align: center;
    &::before {
      content: '\f304';
      position: relative;
      top: 1px;
      left: -3px;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 13px;
      font-weight: 900;
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 14px;
    }

    @include mq() {
      margin-top: 14px;
      &::before {
        font-size: 14px;
      }
      &-anchor {
        font-size: 15px;
      }
    }
  }

  &-summary {
    &-title {
      margin-top: 20px;
      font-size: 17px;
      font-weight: 700;
    }
    &-body {
      margin-top: 5px;
      font-size: 14px;
    }

    @include mq() {
      &-title {
        margin-top: 30px;
      }
      &-body {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }

  &-bookmark {
    color: palette('scheme', 'theme');
    font-size: 13px;
    text-align: center;

    @include mq() {
      font-size: 15px;
    }
  }

  &-meta {
    margin-top: 10px;
    color: palette('scheme', 'theme');
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }
}

// 簡易作者
.p-creatorSimplified {
  padding: 0 17px;
  width: 100%;

  @include mq() {
    padding: 0 35px;
  }

  &-title {
    margin-bottom: 20px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;

    @include mq() {
      font-size: 22px;
    }
  }

  &-row {
    display: flex;
    align-items: center;
  }

  &-image {
    width: 60px;
    img {
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 100px;
    }
  }

  &-detail {
    flex: 1;
    padding-left: 12px;

    @include mq() {
      padding-left: 20px;
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;
  }

  &-id {
    margin: 2px 0 0 3px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    font-weight: 700;
  }

  &-profile {
    margin: 15px 0 30px;
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-history {
    display: flex;
    margin-top: 17px;
    border: 1px solid palette('scheme', 'default');
    border-radius: 8px;
    &-label {
      width: 75px;
      padding: 10px;
      background: palette('scheme', 'default');
      border-radius: 7px 0 0 7px;
      color: #fff;
      font-size: 14px;
      &-inner {
        justify-content: center;
      }
    }
    &-text {
      flex: 1;
      padding: 15px;
      border-radius: 0 7px 7px 0;
      font-size: 12px;
    }
    &-label-inner,
    &-text-inner {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: left;
    }

    @include mq() {
      margin-top: 25px;
      &-label {
        width: 90px;
        font-size: 15px;
      }
      &-text {
        padding: 22px 25px;
        font-size: 14px;
      }
    }
  }
}
