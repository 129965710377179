.sr-only {
  position: absolute;
  z-index: -1;
  clip: rect(0 0 0 0);
  overflow: hidden;
  width: 1px;
  height: 1px;
}

.xs\:hidden {
  display: none;
}
@include mq(xs) {
  .xs\:hidden {
    display: inline;
  }
  .sm\:hidden {
    display: none;
  }
}
@include mq() {
  .xs\:hidden,
  .sm\:hidden {
    display: inline;
  }
  .md\:hidden {
    display: none;
  }
}
@include mq(lg) {
  .xs\:hidden,
  .sm\:hidden,
  .md\:hidden {
    display: inline;
  }
  .lg\:hidden {
    display: none;
  }
}

.u-spacer {
  &-xs {
    margin-top: 10px;
  }
  &-sm {
    margin-top: 20px;
  }
  &-md {
    margin-top: 30px;
  }
  &-lg {
    margin-top: 40px;
  }
  &-xl {
    margin-top: 50px;
  }

  @include mq() {
    &-xs {
      margin-top: 15px;
    }
    &-sm {
      margin-top: 30px;
    }
    &-md {
      margin-top: 50px;
    }
    &-lg {
      margin-top: 60px;
    }
    &-xl {
      margin-top: 80px;
    }
  }
}

$num: 5;
@for $i from 0 through 20 {
  .u-m-#{$i * $num} {
    margin: #{$i * $num}px !important;
  }
  .u-mt-#{$i * $num} {
    margin-top: #{$i * $num}px !important;
  }
  .u-mr-#{$i * $num} {
    margin-right: #{$i * $num}px !important;
  }
  .u-mb-#{$i * $num} {
    margin-bottom: #{$i * $num}px !important;
  }
  .u-ml-#{$i * $num} {
    margin-left: #{$i * $num}px !important;
  }
  .u-p-#{$i * $num} {
    padding: #{$i * $num}px !important;
  }
  .u-pt-#{$i * $num} {
    padding-top: #{$i * $num}px !important;
  }
  .u-pr-#{$i * $num} {
    padding-right: #{$i * $num}px !important;
  }
  .u-pb-#{$i * $num} {
    padding-bottom: #{$i * $num}px !important;
  }
  .u-pl-#{$i * $num} {
    padding-left: #{$i * $num}px !important;
  }
}

.u-tl {
  text-align: left;
}
.u-tc {
  text-align: center;
}
.u-tr {
  text-align: right;
}

.u-vt {
  vertical-align: top;
}
.u-vm {
  vertical-align: middle;
}
.u-vb {
  vertical-align: bottom;
}

.u-color {
  &-default {
    color: palette('scheme', 'default');
  }
  &-theme {
    color: palette('scheme', 'theme');
  }
  &-light-theme {
    color: palette('scheme', 'light-theme');
  }
  &-black {
    color: palette('scheme', 'black');
  }
  &-light-black {
    color: palette('scheme', 'light-black');
  }
  &-gray {
    color: palette('scheme', 'gray');
  }
  &-custom-gray {
    color: palette('scheme', 'custom-gray');
  }
  &-medium-gray {
    color: palette('scheme', 'medium-gray');
  }
  &-light-gray-primary {
    color: palette('scheme', 'light-gray-primary');
  }
  &-light-gray-secondary {
    color: palette('scheme', 'light-gray-secondary');
  }
  &-red {
    color: palette('scheme', 'red');
  }
}