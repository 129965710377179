// 10-7, 10-10
.p-creatorSearchDetail-creator {
  display: flex;

  &-wrapper {
    margin: 30px 0 0;
    padding: 10px 0px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin: 40px 0 0;
      padding: 16px 0;
      font-size: 14px;
    }
  }

  &.-illustrator {
    padding: 0;
    border: none;

    > .p-creatorSearchDetail-creator-name {
      margin-left: 0;
      padding-left: 22px;
      &::before {
        top: 0;
        font-size: 16px;
      }
    }
  }

  &-name {
    position: relative;
    padding-left: 22px;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 16px;
      font-weight: 900;
    }
    &.-illust::before {
      content: '\f53f';
    }
    &.-noval::before {
      content: '\f304';
    }

    @include mq() {
      padding-left: 18px;
      &::before {
        top: 1px;
        font-size: 13px;
      }
    }
  }

  &-anchor {
    color: palette('scheme', 'gray');
  }
}
.p-creatorSearchDetail-history {
  display: flex;
  margin-top: 17px;
  border: 1px solid palette('scheme', 'default');
  border-radius: 8px;
  &-label {
    width: 75px;
    padding: 10px;
    background: palette('scheme', 'default');
    border-radius: 7px 0 0 7px;
    color: #fff;
    font-size: 14px;
    &-inner {
      justify-content: center;
    }
  }
  &-text {
    flex: 1;
    padding: 15px;
    border-radius: 0 7px 7px 0;
    font-size: 12px;
  }
  &-label-inner,
  &-text-inner {
    display: flex;
    align-items: center;
    height: 100%;
    text-align: left;
  }

  @include mq() {
    margin-top: 35px;
    &-label {
      width: 90px;
      font-size: 15px;
    }
    &-text {
      padding: 22px 25px;
      font-size: 14px;
    }
  }
}
.p-creatorSearchDetail-works {
  &-image {
    width: 100%;
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      position: relative;
      display: block;
    }
    img {
      width: 100%;
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }

    @include mq() {
      &-label {
        padding: 0 0 1px;
        font-size: 13px;
      }
    }
  }

  &-detail {
    margin-top: 5px;

    @include mq() {
      margin-top: 12px;
    }
  }

  &-title {
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      font-size: 15px;
    }
    @include mq(lg) {
      font-size: 17px;
    }
  }

  &-copy {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 3px;
      font-size: 14px;
      line-height: 1.4;
      white-space: normal;
      text-overflow: inherit;
      overflow: visible;
    }
  }
}

// 10-7
.creatorSearchIllustDetail {
  &-section01 {

    @include mq() {
      display: flex;
    }
  }

  &-image {
    max-width: 600px;
    margin: 0 auto;
    text-align: center;
    img {
      display: inline-block;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      width: 40%;
      max-width: 320px;
    }
  }

  &-detail {
    padding-top: 10px;

    @include mq() {
      flex: 1;
      padding: 0 0 0 40px;
    }
  }

  &-title {
    font-size: 17px;

    @include mq() {
      font-size: 30px;
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 7px 8px 0 0;
    }
  }

  &-extention {
    margin-top: 35px;

    @include mq(md, max) {
      padding-bottom: 10px;
    }

    &-item {
      display: flex;
      padding: 20px 0;
      margin-top: 25px;
      border-top: 1px solid #EBEBEF;

      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        border-bottom: 1px solid #EBEBEF;
      }

      > dt {
        margin-right: 50px;
        font-weight: bold;
        font-size: 14px;
        width: 126px;

        @include mq(md, max) {
          margin-right: 30px;
        }
      }

      > dd {
        font-size: 14px;
        width: calc(100% - 126px - 50px);

        @include mq(md, max) {
          width: calc(100% - 126px - 30px);
        }
      }

      &.-text {
        display: block;

        > dt {
          width: 100%;
          margin-bottom: 16px;
        }

        > dd {
          width: 100%;
        }
      }
    }
  }

  &-workData {
    &-title {
      margin-top: 30px;
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        margin-top: 40px;
      }
    }

    &-body {
      margin-top: 5px;
      font-size: 14px;

      @include mq() {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }

  &-section02 {
    position: relative;
    margin-top: 64px;
    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 80px;
      &::before {
        content: none;
      }
    }

    &-header {
      display: flex;
      align-items: center;

      @include mq() {
        padding-bottom: 10px;
      }
    }

    &-title {
      flex: 1;
      padding-right: 15px;
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        font-size: 22px;
      }
    }

    &-more-anchor {
      position: relative;
      display: block;
      width: 40px;
      height: 24px;
      font-size: 0;
      text-decoration: none;
      &::before {
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 0;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        transform: translateY(-50%);
      }

      @include mq() {
        width: auto;
        height: auto;
        font-size: 15px;
        color: palette('scheme', 'theme');
        text-decoration: underline;
        &::before {
          content: none;
        }
      }
    }
  }

  &-works01 {
    margin: 20px -17px 0 0;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;

    @include mq() {
      margin: 20px -35px 0 0;
    }
    @include mq(lg) {
      margin: 0;
      overflow: visible;
    }

    &-inner {
      display: flex;
      margin: 0 -7px;
      padding-bottom: 15px;

      @include mq() {
        margin: 0 -10px;
        padding-bottom: 30px;
      }
      @include mq(lg) {
        flex-wrap: wrap;
        margin: 0 -10px;
        padding-bottom: 0;
      }
    }

    .p-creatorSearchDetail-works-item {
      flex: 0 0 calc(26.66667vw + 14px);
      max-width: 220px;
      padding: 0 7px;
      overflow: hidden;
      &:last-child {
        flex: 0 0 calc(26.66667vw + 24px);
        padding-right: 17px;
      }

      @include mq() {
        flex: 0 0 220px;
        padding: 0 10px;
        &:last-child {
          flex: 0 0 245px;
          max-width: 245px;
          padding-right: 35px;
        }
      }
      @include mq(lg) {
        flex: inherit;
        width: calc(16.66667% - 20px);
        margin: 20px 10px 0;
        padding: 0;
        &:last-child {
          flex: inherit;
          padding: 0;
        }
      }
    }
  }

  &-section03 {
    position: relative;
    margin-top: 80px;
    &::before {
      content: '';
      position: absolute;
      top: -50px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 100px;
      &::before {
        content: none;
      }
    }

    &-title {
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        font-size: 22px;
      }
    }
  }

  &-works02 {
    &-inner {
      display: flex;
      flex-wrap: wrap;
      margin: 6px -7px 0;

      @include mq() {
        margin: 10px -10px 0;
      }
    }

    .p-creatorSearchDetail-works-item {
      width: calc(33.33334% - 14px);
      margin: 14px 7px 0;

      @include mq() {
        width: calc(25% - 20px);
        margin: 20px 10px 0;
      }
      @include mq(lg) {
        width: calc(16.66667% - 20px);
        margin: 20px 10px 0;
      }
    }
  }
}

// 10-10
.creatorSearchNovelDetail {
  &-section01-inner {
    display: flex;
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    text-align: center;
    img {
      display: inline-block;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
    }
  }

  &-detail {
    flex: 1;
    padding-left: 20px;

    @include mq() {
      padding-left: 40px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 16px;
    }
  }

  &-title {
    margin-top: 3px;
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 30px;
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-creatorInfo01,
  &-more01 {
    display: none;

    @include mq() {
      display: block;
    }
  }

  &-creatorInfo02,
  &-more02 {

    @include mq() {
      display: none;
    }
  }

  &-section02 {
    position: relative;
    margin-top: 60px;
    &::before {
      content: '';
      position: absolute;
      top: -30px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 80px;
      &::before {
        content: none;
      }
    }

    &-nav {
      position: relative;
      margin: 0 -17px;
      padding: 0 17px;
      font-size: 0;
      text-align: center;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: palette('scheme', 'light-gray-primary');
      }
      li {
        display: inline-block;
        width: 50%;
        max-width: 200px;
      }

      @include mq() {
        margin: 0;
        padding: 0;
      }
    }

    &-nav-anchor {
      position: relative;
      display: block;
      padding: 3px 10px 4px;
      font-size: 15px;
      text-align: center;
      text-decoration: none;
      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        z-index: 2;
        left: 0;
        width: 100%;
        height: 2px;
        background: palette('scheme', 'theme');
        opacity: 0;
      }
      &.is-active::after {
        opacity: 1;
      }

      @include mq(lg) {
        &:hover::after {
          opacity: 1;
        }
      }
    }
  }

  &-toc {
    margin: 10px -17px 0;

    @include mq() {
      margin: 20px 0 0;
      font-size: 15px;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }

    &-anchor {
      width: 100%;
      display: block;
      padding: 10px 17px;
      text-decoration: none;

      &.-isTieupDetail {
        display: flex;
        justify-content: space-between;
      }

      &.-disabled {
        pointer-events: none;
        color: #c5c5c5;
      }

      @include mq(lg) {
        &:hover {
          opacity: .6;
        }
      }
    }
  }

  &-workData {
    &-title {
      margin-top: 20px;
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        margin-top: 30px;
      }
    }

    &-body {
      margin-top: 5px;
      font-size: 14px;

      @include mq() {
        margin-top: 10px;
        font-size: 15px;
      }
    }

    &-meta {
      display: flex;
      margin-top: 10px;
    }

    &-word {
      width: 50%;
      padding-right: 20px;
      color: palette('scheme', 'theme');
      font-size: 14px;

      @include mq() {
        font-size: 15px;
      }
    }

    &-readers {
      display: flex;
      align-items: flex-end;
      padding-right: 20px;
      color: palette('scheme', 'theme');
      font-size: 14px;

      @include mq() {
        font-size: 15px;
      }
    }

    &-update {
      padding: 2px 0 0 0;
      color: palette('scheme', 'gray');
      font-size: 12px;
      text-align: right;

      @include mq() {
        font-size: 13px;
      }
    }

    &-link {
      margin: 30px -17px 0;

      @include mq() {
        margin: 30px 0 0;
      }
    }
  }

  &-workNotice {
    &-title {
      margin-top: 20px;
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        margin-top: 30px;
      }
    }

    &-body {
      margin-top: 5px;
      font-size: 14px;
      li {
        position: relative;
        margin-top: 3px;
        padding-left: 1em;
        &::before {
          content: '\203b';
          position: absolute;
          top: 0;
          left: 0;
        }
      }

      @include mq() {
        margin-top: 10px;
        font-size: 15px;
      }
    }
  }

  &-section03 {
    position: relative;
    margin-top: 80px;
    &::before {
      content: '';
      position: absolute;
      top: -50px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 100px;
      &::before {
        content: none;
      }
    }

    &-title {
      font-size: 17px;
      font-weight: 700;

      @include mq() {
        font-size: 22px;
      }
    }
  }

  &-works {
    &-inner {
      display: flex;
      flex-wrap: wrap;
      margin: -5px -10px 0;

      @include mq() {
        margin: -20px -10px 0;
      }
    }

    .p-creatorSearchDetail-works-item {
      width: calc(33.33334% - 20px);
      margin: 25px 10px 0;

      @include mq() {
        width: calc(25% - 20px);
        margin: 50px 10px 0;
      }
      @include mq(lg) {
        width: calc(16.66667% - 20px);
        margin: 50px 10px 0;
      }
    }
  }

  &-section04 {
    @include mq() {
      display: none;
    }

    margin-top: 10px;
    padding: 12px 0;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    & .-name {
      margin-left: 0;
    }
  }
}


.p-creatorSearch-headerNav {
  position: relative;
  margin: 16px 0 30px;
  padding: 0 17px;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: palette('scheme', 'light-gray-primary');
  }

  @include mq() {
    margin: 16px 0 50px;
    padding: 0 35px;
  }
  @include mq(xl) {
    display: none;
  }

  &-inner {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &-list {
    font-size: 0;
    white-space: nowrap;
    li {
      display: inline-block;
    }
  }

  &-anchor {
    position: relative;
    display: block;
    padding: 3px 10px 4px;
    font-size: 15px;
    text-decoration: none;
    &.-large {
      min-width: 170px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
      visibility: hidden;
    }
    &.is-active::after {
      opacity: 1;
      visibility: visible;
    }

    @include mq() {
      padding: 5px 15px 6px;
    }
    @include mq(lg) {
      &:hover::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.p-creatorSearch-title {
  display: none;

  @include mq(xl) {
    display: block;
    margin-bottom: 70px;
    font-size: 22px;
  }
}

.creatorSearch-character-linkList {
  margin: 0 -17px;

  @include mq() {
    margin: 0;
  }
}

.p-creatorSearch-sideNav {

  @include mq(xl) {
    &-list {
      padding: 16px 0;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 6px 20px rgba(#000, .1);
    }
    &-anchor {
      position: relative;
      display: block;
      padding: 9px 46px 9px 20px;
      font-size: 15px;
      text-decoration: none;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: palette('scheme', 'theme');
        opacity: 0;
      }
      &::after {
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 18px;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        transform: translateY(-50%);
      }
      &:hover::before,
      &.is-active::before {
        opacity: 1;
      }
    }
  }
}

.creatorSearch-memoDetail {
  &-list {
    margin: 0 -17px -20px;

    @include mq() {
      margin: 0 0 -20px;
    }
  }

  &-item {
    display: flex;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    &:last-of-type {
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
  }

  &-label {
    width: 130px;
    padding: 20px 10px 20px 17px;

    @include mq() {
      width: 160px;
    }
  }

  &-text {
    flex: 1;
    padding: 18px 17px 18px 0;
    font-size: 15px;
  }
}

.creatorSearch-characterDetail {
  &-list {
    margin: 0 -17px -20px;

    @include mq() {
      margin: 0 0 -20px;
    }
  }

  &-item {
    display: flex;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
  }

  &-label {
    width: 130px;
    padding: 20px 10px 20px 17px;

    @include mq() {
      width: 160px;
    }
  }

  &-text {
    flex: 1;
    padding: 18px 17px 18px 0;
    font-size: 15px;

    .p-wordList-secondary {
      margin: -2px -6px 0 0;
      li {
        color: palette('scheme', 'default');
        font-size: 15px;
      }
    }

    @include mq() {
      .p-wordList-secondary {
        margin: -2px -8px 0 0;
      }
    }
  }
}

.creatorSearch-application {
  &-wrapper {
    margin-top: 13px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    @include mq(md, max) {
      margin-top: 11px;
      justify-content: space-between;
    }
  }

  &-request-status {
    padding: 8px 0;
    width: calc(100% - 163px - 21px);
    background: #ffdfdf;
    color: #f15353;
    font-size: 12px;
    text-align: center;
    border-radius: 5px;
    span {
      position: relative;
      display: inline-block;
      padding-left: 24px;
      &::before {
        content: '\f500';
        position: absolute;
        top: 50%;
        left: 0;
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
        transform: translateY(-50%);
      }
    }

    @include mq(md, max) {
      width: 49%;
      padding: 10px 0;
      font-size: 13px;
    }

    @include mq(sm, max) {
      padding: 8px 0;
      font-size: 12px;
    }
  }

  &-request-button {
    margin-left: 16px;

    @include mq(md, max) {
      width: 49%;
      margin-left: 0;
    }

    & .c-button-secondary {
      font-size: 15px;
      padding: 8px 13px;

      @include mq(md, max) {
        font-size: 15px;
        padding: 11px 0;
      }

      @include mq(sm, max) {
        font-size: 13px;
        padding: 9px 0;
      }
    }
  }
}
