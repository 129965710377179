.p-modal-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.p-modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 301;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

  &-inner {
    display: table;
    width: 100%;
    height: 100%;
  }

  &-contents {
    display: table-cell;
    padding: 17px;
    vertical-align: middle;
    &-inner {
      position: relative;
      width: 100%;
      max-width: 600px;
      margin: 0 auto;
      background: #fff;
      border-radius: 5px;
      &.-review {
        max-width: 700px;
      }

      @media screen and (max-width: 360px) {
        max-width: 90vw;
      }
    }

    @include mq() {
      padding: 35px;
    }
  }

  &-overlay {
    position: fixed;
    top: -10px;
    right: 0;
    bottom: -10px;
    left: 0;
    z-index: 300;
    background: rgba(#000, .5);
    backface-visibility: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease, visibility .25s ease;
    pointer-events: none;
    &.is-show {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  &-header {
    position: relative;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    .p-modal-close {
      top: 10px;
    }

    @include mq() {
      .p-modal-close {
        top: 12px;
      }
    }
  }

  &-title {
    padding: 14px 40px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;

    @include mq() {
      font-size: 22px;
    }
  }

  &-subtitle {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 17px;
    }
  }

  &-close {
    position: absolute;
    top: 4px;
    right: 4px;
    z-index: 2;
  }

  &-body {
    padding: 20px 10px;

    @include mq() {
      padding: 20px;
    }
  }

  &-footer {
    margin-top: 30px;
    padding: 15px 20px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 20px;
    }
  }

  &-action {
    display: flex;
    justify-content: center;
    width: 224px;
    margin: 0 auto;
    &-col {
      width: 108px;
      padding: 0 5px;
    }
    &-cancel {
      padding-right: 10px;
      padding-left: 10px;
      letter-spacing: -.15em;
    }

    @include mq() {
      width: 280px;
      &-col {
        width: 134px;
      }
    }
  }
}

.modal-request {
  &-header {
    display: flex;
    align-items: center;
  }

  &-image {
    width: 62px;
    img {
      width: 100%;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 84px;
    }
  }

  &-detail {
    flex: 1;
    margin-left: 20px;
    line-height: 1.4;
    &-message {
      font-size: 15px;
      font-weight: 700;
    }
    &-work {
      margin-top: 5px;
      overflow: hidden;
      &-label {
        float: left;
        color: palette('scheme', 'gray');
      }
      &-title {
        color: palette('scheme', 'theme');
        overflow: hidden;
      }
    }

    @include mq() {
      &-message {
        font-size: 17px;
      }
      &-work {
        margin-top: 8px;
        font-size: 15px;
      }
    }
  }

  &-body {
    margin-top: 15px;

    &.center {
      text-align: center;
    }

    @include mq() {
      margin-top: 20px;
    }
  }
}

.modal-requestCompleted {
  &-image {
    width: 62px;
    margin: 0 auto;
    img {
      width: 100%;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 84px;
    }
  }

  &-detail {
    width: 75%;
    margin: 12px auto 0;
    line-height: 1.4;
    &-message {
      font-size: 17px;
      font-weight: 700;
    }
    &-work {
      margin-top: 5px;
      overflow: hidden;
      &-label {
        float: left;
        color: palette('scheme', 'gray');
      }
      &-title {
        color: palette('scheme', 'theme');
        overflow: hidden;
      }
    }

    @include mq() {
      margin: 20px auto 0;
      &-work {
        margin-top: 8px;
        font-size: 15px;
      }
    }
  }
}

.modal-creatorSearch {
  max-width: 244px;
  margin: 0 auto;
  padding: 80px 10px;
}

.modal-imageUpload {
  &-contents-inner {
    max-width: 890px;
  }

  &-control {
    position: relative;
    width: 68.75%;
    max-width: 500px;
    margin: 30px auto 0;
    padding: 9px 26px;
    &-button {
      display: inline-block;
      position: absolute;
      top: 0;
      width: 20px;
      height: 20px;
      font-size: 0;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        color: palette('scheme', 'black');
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
        line-height: 1;
        transform: translate(-50%, -50%);
      }
      &.-minus {
        left: 0;
        &::before {
          content: '\f068';
        }
      }
      &.-plus {
        right: 0;
        &::before {
          content: '\f067';
        }
      }
    }
    &-bar {
      position: relative;
      width: 100%;
      height: 2px;
      margin: 0 auto;
      background: #cdd3ec;
    }
    &-pointer {
      position: absolute;
      top: -5px;
      left: 0;
      z-index: 2;
      width: 12px;
      height: 12px;
      background: palette('scheme', 'black');
      border-radius: 50%;
      cursor: grab;
    }
  }

  &-footer {
    margin-top: 40px;

    @include mq() {
      display: flex;
      justify-content: flex-end;
      margin-top: 0;
      padding: 15px 20px 50px;
      border-top: none;
    }
  }

  &-action {

    @include mq() {
      margin: 0;
    }
  }
}

.modal-primary {
  &-body {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 160px;
    padding: 40px 10px;
    margin-bottom: -30px;
    font-size: 16px;
    flex-direction: column;
    &.-review {
      display: block;
    }

    .center {
      text-align: center;
    }

    p + p  {
      margin-top: 1em;
    }

    &.-wordCount {
      text-align: center;
    }

    @include mq() {
      padding: 40px 20px;
      // p:not([class]) {
      //   font-size: 15px;
      // }
    }
  }

  &-action {
    display: flex;
    justify-content: space-between;
    max-width: 400px;
    margin: 0 auto;
    &-col {
      width: calc(50% - 4px);
    }
    &-button {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.modal-wordCount-title {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: bold;
}

.modal-wordCount-text {
  margin-bottom: 20px;

  > span {
    position: relative;
    top: 1px;
    margin: 0 6px 0 2px;
    font-size: 24px;
    font-weight: bold;
  }
}

.modal-wordCount-caution {
  font-size: 14px;
  text-align: left;
  color: #f15353;

  > span {
    font-weight: bold;
  }
}

.modal-wordCount-separater {
  @include mq() {
    > br {
      display: none;
    }
    &:after {
      position: relative;
      margin: 0 10px;
      top: 2px;
      content: "|";
      font-size: 24px;
      color: #e5e5ea;
    }
  }
}

.modal-tieupSelect {
  max-width: 300px;
  margin: 0 auto;
  padding: 80px 10px;
}

.modal-tieupTypeSelect {
  max-width: 540px;
  margin: 0 auto;
  padding: 20px 20px 15px;

  @include mq() {
    padding: 20px;
  }

  &-item:not(:first-child) {
    margin-top: 20px;

    @include mq() {
      margin-top: 30px;
    }
  }

  &-note {
    margin: 3px 0 0 28px;
    color: palette('scheme', 'gray');
    font-size: 12px;
  }
}

.modal-tieupWorkSelect {
  max-width: 540px;
  margin: 0 auto;
  padding: 20px 20px 15px;

  @include mq() {
    padding: 20px;
  }

  &-list {
    max-height: 240px;
    padding: 5px 20px 5px 0;
    overflow-y: auto;

    @include mq() {
      max-height: 320px;
    }
  }

  &-item:not(:first-child) {
    margin-top: 15px;

    @include mq() {
      margin-top: 20px;
    }
  }

  &-area {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 28px;
    cursor: pointer;
  }

  &-image {
    display: block;
    width: 48px;
    margin-right: 12px;

    @include mq() {
      width: 64px;
    }
  }

  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .modal-tieupWorkSelect-detail::after {
      opacity: 1;
    }
  }

  &-detail {
    flex: 1;
    font-size: 13px;
    line-height: 1.4;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      border-radius: 50%;
      transform: translateY(-50%);
    }
    &::before {
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid palette('scheme', 'gray');
    }
    &::after {
      left: 5px;
      z-index: 2;
      width: 10px;
      height: 10px;
      background: palette('scheme', 'theme');
      opacity: 0;
    }

    @include mq() {
      font-size: 15px;
    }
  }
}
