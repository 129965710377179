.p-ranking {
  &-header {
    margin: 0 0 18px;
    @include mq() {
      margin: 65px 0 100px;
    }
    &-inner {
      @include mq() {
        display: flex;
        align-items: center;
      }
    }
  }

  &-col01 {
    margin: 0 -17px auto;
    @include mq() {
      margin: 0 0 auto 0;
    }

    &-inner {
      position: relative;
      margin-top: 14px;
      padding: 6px 120px 6px 0;
    }

    @include mq() {
      text-align: center;
      &-inner {
        position: relative;
        display: inline-block;
        margin-top: 25px;
        padding: 6px 120px 6px 0;
      }
    }
  }

  &-title {
    padding: 13px 17px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      padding: 0;
      font-size: 23px;
      text-align: left;
    }
  }

  &-col02 {
    display: flex;
    flex-direction: column;
    margin: 0 -17px;
    border-top: 1px solid #e5e5ea;
    border-bottom: 1px solid #e5e5ea;
    @include mq() {
      margin: 0 0 0 auto;
      border-top: none;
      border-bottom: none;
    }
  }

  &-select {
    border-bottom: 1px solid #e5e5ea;
    width: 100%;
    padding: 13px 15px 13px 0;
    text-align: right;
    @include mq() {
      border-bottom: none;
      padding: 0 0 15px;
    }
  }

  &-select-inner {
    > select {
      font-size: 13px;
    }
  }

  &-toggle {
    padding: 13px 17px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    @include mq() {
      padding: 0;
      justify-content: flex-end;
    }
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .p-ranking-toggle-button {
        background: palette('scheme', 'check-green');
        border-color: palette('scheme', 'check-green');
        &::after {
          left: 12px;
          box-shadow: 0 2px 6px rgba(#000, .05);
        }
      }
    }
    &-button {
      position: relative;
      display: inline-block;
      width: 32px;
      height: 20px;
      background: #fff;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 16px;
      font-size: 0;
      vertical-align: top;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(#000, .1);
        transition: .25s ease;
      }
    }
  }
  &-toggle-label {
    @include mq() {
      padding-right: 60px;
    }
  }
}

.p-ranking-works {
  &-list {
    margin: -17px -17px 0;
    @include mq() {
      display: flex;
      flex-wrap: wrap;
      margin: 0;
    }
  }

  &-item {
    display: flex;
    align-items: flex-start;
    padding: 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    @include mq() {
      width: 50%;
      padding: 30px 10px 30px 0;
    }
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
    }
    img {
      width: 100%;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }

    @include mq() {
      width: 33%;
      max-width: 165px;
      &-label {
        letter-spacing: 0;
      }
    }
  }

  &-detail {
    position: relative;
    flex: 1;
    margin-left: 15px;

    @include mq() {
      margin-left: 18px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;
    margin-top: 16px;
    @include mq() {
      font-size: 18px;
    }
    &-anchor {
      text-decoration: none;
    }
  }
}

.p-ranking-works-user {
  margin-top: 24px;

  li {
    position: relative;
    display: flex;
    font-size: 14px;
    padding-left: 24px;
    a {
      color: #8a8a8a;
    }
    &::before {
      position: absolute;
      top: -1px;
      left: 0;
      color: #c5c5c5;
      font-size: 16px;
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
    }
    &:first-child {
      margin-bottom: 4px;
    }
  }
  &-novelist::before {
    content: '\f304';
  }
  &-illustrator::before {
    content: '\f53f';
  }
  &-anchor {
    color: palette('scheme', 'gray');
  }
}

.p-ranking-label {
  &-rank {
    font-size: 13px;
    font-weight: bold;
    background: #eff0eb 0% 0% no-repeat padding-box;
    border-radius: 4px;
    text-align: center;
    display: inline-block;
    padding: 5px 8px;
    margin-right: 8px;
    @include mq() {
      font-size: 15px;
    }
    &.--number1,
    &.--number2,
    &.--number3 {
      padding: 2px 8px;
      @include mq() {
        padding: 3px 8px;
      }
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f521';
        font-size: 16px;
        color: #fff;
        margin-right: 8px;
        @include mq() {
          font-size: 19px;
        }
      }
    }
    &.--number1 {
      background: transparent linear-gradient(245deg, #ffee00 0%, #ebbe40 100%) 0% 0% no-repeat padding-box;
    }
    &.--number2 {
      background: transparent linear-gradient(244deg, #d7d8d5 0%, #c5b1b1 100%) 0% 0% no-repeat padding-box;
    }
    &.--number3 {
      background: transparent linear-gradient(67deg, #d4a341 0%, #f1ae29 100%) 0% 0% no-repeat padding-box;
    }
  }

  &-new {
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background: #ff6160 0% 0% no-repeat padding-box;
    border-radius: 2px;
    padding: 4px 7px;
  }
}
