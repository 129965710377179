.p-signIn {


  &-header {
    &-title {
      padding: 13px 17px;
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
      font-size: 17px;
      font-weight: 700;
      text-align: center;
      letter-spacing: .075em;

      @include mq() {
        padding: 65px 35px 0;
        border-bottom: none;
        font-size: 22px;
      }
    }

    &-note {
      margin-top: 16px;
      padding: 0 17px;
      text-align: center;
    }
  }

  &-section {
    margin-top: 30px;
    padding: 0 17px 50px;
    font-size: 14px;
    & + & {
      padding-top: 30px;
      border-top: 14px solid palette('scheme', 'light-gray-primary');
    }
    &-inner {
      max-width: 600px;
      margin: 0 auto;
    }
    &-title {
      margin-bottom: 30px;
      font-size: 17px;
      font-weight: 700;
      text-align: center;
    }
    &-message {
      margin-bottom: 30px;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.618;
      text-align: center;
    }

    @include mq() {
      margin-top: 30px;
      padding: 0 35px 80px;
      font-size: 15px;
      & + & {
        position: relative;
        padding-top: 60px;
        border-top: none;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 600px;
          height: 1px;
          background: palette('scheme', 'light-gray-primary');
          transform: translateX(-50%);
        }
      }
      &-title {
        font-size: 22px;
      }
    }
  }

  &-sns {
    width: 225px;
    margin: 0 auto;

    @include mq() {
      display: flex;
      justify-content: space-between;
      width: 458px;
      margin: 50px auto 0;
    }

    &-col {
      margin: 10px auto 0;

      @include mq() {
        margin: 0 auto 15px;
      }

      &.-signup {
        width: 280px;
        @include mq() {
          width: 300px;
        }
      }
    }

    &-button {
      display: block;
      padding: 7px 20px;
      border: 1px solid;
      border-radius: 34px;
      color: #fff;
      font-size: 17px;
      line-height: 1.2;
      text-align: center;
      text-decoration: none;
      span {
        position: relative;
        display: inline-block;
        padding-left: 22px;
        &::before {
          position: absolute;
          top: 50%;
          left: 0;
          transform: translateY(-50%);
          font-family: 'Font Awesome 5 Brands';
          font-weight: 400;
        }
      }
      &.-twitter {
        background: #2aa3ef;
        border-color: #2aa3ef;
        span::before {
          content: '\f099';
        }
      }
      &.-google {
        background: #4688f1;
        border-color: #4688f1;
        span::before {
          content: '\f1a0';
        }
      }

      @include mq(lg) {
        &:hover {
          &.-twitter {
            background: #fff;
            color: #2aa3ef;
            span::before {
              color: #2aa3ef;
            }
          }
          &.-google {
            background: #fff;
            color: #4688f1;
            span::before {
              color: #4688f1;
            }
          }
        }
      }
    }
  }

  &-block {
    margin-top: 20px;

    @include mq() {
      margin-top: 30px;
    }
  }

  &-link {
    margin-top: 30px;
    font-size: 13px;
    text-align: center;
    &-anchor {
      color: palette('scheme', 'gray');
    }
  }

  &-genreList {

    @include mq() {
      margin-top: -15px;
    }
  }

  &-checkbox-list li {

    @include mq(lg) {
      min-width: calc(20% - 6px);
    }
  }
}
