.c-note-primary {
  margin: 10px 0 0 1em;
  color: palette('scheme', 'gray');
  font-size: 12px;
  text-indent: -1em;

  &.-settings {
    margin-top: 8px;
  }

  > a {
    color: inherit;
  }

  &.-normal {
    margin-left: 0;
    text-indent: 0;
  }

  &.-caution {
    color: palette('scheme', 'red');
  }

  @include mq() {
    font-size: 13px;
  }
}

.c-textLink {
  &-theme {
    color: palette('scheme', 'theme');
  }
  &-gray {
    color: palette('scheme', 'gray');
  }
}

.c-textButton {
  &-theme,
  &-gray {
    font-size: 13px;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }

    @include mq() {
      font-size: 15px;
    }
  }
  &-theme {
    color: palette('scheme', 'theme');
  }
  &-gray {
    color: palette('scheme', 'gray');
  }
}

.c-star {
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
  &::before {
    font-family: 'Font Awesome 5 Free';
    color: palette('scheme', 'corn-yellow');
  }
  &.-full::before {
    content: '\f005';
    font-weight: 900;
  }
  &.-half::before {
    content: '\f5c0';
    font-weight: 900;
  }
  &.-blank::before {
    content: '\f005';
    font-weight: 400;
  }
}

.c-arrow{
  position: relative;
  display: inline-block;
  padding-right:20px;
  color: #030303;
  vertical-align: middle;
  text-decoration: none;
  &::before,
  &::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }

  &::before{
    box-sizing: border-box;
    width: 20px;
    height: 20px;
    background: #030303;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  &::after{
    left: 5px;
    width: 8px;
    height: 8px;
    border-top: 2px solid #ffffff;
    border-right: 2px solid #ffffff;
    transform: rotate(45deg);
  }

  &.-colorReverse{
    color: #ffffff;
    &::before{
      background: #ffffff;
    }
    &::after{
      border-top: 2px solid #030303;
      border-right: 2px solid #030303;
    }
  }

  &.-colorRed{
    color: #FF6160;
    &::before{
      background: #FF6160;
    }
    &::after{
      border-top: 2px solid #ffffff;
      border-right: 2px solid #ffffff;
    }
  }
}

.c-arrow-small {
  position: relative;
  display: inline-block;
  padding-right:16px;
  color: #030303;
  vertical-align: middle;
  text-decoration: none;
  &::before,
  &::after{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    content: "";
    vertical-align: middle;
  }

  &::before{
    box-sizing: border-box;
    width: 16px;
    height: 16px;
    background: #030303;
    -webkit-border-radius: 50%;
    border-radius: 50%;
  }
  &::after{
    left: 5px;
    width: 5px;
    height: 5px;
    border-top: 1px solid #ffffff;
    border-right: 1px solid #ffffff;
    transform: rotate(45deg);
  }

  &.-colorReverse{
    color: #ffffff;
    &::before{
      background: #ffffff;
    }
    &::after{
      border-top: 2px solid #030303;
      border-right: 2px solid #030303;
    }
  }

  &.-colorRed{
    color: #FF6160;
    &::before{
      background: #FF6160;
    }
    &::after{
      border-top: 1px solid #ffffff;
      border-right: 1px solid #ffffff;
    }
  }
}
