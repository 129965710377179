$hero-slider-bp: 480px;

@mixin swiperDefaultButton(){
  .swiper-button-next {
    display: none;
    position: absolute;
    bottom: 50%;
    right: 40px;
    color: #030303;
    vertical-align: middle;
    text-decoration: none;
    &:hover{
      opacity: .6;
    }

    &::before,
    &::after{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: "";
      vertical-align: middle;
    }

    &::before{
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: #030303;
      opacity: .5;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
    &::after{
      left: 10px;
      width: 14px;
      height: 14px;
      border-top: 3px solid #ffffff;
      border-right: 3px solid #ffffff;
      transform: rotate(45deg);
    }
    @include mq(){
      display: inline-block;
    }
  }

  .swiper-button-prev {
    display: inline-block;
    position: absolute;
    bottom: 50%;
    left: 40px;
    color: #030303;
    vertical-align: middle;
    text-decoration: none;
    &:hover{
      opacity: .6;
    }

    &::before,
    &::after{
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      content: "";
      vertical-align: middle;
    }

    &::before{
      box-sizing: border-box;
      width: 40px;
      height: 40px;
      background: #030303;
      opacity: .5;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
    &::after{
      right: 10px;
      width: 14px;
      height: 14px;
      border-bottom: 3px solid #ffffff;
      border-left: 3px solid #ffffff;
      transform: rotate(45deg);
    }
    @include mq(){
      display: none;
    }
  }

  .swiper-button-disabled{
    display: none
  }
}

.top-heroSlider {
  padding: 0;
  @media screen and (min-width: $hero-slider-bp) {
    padding: 16px 0;
    background: #EFF0EB;
  }
  @include swiperDefaultButton();
  .swiper-slide{
    transform: translateZ(0);
    width: 100%;
    margin: 0;
    @media screen and (min-width: $hero-slider-bp) {
      margin: 0 8px;
      width: auto;
    }
  }
  &-novel{
    position: relative;
    &-coverImg{
      width: 100%;
      aspect-ratio: 90 / 47;
      position: relative;
      overflow: hidden;
      @media screen and (min-width: $hero-slider-bp) {
        width: 480px;
        height: 251px;
        border-radius: 5px;
      }
      img {
        width: 100%;
        object-fit: cover;
      }
    }

    &-textArea{
      position: absolute;
      background: rgba(0,0,0,0.4);
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 24px 16px 12px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      will-change: transform;
      z-index: 2;
      border-radius: 0 0 4px 4px;

      &:before{
        content: "Pick up!";
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 15px;
        transform: translateY(-50%);
        width: 66px;
        height: 27px;
        background: #FFDE07;
        color: #2B2B2B;
        font: italic normal bold 13px "Jost";
        border-radius: 2px;
        @media screen and (min-width: $hero-slider-bp) {
          left: 12px;
        }
      }
      h3{
        margin: 0;
        color: #ffffff;
        font-size: 14px;
        font-weight: bold;
        line-height: 1;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    &-textWrap{
      display: block;
      flex:auto 1 1;
      margin-top: 12px;
      overflow: hidden;
    }

    &-outlineText{
      color: #ffffff;
      font-size: 14px;
      line-height: calc(21 / 14);
      //行数を超えるものは省略
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      &.-displayNone{
        display: none;
      }
    }

    &-catchCopy{
      color: #ffffff;
      font-size: 14px;
      line-height: calc(21 / 14);
      //行数を超えるものは省略
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      &.-displayNone{
        display: none;
      }
    }
  }

  &-hero{
    a{
      display: block;
    }
    img{
      object-fit: cover;
      width: 100%;
      @media screen and (min-width: $hero-slider-bp) {
        width: 480px;
        height: 251px;
      }
    }
  }

  &-pagination {
    margin-top: 16px;
    text-align: center;
    line-height: 1;
    .swiper-pagination-bullet {
      margin: 0 4px;
    }
  }

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    @media screen and (min-width: $hero-slider-bp) {
      display: inline-block;
    }
  }
}

.creator-heroSlider{
  overflow: hidden;
  .swiper-container {
    max-width: 1388px;
    margin: 0 auto;
    overflow: visible;
    padding: 30px 22px 52px;
    @include mq(){
      padding: 65px 0;
    }

    .swiper-pagination {
      bottom: 0;

      .swiper-pagination-bullet {
        width: 12px;
        height: 12px;
        background: #C9C9C9 0% 0% no-repeat padding-box;
        opacity: 1;
        margin: 0 6px;

        &.swiper-pagination-bullet-active {
          background: #F15352 0% 0% no-repeat padding-box;
        }
      }

    }
  }

  .swiper-slide:not(.swiper-slide-active) {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: #272729 0% 0% no-repeat;
      opacity: 0.7;
    }
  }
}


.index-section {
  position: relative;
  margin-top: 48px;

  @include swiperDefaultButton();

  .swiper-button-prev,
  .swiper-button-next {
    display: none;
    @media screen and (min-width: $hero-slider-bp) {
      display: inline-block;
    }
  }

  .swiper-button-disabled {
    display: none;
  }

  &:first-child {
    margin-top: 0;
  }
  &:last-child{
    margin-bottom: 124px;
    @include mq(){
      margin-bottom: 136px;
    }
  }

  .swiper-container {
    .swiper-wrapper {
      .swiper-slide {
        width: 100%;
        max-width: 140px;
        @include mq(){
          width: 139px;
        }
      }
    }
  }

  &-header {
    position: relative;
    display: flex;
    padding-right: 16px;

    @include mq() {
      // max-width: 1270px;//ぶち抜きに変更
      margin: 0 auto;
      padding-right: 24px;
    }
  }

  &-headerInner{
    display: flex;
    justify-content: space-between;
    align-items:center;
    width: 100%;
    padding-top: 8px;
    border-top: solid 1px #c5c5c5;
    @include mq(){
      width: 451px;
    }
  }

  &-anchor{
    text-decoration: none;
    &:hover{
      opacity: .6;
    }
  }

  &-listLinkText{
    span{
      vertical-align: middle;
    }
    i {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-left: 4px;
    }
  }

  &-title {
    flex: 1;
    padding-right: 15px;
    font-size: 16px;
    line-height: 1;
    font-weight: 700;
    @include mq(){
      font-size: 18px;
    }

    @at-root{
      @keyframes -isSpin {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(1turn);
        }
      }
    }
    .fa-redo-alt{
      opacity: 0;
      transition: .4s;
      color: palette('scheme', 'gray');
      animation: -isSpin 2s linear infinite;

      &.-isValidating{
        opacity: 1;
      }
    }
  }

  &-loader {
    padding-left: 23px;
    margin-top: 24px;
    @include mq() {
      margin: 24px auto 0;
      padding-left: 125px;
    }
  }

  &-body {
    width: 100%;
    // max-width: 1270px;//ぶち抜きに変更
    // margin-left: auto;
    // margin-right: auto;
    margin-top: 24px;
  }

  &-barLink{
    background:#535660;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 12px calc(100% / 2 - 1200px / 2);
    @media screen and ( max-width:calc(1200px + 10px * 2)) {
      padding: 12px 10px;
    }
    @include mq(md,max){
      display: none;
    }

    &-item{
      width: auto;
      flex: auto 1 1;
      text-align: center;
      border-right: 1px solid #ffffff;
      &:first-child{
        border-left: 1px solid #ffffff;
      }
      a{
        text-decoration: none;
      }
      &:hover{
        .index-section-barLink-text{
          color: rgba(255,255,255,0.6);
        }
      }
    }

    &-text{
      color: #ffffff;
      font-size: 15px;
      line-height: calc(24/15);
    }
  }

  &-sliderBody{
    overflow: hidden;
  }

  &-sliderContainer{
    overflow: initial !important;
    margin:24px 0 0 0 !important;
  }

  &-info{
    margin: 24px 0 0 0;
    padding: 0 16px 0 0;
    @include mq(){
      margin:24px 0;
      padding: 0 24px 0 0;
    }

    &-item{
      background: #EFF0EB;
      margin-top: 16px;
      padding: 16px;
      a{
        text-decoration: none;
      }
      &:hover{
        opacity: .6;
      }
    }

    &-labels{
      display: flex;
      align-items: center;

      >*:not(:first-child){
        margin-left: 8px;
      }

      time{
        font-weight: bold;
        font-size: 15px;
        line-height: calc(24/15);
      }

      p{
        padding: 4px 8px;
        color: #ffffff;
        background: #535660;
        border-radius: 2px;

      }
    }

    h3{
      margin-top: 10px;
      font-weight: normal;
      font-size: 15px;
      line-height: calc(24/15);
    }
  }

  &-r18switch{
    max-width: 1324px;
    margin: 16px auto 0;
    padding: 0 17px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-size: 0;
    box-sizing: border-box;
    @include mq(){
      padding: 0 20px;
    }
    >p{
      font-size: 13px;
      font-weight: bold;
    }
    >*:not(:first-child){
      margin-left: 4px;
    }
  }

  &-toggle {
    position: relative;
    display: block;
    cursor: pointer;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .index-section-toggle-button {
        background: palette('scheme', 'check-green');
        border-color: palette('scheme', 'check-green');
        &::after {
          right: 0;
          box-shadow: 0 2px 6px rgba(#000, .05);
        }
      }
    }
    &-button {
      position: relative;
      display: inline-block;
      background: #fff;
      border: 1px solid palette('scheme', 'gray');
      background: palette('scheme', 'gray');
      border-radius: 16px;
      font-size: 0;
      vertical-align: top;
      transition: .25s ease;
      width: 32px;
      height: 16px;
      @include mq(){
        width: 36px;
        height: 18px;
      }
      &::after {
        content: '';
        position: absolute;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(#000, .1);
        transition: .25s ease;
        top: 0;
        right: 16px;
        width: 14px;
        height: 14px;
        @include mq(){
          right: 18px;
          width: 16px;
          height: 16px;
        }
      }
    }

    &-checkIcon{
      position: absolute;
      bottom: 50%;
      transform:translateY(50%);
      color: #ffffff;
      left:4px ;
      &:before{
        font-size: 10px;
      }
    }
    &-timesIcon{
      position: absolute;
      bottom: 50%;
      transform:translateY(50%);
      color: #ffffff;
      right:4px;
      &:before{
        font-size: 13px;
      }
    }
  }
}

.index-works {
  position: relative;
  margin-top: 20px;

  @include mq() {
    margin-top: 30px;
  }

  &-item{
    cursor: pointer;
    &:hover{
      opacity: .7;
    }
  }

  &-item-a {
    text-decoration: none;
  }

  &-inner {
    padding: 0 17px;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }

    @include mq() {
      padding: 0 35px;
    }
  }

  &-list {
    display: flex;
    margin: 0 -7px;

    @include mq() {
      margin: 0 -10px;
    }
  }

  &-image {
    width: 100%;
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }

    @include mq() {
      &-label {
        padding: 0 0 1px;
        font-size: 13px;
      }
    }
  }

  &-detail {
    margin-top: 12px;
  }

  &-rankLabel {
    display: inline-block;
    font-size: 13px;
    font-weight: bold;
    background: #eff0eb 0% 0% no-repeat padding-box;
    border-radius: 2px;
    text-align: center;
    padding: 5px 8px;
    margin-right: 8px;
    margin-bottom: 8px;
    @include mq() {
      font-size: 15px;
    }
    &.--number1,
    &.--number2,
    &.--number3 {
      padding: 2px 8px;
      @include mq() {
        padding: 3px 8px;
      }
      &:before {
        font-family: 'Font Awesome 5 Free';
        content: '\f521';
        font-size: 15px;
        line-height: 1;
        color: #fff;
        margin-right: 8px;
        @include mq() {
          font-size: 18px;
          line-height: 24px;
        }
      }
    }
    &.--number1 {
      background: transparent linear-gradient(245deg, #ffee00 0%, #ebbe40 100%) 0% 0% no-repeat padding-box;
    }
    &.--number2 {
      background: transparent linear-gradient(244deg, #d7d8d5 0%, #c5b1b1 100%) 0% 0% no-repeat padding-box;
    }
    &.--number3 {
      background: transparent linear-gradient(67deg, #d4a341 0%, #f1ae29 100%) 0% 0% no-repeat padding-box;
    }
  }

  &-newLabel {
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    color: #f15353;
    padding: 0 7px;
    margin-bottom: 10px;
  }

  &-title {
    font-size: 13px;
    font-weight: 700;
    overflow: hidden;
    // white-space: nowrap;
    // text-overflow: ellipsis;

    //2行対応
    line-height: calc(21 / 15);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    height: 34px;


    &.--large {
      font-size: 16px;
      @include mq() {
        font-size: 15px;
      }
    }
  }


  &-copy {
    margin-top: 8px;
    font-size: 13px;
    color: #787878;
    overflow: hidden;

    //2行対応
    line-height: calc(21 / 15);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    height: 34px;
  }

  &-button {
    display: none;
    position: absolute;
    top: 0;
    z-index: 2;
    width: 35px;
    height: 100%;
    background: rgba(#000, .5);
    font-size: 0;
    &::before {
      position: absolute;
      top: 50%;
      color: #fff;
      font-family: 'Font Awesome 5 Free';
      font-size: 20px;
      font-weight: 900;
      line-height: 1;
      transform: translateY(-50%);
    }
    &.-prev {
      left: 0;
      &::before {
        content: '\f104';
        left: 12px;
      }
    }
    &.-next {
      right: 0;
      &::before {
        content: '\f105';
        left: 12px;
      }
    }
    &.is-show {
      display: block;
    }

    @include mq(lg) {
      &:hover {
        background: rgba(#000, .7);
      }
    }
  }
}

.index-section.--large {
  margin-top: 25px;
  @include mq(sm, max, true) {
    .index-section-header {
      padding: 0 17px;
    }
    .index-section-headerInner {
      max-width: 100%;
    }
  }
  .swiper-container .swiper-wrapper .swiper-slide {
    @include mq() {
      max-width: 138px;
    }
  }
}

.index-ranking {
  &-section-body {
    padding: 0 17px;

    @include mq() {
      max-width: 1270px;
      margin: 0 auto;
      padding: 0 35px;
    }
  }

  @include mq() {
    display: flex;
    flex-wrap: wrap;
    margin: -5px -5px 0;
  }

  &-item {
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
    counter-increment: index-rank;
    &:nth-child(-n+5) .index-ranking-detail {
      &::before {
        content: '\f521';
        position: absolute;
        top: 4px;
        left: 4px;
        z-index: 3;
        color: #fff;
        font-size: 15px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        line-height: 1;
        text-decoration: none;
      }
      &::after {
        padding: 0 5px 0 26px;
      }
    }
    &:nth-child(1) .index-ranking-detail::after {
      background: palette('scheme', 'rank-gold');
    }
    &:nth-child(2) .index-ranking-detail::after {
      background: palette('scheme', 'rank-silver');
    }
    &:nth-child(3) .index-ranking-detail::after {
      background: palette('scheme', 'rank-bronze');
    }

    @include mq() {
      width: calc(50% - 10px);
      margin: 0 5px;
      padding: 30px 10px 30px 0;
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
  }

  &-image {
    width: 30%;
    min-width: 120px;
    max-width: 160px;
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      position: relative;
      display: block;
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }
    img {
      width: 100%;
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
      &-label {
        padding: 0 0 1px;
        font-size: 13px;
      }
    }
  }

  &-detail {
    position: relative;
    flex: 1;
    margin-left: 15px;
    padding-top: 36px;
    &::after {
      content: counter(index-rank);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      min-width: 24px;
      padding: 0 3px;
      background: palette('scheme', 'light-black');
      color: #fff;
      font-family: $ff-en;
      font-size: 18px;
      font-weight: 700;
      font-style: italic;
      line-height: 24px;
      text-align: center;
      text-decoration: none;
    }

    @include mq() {
      margin-left: 18px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 17px;
    }
  }

  &-creator {
    margin-top: 12px;
    li {
      position: relative;
      display: inline-block;
      margin-top: 3px;
      padding-left: 14px;
      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        color: palette('scheme', 'gray');
        font-family: 'Font Awesome 5 Free';
        font-size: 11px;
        font-weight: 900;
      }
      &:first-child {
        margin-right: 16px;
      }
    }
    &-type01::before {
      content: '\f304';
    }
    &-type02::before {
      content: '\f53f';
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 12px;
    }

    @include mq() {
      margin-top: 14px;
      li {
        padding-left: 16px;
        &::before {
          top: 1px;
          font-size: 12px;
        }
      }
      &-anchor {
        font-size: 13px;
      }
    }
  }
}

.index-ads {
  margin-bottom: 50px;

  @include mq() {
    margin-bottom: 80px;
  }
}

.bar-loader-container {
  display: flex;
  justify-content: center;
}
