.chat {
  &-wrapper {
    width: 100%;
    overflow-y: auto;
    position: fixed;

    @include mq() {
      display: flex;
      flex-direction: row-reverse;
      max-width: 1270px;
      margin: 0 auto;
      padding: 0 35px;
      overflow-y: initial;
      position: relative;
    }
  }

  &-main {

    @include mq() {
      flex: 1;
      min-width: 0;
      border-right: 1px solid palette('scheme', 'light-gray-primary');
    }
  }

  &-side {

    @include mq() {
      display: flex;
      flex-direction: column;
      width: 40%;
      max-width: 360px;
    }
  }

  &-bnr {
    display: block;
  }

  &-rooms {
    @include mq() {
      border-right: 1px solid palette('scheme', 'light-gray-primary');
      border-left: 1px solid palette('scheme', 'light-gray-primary');
      flex-grow: 1;
      overflow-y: auto;
    }
  }

  &-timeline {
    height: 100%;
    overflow: auto;

    &-logs {
      display: flex;
      flex-direction: column-reverse;
    }
    &-messages {
      display: flex;
      flex-direction: column-reverse;
    }
    &-unread-anchor {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &::before {
        background: #f15353;
        content: "";
        flex: 1;
        height: 1px;
      }

      &::after {
        color: #f15353;
        content: "未読";
        font-weight: 700;
        margin-left: 10px;
      }
    }
    &-anchor {
      pointer-events: none;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 50px;
    }

    &-inner {
      min-height: 100%;
      padding: 0 14px;
      position: relative;
    }
    @include mq() {
      &-inner {
        padding: 0 30px;
      }
    }
  }
}

.chatIndex {
  &-header {
    background: palette('scheme', 'header-gray');

    &-inner {
      position: relative;
      padding: 14px 52px;

      @include mq() {
        padding: 25px 90px;
      }
    }

    &-title {
      font-size: 17px;
      text-align: center;

      @include mq() {
        font-size: 22px;
      }
    }

    &-back {
      display: block;
      position: absolute;
      top: 50%;
      left: 6px;
      width: 32px;
      height: 32px;
      text-decoration: none;
      transform: translateY(-50%);
      &::before {
        content: '\f104';
        position: absolute;
        top: 50%;
        left: 50%;
        color: palette('scheme', 'black');;
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        transform: translate(-50%, -50%);
      }

      @include mq() {
        left: 24px;
      }
    }
  }

  &-main {
    display: none;

    @include mq() {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 30px;
    }
  }
}

.chatDetail {
  &-header {
    //margin: 0 -17px 30px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      flex: 0 42px;
    }

    &-inner {
      position: relative;
      display: flex;
      align-items: center;
      padding: 7px 17px 7px 42px;

      @include mq() {
        padding: 10px 30px 10px 42px;
      }
    }

    &-image {
      width: 32px;
      margin-right: 8px;
      .empty {
        width: 32px;
        height: 32px;
        border: 1px solid palette('scheme', 'light-gray-primary');
        border-radius: 50%;
        background-color: palette('scheme', 'light-gray-primary');
      }

      img {
        width: 32px;
        height: 32px;
        border: 1px solid palette('scheme', 'light-gray-primary');
        border-radius: 50%;
      }

      @include mq() {
        width: 48px;
      }
    }

    &-detail,
    &-form {
      flex: 1;
      min-width: 0;
    }

    &-title,
    &-name {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &-title {
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2;
    }

    &-name {
      margin-top: 2px;
      line-height: 1.2;
    }

    &-back,
    &-edit,
    &-operation {
      display: block;
      width: 32px;
      height: 32px;
      text-decoration: none;
      font-size: 0;
      &::before {
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        transform: translate(-50%, -50%);
      }
    }

    &-edit,
    &-operation {
      background: #fff;
      border-radius: 50%;
      margin-left: 15px;
      position: relative;

      @include mq() {
        margin-left: 16px;
      }
    }

    &-back {
      position: absolute;
      top: 50%;
      left: 6px;
      transform: translateY(-50%);
      &::before {
        content: '\f104';
        color: palette('scheme', 'black');
      }
    }

    &-edit {
      border: 1px solid palette('scheme', 'light-gray-secondary');
      &::before {
        content: '\f303';
        color: palette('scheme', 'default');
      }
    }

    &-operation {
      border: 1px solid palette('scheme', 'theme');
      &::before {
        content: '\f590';
        color: palette('scheme', 'theme');
      }
      &.is-operation {
        background: palette('scheme', 'theme');
        &::before {
          color: #fff;
        }
      }
    }
  }

  &-main {
    //padding: 0 17px 14px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    @include mq() {
      position: relative;
      min-width: 0;
    }
  }

  &-side {
    display: none;

    @include mq() {
      display: flex;
    }
  }
}

.chat-user {
  &-item {
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
  }

  &-anchor {
    display: flex;
    align-items: center;
    padding: 24px 17px;
    text-decoration: none;
  }

  &-image {
    position: relative;
    width: 60px;
    margin-right: 20px;
    .empty {
      width: 60px;
      height: 60px;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
      background-color: palette('scheme', 'light-gray-primary');;
    }

    img {
      width: 60px;
      height: 60px;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }
  }

  &-unread {
    position: absolute;
    bottom: -4px;
    right: -4px;
    z-index: 2;
    width: 24px;
    height: 24px;
    background: palette('scheme', 'theme');
    border: 1px solid #fff;
    border-radius: 50%;
    color: #fff;
    font-size: 11px;
    font-weight: 700;
    line-height: 22px;
    text-align: center;
  }

  &-detail {
    flex: 1;
    min-width: 0;
  }

  &-title,
  &-name,
  &-latest-message {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
  }

  &-latest {
    display: flex;
    color: palette('scheme', 'gray');
    &-col01 {
      flex: 1;
      min-width: 0;
    }
    &-col02 {
      width: 40px;
      margin-left: 20px;
      text-align: right;
    }
  }

  &-item.-operation {
    background: palette('scheme', 'theme');
    border-bottom: 1px solid palette('scheme', 'theme');
    .chat-user-image img {
      border-color: #fff;
    }
    .chat-user-anchor,
    .chat-user-latest {
      color: #fff;
    }
  }
}

.chat-comment {
  display: flex;
  width: 100%;
  margin-bottom: 20px;

  &:last-child {
    margin-top: 20px;
  }

  &-image {
    width: 32px;
    margin-right: 8px;

    .empty {
      width: 32px;
      height: 32px;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
      background-color: palette('scheme', 'light-gray-primary');
    }

    img {
      width: 32px;
      height: 32px;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 48px;

      .empty, img {
        width:48px;
        height: 48px;
      }
    }
  }

  &-detail {
    flex: 1;
  }

  &-message {
    padding: 5px 10px;
    border-radius: 7px;
    font-size: 15px;
    line-height: 1.4;

    overflow-wrap: anywhere;

    @include mq() {
      padding: 8px 12px;
    }
  }

  &-date {
    display: block;
    margin-top: 5px;
    color: palette('scheme', 'gray');
  }

  &-upload {
    a {
      display: inline-block;
      position: relative;
      width: 250px;
      height: 250px;
    }
    &.-vertical a {
      max-width: 280px;
    }
    img {
      border-radius: 7px;
      object-fit: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
    }
  }

  &-placeholder {
    width: 100%;
    height: 100%;
  }

  &.-self {
    justify-content: flex-end;
    .chat-comment-inner {
      width: 60%;
      max-width: 400px;
      min-width: 200px;
    }
    .chat-comment-message {
      background: #333;
      color: #fff;
      a {
        color: inherit;
      }
    }
    .chat-comment-date {
      text-align: right;
    }
    .chat-comment-upload {
      text-align: right;
    }
    &.-operation .chat-comment-message {
      background: palette('scheme', 'theme');
    }
  }
  &.-partner {
    .chat-comment-inner {
      display: flex;
      width: 70%;
      max-width: 440px;
      min-width: 220px;
    }
    .chat-comment-message {
      background: palette('scheme', 'light-gray-primary');
    }
    &.-operation .chat-comment-message {
      background: palette('scheme', 'light-theme');
      color: palette('scheme', 'theme');
    }
  }
}

.chat-action {
  position: relative;
  margin: 14px 17px;
  form {
    width: calc(100% - 47px);
    margin-left: 47px;
  }

  @include mq() {
    margin: 24px;
  }

  &-upload {
    position: absolute;
    top: 0;
    //left: -47px;
    display: inline-block;
    width: 36px;
    height: 36px;
    overflow: hidden;
    cursor: pointer;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }
    &-text {
      font-size: 0;
      &::after {
        content: '\f03e';
        position: absolute;
        top: 50%;
        left: 50%;
        font-size: 22px;
        font-family: 'Font Awesome 5 Free';
        font-weight: 400;
        transform: translate(-50%, -50%);
      }
    }
  }

  &-textfield {
    width: 100%;
    padding: 5px 46px 5px 10px;
    background: #fff;
    border: 1px solid palette('scheme', 'medium-gray');
    border-radius: 5px;
    box-shadow: 0 6px 20px rgba(#000, .1);
    overflow-x: hidden;
    overflow-y: auto;
    &.is-operation {
      background: palette('scheme', 'light-theme');
      border-color: palette('scheme', 'light-theme');
      color: palette('scheme', 'theme');
    }
  }

  &-submit {
    position: absolute;
    top: 0;
    right: 4px;
    z-index: 2;
    width: 36px;
    height: 36px;
    font-size: 0;
    &::after {
      content: '\f1d8';
      position: absolute;
      top: 50%;
      left: 50%;
      color: palette('scheme', 'theme');
      font-size: 17px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      transform: translate(-50%, -50%);
    }
  }
}
