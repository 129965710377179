.c-label-primary {
  display: flex;
  margin-bottom: 8px;
  font-size: 13px;
  white-space: nowrap;
  &-note {
    margin-left: 1em;
    padding: 2px 0 0 10px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    line-height: 1.4;
    text-indent: -1em;
    white-space: normal;

    &.-normal {
      margin-left: 0;
      text-indent: 0;
    }
  }

  &.-genre {
    display: none;
  }

  @include mq() {
    margin-bottom: 12px;
    font-size: 15px;
    &-note {
      padding: 4px 0 0 10px;
      font-size: 13px;
    }
  }
}

.c-textfield-primary {
  width: 100%;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid palette('scheme', 'medium-gray');
  border-radius: 5px;
  box-shadow: 0 6px 20px rgba(#000, .1);
  &.-xsmall {
    max-width: 170px;
  }
  &.-small {
    max-width: 400px;
  }
  &.-medium {
    max-width: 600px;
  }
  &-label {
    position: relative;
    top: -8px;
    margin-left: 5px;
  }
  &.-disabled {
    background-color: palette('scheme', 'medium-gray');
  }
}

.c-search-textfield {
  position: relative;
  display: inline-block;
  &::before {
    content: '\f002';
    position: absolute;
    top: 9px;
    left: 14px;
    z-index: 2;
    color: palette('scheme', 'gray');
    font-family: 'Font Awesome 5 Free';
    font-size: 17px;
    font-weight: 900;
    line-height: 1;
    pointer-events: none;
  }
  .c-textfield-primary {
    padding: 5px 10px 5px 35px;
  }
}

.c-textarea-primary {
  width: 100%;
  height: 120px;
  min-height: 120px;
  padding: 5px 10px;
  background: #fff;
  border: 1px solid palette('scheme', 'medium-gray');
  border-radius: 5px;
  box-shadow: 0 6px 20px rgba(#000, .1);

  &.-height-medium-large {
    height: 148px;
  }

  @include mq() {
    &.-height-small {
      height: 140px;
    }
    &.-height-medium {
      height: 220px;
    }
    &.-height-medium-large {
      height: 256px;
    }
    &.-height-large {
      height: 310px;
    }
  }
}

.c-radio-primary {
  display: inline-block;
  cursor: pointer;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .c-radio-primary-text::after {
      opacity: 1;
    }
  }
  &-text {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    font-size: 13px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid palette('scheme', 'gray');
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      width: 10px;
      height: 10px;
      background: palette('scheme', 'gray');
      border-radius: 50%;
      opacity: 0;
    }
  }

  @include mq() {
    &-text {
      font-size: 15px;
      &::before {
        top: 1px;
      }
      &::after {
        top: 6px;
      }
    }
  }
}

.c-radio-secondary {
  display: inline-block;
  cursor: pointer;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .c-radio-secondary-text::after {
      opacity: 1;
    }
  }
  &-text {
    position: relative;
    display: inline-block;
    padding-left: 28px;
    font-size: 13px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid palette('scheme', 'gray');
      border-radius: 50%;
    }
    &::after {
      content: '';
      position: absolute;
      top: 5px;
      left: 5px;
      z-index: 2;
      width: 10px;
      height: 10px;
      background: palette('scheme', 'theme');
      border-radius: 50%;
      opacity: 0;
    }
  }

  @include mq() {
    &-text {
      font-size: 15px;
      &::before {
        top: 1px;
      }
      &::after {
        top: 6px;
      }
    }
  }
}

.c-checkbox-primary {
  position: relative;
  display: block;
  width: 100%;
  cursor: pointer;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .c-checkbox-primary-text {
      background: palette('scheme', 'light-gray-primary');
      border-color: palette('scheme', 'light-gray-primary');
    }
  }
  &-text {
    display: block;
    padding: 4px 8px;
    border: 1px solid palette('scheme', 'light-gray-secondary');
    border-radius: 24px;
    font-size: 13px;
    text-align: center;
    &.-spacing-sm {
      letter-spacing: -.15em;
    }
  }

  @include mq(lg) {
    &:hover .c-checkbox-primary-text {
      background: palette('scheme', 'light-gray-primary');
      border-color: palette('scheme', 'light-gray-primary');
    }
  }
}

.c-checkbox-secondary {
  display: inline-block;
  cursor: pointer;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .c-checkbox-secondary-text::after {
      opacity: 1;
    }
  }
  &-text {
    position: relative;
    display: inline-block;
    padding-left: 24px;
    font-size: 13px;
    &::before {
      content: '';
      position: absolute;
      top: 2px;
      left: 0;
      width: 16px;
      height: 16px;
      border: 1px solid palette('scheme', 'medium-gray');
      border-radius: 5px;
      box-shadow: 0 6px 20px rgba(#000, .1);
    }
    &::after {
      content: '\f00c';
      position: absolute;
      top: 5px;
      left: 3px;
      z-index: 2;
      color: palette('scheme', 'theme');
      font-family: 'Font Awesome 5 Free';
      font-size: 10px;
      font-weight: 900;
      line-height: 1;
      opacity: 0;
    }
  }

  @include mq() {
    &-text {
      font-size: 15px;
      &::before {
        top: 3px;
        width: 17px;
        height: 17px;
      }
      &::after {
        top: 6px;
        font-size: 11px;
      }
    }
  }
}

.c-toggle-checkbox {
  display: inline-block;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    &:checked + .c-toggle-checkbox-text {
      background: palette('scheme', 'check-green');
      border-color: palette('scheme', 'check-green');
      &::after {
        left: 19px;
        box-shadow: 0 4px 10px rgba(#000, .1);
      }
    }
  }
  &-text {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 32px;
    background: #fff;
    border: 1px solid palette('scheme', 'light-gray-primary');
    border-radius: 32px;
    font-size: 0;
    vertical-align: top;
    transition: .25s ease;
    &::after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 28px;
      height: 28px;
      background: #fff;
      border-radius: 50%;
      box-shadow: 0 4px 10px rgba(#000, .2);
      transition: .25s ease;
    }
  }
}

.c-select-primary {
  position: relative;
  display: inline-block;
  &.-full {
    display: block;
  }
  &::after {
    content: '\f107';
    position: absolute;
    top: 50%;
    right: 10px;
    color: palette('scheme', 'gray');
    font-family: 'Font Awesome 5 Free';
    font-size: 17px;
    font-weight: 900;
    transform: translateY(-50%);
    pointer-events: none;
  }
  &-body {
    width: 100%;
    padding: 3px 30px 3px 10px;
    border: 1px solid palette('scheme', 'light-gray-secondary');
    border-radius: 5px;
    overflow: hidden;
  }
}

.c-required-label {
  height: 22px;
  padding: 3px 8px;
  margin-left: 9px;
  display: flex;
  align-items: center;
  color: #fff;
  background-color: #ff6160;
  font-weight: bold;
  font-size: 13px;
  border-radius: 2px;
}
