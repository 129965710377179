.p-page-header {
  margin-bottom: 30px;
  border-bottom: 1px solid palette('scheme', 'light-gray-primary');

  @include mq() {
    margin-bottom: 80px;
  }

  &-inner {
    padding: 13px 17px;

    @include mq() {
      padding: 24px 35px;
    }
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      font-size: 22px;
    }
  }
}

.p-ads {
  margin-top: 40px;
  text-align: center;

  @include mq() {
    margin-top: 65px;
  }

  &-inner {
    max-width: 360px;
    margin: 0 auto;

    @include mq() {
      max-width: 808px;
    }
  }

  &-label {
    padding: 0 10px 6px;
    color: palette('scheme', 'medium-gray');
    font-family: $ff-en;
    font-size: 11px;
    text-align: right;


    @include mq() {
      padding: 0 20px 6px;
    }
  }

  .l-single & {
    margin-right: -17px;
    margin-left: -17px;

    @include mq() {
      margin-right: -35px;
      margin-left: -35px;
    }
  }

  .l-column-main & {

    @include mq(xl) {
      &-label {
        max-width: 768px;
        margin: 0 auto;
        padding: 0 0 6px;
      }
    }
  }

  .l-column-side & {

    @include mq(xl) {
      margin-top: 40px;
      &-label {
        margin-right: -20px;
        padding: 0 0 6px;
      }
    }
  }
}

.p-button {
  width: 225px;
  margin: 30px auto 0;
  &.-spacer-sm {
    margin: 17px auto 0;
  }

  @include mq() {
    margin: 50px auto 0;
    &.-spacer-sm {
      margin: 34px auto 0;
    }
  }

  @media screen and (max-width: 321px) {
    width: 100%;
  }

  &-row {
    display: flex;
    justify-content: space-between;
    max-width: 370px;
    margin: 30px auto 0;
    &.-spacer-sm {
      margin: 17px auto 0;
    }

    @include mq() {
      margin: 50px auto 0;
      &.-spacer-sm {
        margin: 34px auto 0;
      }
    }
  }

  &-col {
    width: calc(50% - 4px);
  }
}

.p-checkbox-list {
  margin: -7px -3px 0;
  font-size: 0;
  li {
    display: inline-block;
    min-width: calc(50% - 6px);
    margin: 7px 3px 0;

    @include mq(xs) {
      min-width: calc(33.33334% - 6px);
    }
    @include mq() {
      min-width: calc(20% - 6px);
    }
    @include mq(lg) {
      min-width: calc(14.28571% - 6px);
    }
  }
}

.p-checkbox-list + .p-checkbox-list {
  margin-top: 0px;
}

.p-multiSelect {
  display: flex;
  &-separate {
    display: inline-block;
    width: 24px;
    padding-top: 10px;
    line-height: 1;
    text-align: center;
    vertical-align: top;
  }
  &-select {
    width: calc(50% - 12px);
    &::after {
      content: none;
    }
    &-body {
      padding: 3px 10px;
    }
  }
}

.p-birthSelect {
  display: flex;
  justify-content: space-between;
  &-select {
    width: calc(33.33334% - 7px);
    &-body {
      padding: 3px 10px;
    }
  }
}

.p-linkList {
  margin-top: 20px;
  border-top: 1px solid palette('scheme', 'light-gray-primary');
  li {
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
  }
  &-anchor {
    position: relative;
    display: block;
    padding: 10px 42px 10px 17px;
    text-decoration: none;
    &::after {
      content: '\f105';
      position: absolute;
      top: 50%;
      right: 18px;
      color: palette('scheme', 'theme');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      transform: translateY(-50%);
    }

    &.-disabled {
      pointer-events: none;
      color: #e5e5ea;
      &:after {
        color: #e5e5ea;
      }
    }
  }

  @include mq() {
    &-anchor {
      font-size: 15px;
    }
  }
  @include mq(lg) {
    &-anchor:hover {
      opacity: .6;
    }
  }
}

.p-dataList {
  margin-top: 20px;
  border-top: 1px solid palette('scheme', 'light-gray-primary');

  &-item {
    display: flex;
    padding: 10px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      font-size: 15px;
    }
  }

  &-label {
    width: 50%;
    padding-right: 20px;
  }

  &-text {
    width: 50%;
    text-align: right;
  }
}

.p-editList {
  margin-top: 20px;
  border-top: 1px solid palette('scheme', 'light-gray-primary');

  &-item {
    position: relative;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      font-size: 15px;
    }
  }

  &-title {
    display: block;
    padding: 10px 60px 10px 17px;
    text-decoration: none;

    @include mq(lg) {
      &:hover {
        opacity: .6;
      }
    }
  }

  &-edit {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
    padding: 10px 17px;
    color: palette('scheme', 'theme');
  }
}

.p-wordList-primary {
  margin: -4px -4px 0 0;
  font-size: 0;
  li {
    display: inline-block;
    margin: 4px 4px 0 0;
    padding: 2px 4px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 12px;
    line-height: 1.4;
  }

  @include mq() {
    margin: -7px -7px 0 0;
    li {
      margin: 7px 7px 0 0;
      padding: 5px 8px 3px;
      font-size: 13px;
    }
  }
}

.p-wordList-secondary {
  margin: -4px -6px 0 0;
  font-size: 0;
  li {
    display: inline-block;
    margin: 4px 6px 0 0;
    color: palette('scheme', 'theme');
    font-size: 12px;
    line-height: 1.4;
    &::before {
      content: '\0023';
    }
  }

  @include mq() {
    margin: 8px -8px 0 0;
    li {
      margin: 4px 8px 0 0;
      font-size: 13px;
    }
  }
}

.p-inlineList {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -10px -10px 0 0;
  li {
    margin: 10px 10px 0 0;
  }

  @include mq() {
    margin: -20px -20px 0 0;
    li {
      margin: 20px 20px 0 0;
    }
  }
}

.p-genreList {
  margin-top: -20px;

  &-label {
    display: none;

    @include mq() {
      display: block;
      margin: 20px 0 -22px;
      font-size: 14px;
      &:first-child {
        margin: 30px 0 -22px;
      }
    }
  }

  &-item {
    margin-top: 20px;
  }
}

.p-reviews {
  border-top: 1px solid palette('scheme', 'light-gray-primary');
  &-item {
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    padding: 24px 0;
    @include mq() {
      padding: 24px 17px;
    }

    &.-isMyitem {
      padding-bottom: 20px;
    }
  }

  &-row {
    display: flex;
  }

  &-image {
    width: 36px;
    flex-shrink: 0;
    a {
      display: block;
    }
    img {
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }
  }

  &-detail {
    padding-top: 4px;
    padding-left: 16px;

    > a {
      display: block;
      text-decoration: none;
      transition: opacity .25s ease;
      &:hover {
        opacity: 0.6;
      }
    }
  }

  &-name {
    font-size: 15px;
    font-weight: 700;
  }


  &-star {
    position: relative;
    top: -1px;
    display: inline-block;
    font-size: 0;
    .c-star {
      font-size: 12px;
      &:not(:last-child) {
        margin-right: 2px;
      }
    }

    @include mq() {
      top: 0;
      .c-star {
        font-size: 14px;
      }
    }
  }

  &-body {
    margin-top: 22px;
    @include mq() {
      margin-top: 24px;
    }
  }

  &-body-header {
    @include mq() {
      display: flex;
      align-items: flex-start;
    }
  }

  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-top: 14px;

    @include mq() {
      margin-top: 0;
      margin-left: 22px;
    }
  }

  &-comment {
    margin-top: 12px;
    font-size: 15px;
    word-break: break-word;
    @include mq() {
      margin-top: 14px;
    }
  }

  &-editWrapper {
    margin-top: 10px;
    > ul {
      display: flex;
      justify-content: flex-end;
      list-style-type: none;

      > li {
        &:last-child {
          margin-left: 5px;
        }

        > button {
          font-size: 14px;
          text-decoration: underline;
          transition: opacity 0.25s ease;
          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
}
