.p-bookshelf{
  @at-root {
    @keyframes LoadAnimation {
      0% {
        background-position: 50% 0;
      }

      100% {
        background-position: -50% 0;
      }
    }
  }
  padding-bottom: 80px;

  .p-bookshelf-backbuttonWrapper{
    position: relative;
    height: 48px;
    padding:0 20px;
    display: flex;
    align-items: center;
    @include mq(single,max,true){
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
    @include mq(single){
      background-color: #F5F5F5;
      padding:0 calc(50% - 900px / 2);
    }
  }

  .p-bookshelf-backbutton{
    >span{
      margin-left: 10px;
      font-weight: bold;
    }
  }

  .p-bookshelf-spTitle{
    position: absolute;
    right: 50%;
    bottom: 50%;
    transform: translate(50%,50%);
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .075em;
    @include mq(single){
      display: none;
    }
  }

  .p-bookshelf-header{
    margin-top: 16px;
    padding: 0 20px;
    @include mq(single){
      padding:0 calc(50% - 900px / 2);
      margin-top: 30px;
    }
  }

  .p-bookshelf-pcTitle{
    font-size: 23px;
    text-align: left;
    letter-spacing: .075em;

    @include mq(single,max,true){
      display: none;
    }
  }

  .p-bookshelf-formWrapper{
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @include mq(single) {
      margin-top: 30px;
    }
  }

  .p-bookshelf-sortIcon{
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .p-bookshelf-form{
    position: relative;
    display: inline-block;

    @include mq(single,max,true){
      margin-right: 22px;
    }

    &::before {
      pointer-events: none;
      content: '\f078';
      position: absolute;
      bottom: 50%;
      right: 12px;
      transform: translateY(50%);
      color: #707070;
      font-family: 'Font Awesome 5 Free';
      font-size: 8px;
      font-weight: 900;
    }
  }

  .p-bookshelf-select {
    border: 1px solid #707070;
    border-radius: 4px;
    padding: 6px 30px 6px 12px;
  }

  .p-bookshelf-mainSection{
    @include mq(single,max,true){
      max-width: 900px;
    }
    @include mq(single){
      padding:0 calc(50% - 900px / 2);
    }
  }

  .p-bookshelf-body {
    position: relative;
    padding: 60px 10px 50px;
    background: palette('scheme', 'light-gray-primary');
    @include mq(single){
      padding: 50px 35px;
    }

    &.-initial{
      height: 337px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      @include mq(single){
        height: 640px;
      }
      p{
        margin-top: 32px;
        font-size: 16px;
        font-weight: bold;
        color: palette('scheme', 'gray');
      }
    }

    &.-placeholder{
      .p-bookshelf-item-title{
        position: relative;
        &:before,&:after{
          content:'';
          position: absolute;
          bottom: 50%;
          left: 0;
          transform: translateY(50%);
          height: 10px;
          width: 100%;
          border-radius: 8px;
          background:linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
          background-size: 300% 100%;
          animation: 1s linear infinite LoadAnimation;
        }
      }
      li{
        position: relative;
        display: block;
        height: 19px;
        width: 80%;
        &:before{
          content:'';
          position: absolute;
          top: 0;
          left: 0;
          height: 10px;
          width: 100%;
          border-radius: 8px;
          background:linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
          background-size: 300% 100%;
          animation: 1s linear infinite LoadAnimation;
        }
      }
    }
  }


  .p-bookshelf-list{
    display: flex;
    flex-wrap: wrap;

    //flexbox一行目のマージン相殺
    margin-top: -50px;
    @include mq(single){
      margin-top: -90px;
    }
  }

  .p-bookshelf-item{
    position: relative;
    width: calc(50% - 20px);
    margin: 50px 10px 0;
    @include mq(sm){
      width: calc(33% - 20px);
    }
    @include mq(single){
      width: calc(25% - 30px);
      margin: 90px 15px 0;
    }
  }

  .p-bookshelf-anchor {
    position: relative;
    display: block;
    text-decoration: none;
    cursor: pointer;
    &:hover{
      opacity: 0.7;
    }
  }

  .p-bookshelf-image{
    img {
      width: 100%;
      border-radius: 4px;
      box-shadow: 0px 3px 8px #0000004D;
    }

    &.-deleted{
      position: relative;
      background: #303030;
      padding-top: 143%;
      img{
        position: absolute;
        bottom: 50%;
        right: 50%;
        transform: translate(50%,50%);
        width: 40%;
        max-width: 100%;
        box-shadow:none;
      }
    }
  }

  .p-bookshelf-remove {
    display: inline-block;
    position: absolute;
    top: -12px;
    right: -12px;
    z-index: 2;
    width: 24px;
    height: 24px;
    background: #3D3D3D;
    box-shadow: 3px 3px 6px #00000029;
    border-radius: 50%;
    font-size: 0;

    &::before,
    &::after {
      content: '';
      position: absolute;
      display: inline-block;
      top: 50%;
      left: 50%;
      width: 15px;
      height: 3px;
      background: #fff;
      border-radius: 1px;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:hover{
      opacity: 0.7;
    }
  }

  .p-bookshelf-item-title{
    margin-top: 8px;
    font-size: 16px;
    line-height: calc(20 / 16);
    font-weight: bold;

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    //IE用
    height: 40px;
  }

}
