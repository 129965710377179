.p-creatorMypage-header {
  background: palette('scheme', 'header-gray');

  &.-isMypage {
    background-color: #fff;
    border-bottom: 1px solid #e5e5ea;
  }

  &-inner {
    position: relative;
    padding: 14px 52px;

    @include mq() {
      padding: 25px 90px;
    }
  }

  &-title {
    font-size: 17px;
    text-align: center;

    @include mq() {
      font-size: 22px;
    }
  }

  &-icon {
    display: block;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    text-decoration: none;
    transform: translateY(-50%);
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: palette('scheme', 'black');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      transform: translate(-50%, -50%);
    }
    &.-back {
      left: 6px;
      &::before {
        content: '\f104';
      }
    }
    &.-edit {
      right: 12px;
      &::before {
        content: '\f013';
      }
    }

    @include mq() {
      &.-back {
        left: 24px;
      }
      &.-edit {
        right: 26px;
      }
    }
    @include mq(lg) {
      &:hover {
        opacity: .6;
      }
    }
  }
}

.p-creatorMypage-title {
  display: none;

  @include mq(xl) {
    display: block;
    margin-bottom: 70px;
    font-size: 22px;
  }

  &.-illust {
    margin-bottom: 80px;
    display: block;

    @include mq(md, max) {
      display: none;
    }
  }
}

.p-creatorMypage-headerNav {
  position: relative;
  margin: 16px 0 30px;
  padding: 0 17px;
  text-align: center;
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: palette('scheme', 'light-gray-primary');
  }

  @include mq() {
    margin: 16px 0 50px;
    padding: 0 35px;
  }
  @include mq(xl) {
    display: none;
  }

  &-inner {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }

  &-list {
    font-size: 0;
    white-space: nowrap;
    li {
      display: inline-block;
    }
  }

  &-anchor {
    position: relative;
    display: block;
    padding: 3px 10px 4px;
    font-size: 15px;
    text-decoration: none;
    &.-large {
      min-width: 170px;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
      visibility: hidden;
    }
    &.is-active::after {
      opacity: 1;
      visibility: visible;
    }

    @include mq() {
      padding: 5px 15px 6px;
    }
    @include mq(lg) {
      &:hover::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.p-creatorMypage-sideNav {

  @include mq(xl) {
    &-list {
      padding: 16px 0;
      background: #fff;
      border-radius: 5px;
      box-shadow: 0 6px 20px rgba(#000, .1);
    }
    &-anchor {
      position: relative;
      display: block;
      padding: 9px 46px 9px 20px;
      font-size: 15px;
      text-decoration: none;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 4px;
        height: 100%;
        background: palette('scheme', 'theme');
        opacity: 0;
      }
      &::after {
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 18px;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        transform: translateY(-50%);
      }
      &:hover::before,
      &.is-active::before {
        opacity: 1;
      }
    }
  }
}

.p-creatorMypage-sideBnr {
  a {
    display: block;
  }
}

// 12-1
.creatorMypage-index-avatar {

  @include mq() {
    position: relative;
    padding-left: 220px;
  }

  &-head {
    display: flex;

    @include mq() {
      display: block;
    }
  }

  &-image {
    width: 100px;
    img {
      border-radius: 50%;
    }

    @include mq() {
      position: absolute;
      top: 0;
      left: 0;
      width: 200px;
    }
  }

  &-row {
    flex: 1;
    padding: 5px 0 0 20px;

    @include mq() {
      padding: 20px 0 0 0;
    }
  }

  &-col01 {

    &.-flex {
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .button {
        margin-top: 10px;
      }
    }

    @include mq() {
      &.-flex {
        flex-direction: row;

        .button {
          margin-top: 0;
        }
      }
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;

    @include mq() {
      font-size: 22px;
    }
  }

  &-id {
    margin: 2px 0 0 3px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      font-size: 14px;
    }
  }

  &-link {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: -10px;
    li {
      margin: 10px 0 0 10px;
    }
    &-button {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      &.-pixiv,
      &.-syosetu {
        width: auto;
        height: 36px;
        padding: 0 6px;
        border-radius: 18px;
      }
      &.-pixiv {
        background: palette('scheme', 'pixiv');
      }
      &.-syosetu {
        background: palette('scheme', 'syosetu');
      }
    }

    @include mq(lg) {
      &-button.-pixiv:hover,
      &-button.-syosetu:hover {
        opacity: .6;
      }
    }
  }

  &-profile {
    margin-top: 30px;
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-request,
  &-requestStatus {
    padding: 3px 17px;
    color: palette('scheme', 'theme');
    font-size: 13px;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
        transform: translateY(-50%);
      }
    }
  }

  &-request {
    margin-top: 30px;
    background: palette('scheme', 'light-gray-primary');
    span {
      padding-left: 18px;
      &::before {
        content: '\f0f3';
      }
    }
    &-anchor {
      margin-left: 5px;
      color: palette('scheme', 'theme');
      font-weight: 700;
    }
  }

  &-requestStatus {
    margin: 30px -17px 0;
    background: palette('scheme', 'light-theme');
    span {
      padding-left: 24px;
      &::before {
        content: '\f500';
      }
    }

    @include mq() {
      margin: 30px 0 0;
    }
  }

  &-history {
    display: flex;
    margin-top: 35px;
    border: 1px solid palette('scheme', 'default');
    border-radius: 8px;
    &-label {
      width: 75px;
      padding: 10px;
      background: palette('scheme', 'default');
      border-radius: 7px 0 0 7px;
      color: #fff;
      font-size: 14px;
      &-inner {
        justify-content: center;
      }
    }
    &-text {
      flex: 1;
      padding: 15px;
      border-radius: 0 7px 7px 0;
      font-size: 12px;
    }
    &-label-inner,
    &-text-inner {
      display: flex;
      align-items: center;
      height: 100%;
      text-align: left;
    }

    @include mq() {
      &-label {
        width: 90px;
        font-size: 15px;
      }
      &-text {
        padding: 22px 25px;
        font-size: 14px;
      }
      &-text-inner {
        // 高さを3行で固定
        display: block;
        height: 3em * 1.618;
        overflow-y: auto;
      }
    }
  }

  &-meta {
    margin-top: 30px;
    &-item {
      display: flex;
      margin-top: 12px;
    }
    &-label {
      width: 110px;
      padding: 0 5px 0 0;
      font-size: 14px;
    }
    &-text {
      flex: 1;
      > ul {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        margin-top: -7px;
        li {
          margin: 7px 8px 0 0;
        }
      }
    }

    @include mq() {
      margin-top: 35px;
      &-item {
        margin-top: 12px;
      }
      &-label {
        width: 120px;
        padding: 1px 5px 0 0;
        font-size: 15px;
      }
    }
  }
}
.creatorMypage-index-works {
  &-head {
    margin-top: 30px;
    font-size: 17px;
    font-weight: 700;

    @include mq() {
      margin-top: 50px;
      font-size: 22px;
    }
  }

  &-list {
    margin: 8px -17px 0;

    @include mq() {
      margin: 8px 0 0;
    }
  }

  &-item {
    display: flex;
    padding: 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 30px 0;
    }
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }
    img {
      width: 100%;
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
    }
  }

  &-detail {
    flex: 1;
    margin-left: 15px;

    @include mq(xs) {
      display: flex;
      flex-direction: column;
      &-inner {
        padding-bottom: 20px;
      }
    }
    @include mq() {
      margin-left: 20px;
    }
  }

  &-label {
    margin-bottom: 10px;
    font-size: 0;
    li {
      display: inline-block;
      min-width: 60px;
      padding: 2px 5px;
      border: 1px solid palette('scheme', 'black');
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
      &:first-child {
        background: palette('scheme', 'black');
        color: #fff;
      }
    }

    @include mq() {
      margin-bottom: 15px;
      li {
        min-width: 72px;
        padding: 4px 5px;
        font-size: 13px;
      }
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 22px;
    }
  }

  &-status {
    margin-top: 10px;
    color: palette('scheme', 'theme');
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-creator {
    margin-top: 7px;
    li {
      position: relative;
      display: inline-block;
      margin-top: 3px;
      padding-left: 14px;
      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        color: palette('scheme', 'gray');
        font-family: 'Font Awesome 5 Free';
        font-size: 11px;
        font-weight: 900;
      }
      &:first-child {
        margin-right: 16px;
      }
      &.-block{
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &-type01::before {
      content: '\f304';
    }
    &-type02::before {
      content: '\f53f';
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 12px;
    }

    @include mq() {
      li {
        padding-left: 16px;
        &::before {
          top: 1px;
          font-size: 12px;
        }
      }
      &-anchor {
        font-size: 13px;
      }
    }
  }

  &-action {
    margin-top: 18px;
    li {
      margin-top: 8px;
    }

    @include mq(xs) {
      display: flex;
      justify-content: space-between;
      max-width: 370px;
      margin-top: auto;
      li {
        width: calc(50% - 4px);
        margin: 0;
      }
    }
  }

  &-empty {
    padding: 0 17px;
    margin: 20px 0;
    font-size: 16px;

    @include mq() {
      padding: 0px;
    }
  }
}

// 12-1-1
.creatorMypage-novel {
  &-add {
    margin: 0 auto 30px;

    @include mq() {
      margin: 0 auto 50px;
      &.-home {
        margin: 0 auto 20px;
      }
    }
  }

  &-list {
    margin: -17px -17px 0;

    @include mq() {
      margin: -30px 0 0;
    }
  }

  &-item {
    display: flex;
    padding: 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    &:last-child {
      border-bottom: none;
    }

    @include mq() {
      padding: 30px 0;
    }
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }
    img {
      width: 100%;
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
    }
  }

  &-detail {
    flex: 1;
    margin-left: 15px;

    @include mq(xs) {
      display: flex;
      flex-direction: column;
      &-inner {
        padding-bottom: 20px;
      }
    }
    @include mq() {
      margin-left: 20px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 14px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 22px;
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 7px 8px 0 0;
    }
  }

  &-creator {
    position: relative;
    margin-top: 15px;
    padding-left: 14px;
    &::before {
      content: '\f304';
      position: absolute;
      top: 2px;
      left: 0;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 11px;
      font-weight: 900;
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 12px;
    }

    @include mq() {
      margin-top: 17px;
      padding-left: 16px;
      &::before {
        top: 1px;
        font-size: 12px;
      }
      &-anchor {
        font-size: 13px;
      }
    }
  }

  &-action {
    margin-top: 18px;
    li {
      margin-top: 8px;
    }

    @include mq(xs) {
      display: flex;
      justify-content: space-between;
      max-width: 370px;
      margin-top: auto;
      li {
        width: calc(50% - 4px);
        margin: 0;
      }
    }
  }
}

// 12-1-2
.creatorMypage-novelManage-work {
  display: flex;

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-inner {
      position: relative;
      min-height: 100px;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
    }
  }

  &-upload {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 2;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 0;
    overflow: hidden;
    cursor: pointer;
    transform: translate(-50%, -50%);
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }

    @include mq(lg) {
      &:hover {
        background: rgba(#fff, .6);
      }
    }
  }

  &-detail {
    flex: 1;
    margin-left: 15px;

    @include mq() {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      &-inner {
        padding-bottom: 20px;
      }
    }
  }

  &-label {
    margin-bottom: 10px;
    font-size: 0;
    li {
      display: inline-block;
      min-width: 60px;
      padding: 2px 5px;
      border: 1px solid palette('scheme', 'black');
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
      &:first-child {
        background: palette('scheme', 'black');
        color: #fff;
      }
    }

    @include mq() {
      margin-bottom: 20px;
      li {
        min-width: 72px;
        padding: 4px 5px;
        font-size: 13px;
      }
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 14px;
    }
  }

  &-title {
    margin-top: 3px;
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 22px;
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 7px 8px 0 0;
    }
  }

  &-action {
    max-width: 225px;
    margin-top: 15px;

    @include mq() {
      margin-top: auto;
    }
  }
}
.creatorMypage-novelManage-section02 {
  position: relative;
  margin-top: 65px;
  &::before {
    content: '';
    position: absolute;
    top: -30px;
    left: -17px;
    width: calc(100% + 34px);
    height: 14px;
    background: palette('scheme', 'light-gray-primary');
  }

  @include mq() {
    margin-top: 80px;
    &::before {
      content: none;
    }
  }

  &-nav {
    position: relative;
    margin: 0 -17px;
    padding: 0 17px;
    font-size: 0;
    text-align: center;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: palette('scheme', 'light-gray-primary');
    }
    li {
      display: inline-block;
      width: 50%;
      max-width: 200px;
    }

    @include mq() {
      margin: 0;
      padding: 0;
    }
  }

  &-nav-anchor {
    position: relative;
    display: block;
    padding: 3px 10px 4px;
    font-size: 15px;
    text-align: center;
    text-decoration: none;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
    }
    &.is-active::after {
      opacity: 1;
    }

    @include mq(lg) {
      &:hover::after {
        opacity: 1;
      }
    }
  }
}
.creatorMypage-novelManage-toc {
  margin: 30px -17px 0;

  @include mq() {
    margin: 30px 0 0;
  }
}
.creatorMypage-novelManage-status {
  margin-top: 30px;

  @include mq() {
    margin-top: 60px;
  }

  &-label {
    margin: 0 -17px;
    padding: 3px 17px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 13px;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
      padding-left: 20px;
      &::before {
        content: '\f02d';
        position: absolute;
        top: 50%;
        left: 0;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 15px;
        font-weight: 900;
        transform: translateY(-50%);
      }
    }

    @include mq() {
      margin: 0;
    }
  }

  &-button-col {
    &:nth-child(1) {
      width: 108px;
    }
    &:nth-child(2) {
      flex: 1;
      width: auto;
      margin-left: 7px;
    }
  }
}
.creatorMypage-novelManage-workData {
  &-head {
    display: flex;
    margin-top: 30px;
  }

  &-title {
    width: 70%;
    padding-right: 20px;
    font-size: 17px;
    font-weight: 700;
  }

  &-edit {
    width: 30%;
    padding: 3px 0 0 20px;
    text-align: right;
  }

  &-body {
    margin-top: 5px;
    font-size: 14px;

    @include mq() {
      margin-top: 10px;
      font-size: 15px;
    }
  }

  &-link {
    margin: 30px -17px 0;

    @include mq() {
      margin: 30px 0 0;
    }
  }

  &-meta {
    display: flex;
    margin-top: 8px;

    @include mq() {
      margin: 10px 17px 0;
    }
  }

  &-status {
    width: 50%;
    padding-right: 20px;
    color: palette('scheme', 'theme');
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-update {
    width: 50%;
    padding: 2px 0 0 20px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    text-align: right;

    @include mq() {
      font-size: 13px;
    }
  }
}
.creatorMypage-novelManage-workNotice {
  &-head {
    position: relative;
    display: flex;
    margin-top: 50px;
    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      &::before {
        left: 0;
        width: 100%;
      }
    }
  }

  &-title {
    width: 70%;
    padding-right: 20px;
    font-size: 17px;
    font-weight: 700;
  }

  &-edit {
    width: 30%;
    padding: 3px 0 0 20px;
    text-align: right;
  }

  &-body {
    margin-top: 5px;
    font-size: 14px;
    li {
      position: relative;
      margin-top: 3px;
      padding-left: 1em;
      &::before {
        content: '\203b';
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    @include mq() {
      margin-top: 10px;
      font-size: 15px;
    }
  }
}

// 12-1-7, 12-6-6
.creatorMypage-memo-editList {
  margin: 0 -17px;

  @include mq() {
    margin: 0;
  }
}

// 12-1-7-2, 12-6-16
.creatorMypage-memoDetail {
  &-list {
    margin: 0 -17px -20px;

    @include mq() {
      margin: 0 0 -20px;
    }
  }

  &-item {
    display: flex;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    &:last-of-type {
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
  }

  &-label {
    width: 130px;
    padding: 20px 10px 20px 17px;

    @include mq() {
      width: 160px;
    }
  }

  &-text {
    flex: 1;
    padding: 18px 17px 18px 0;
    font-size: 15px;
  }
}

// 12-1-8, 12-6-7
.creatorMypage-character-linkList {
  margin: 0 -17px;

  @include mq() {
    margin: 0;
  }
}

// 12-1-8-1, 12-6-19
.creatorMypage-characterEdit {
  &-fieldset {
    border: 0;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }

  &-legend {
    font-size: 22px;
    font-weight: 700;
  }

  &-radio {
    display: inline-flex;
    flex-wrap: wrap;
    margin: -10px -30px 12px 0;
    li {
      margin: 10px 30px 0 0;
    }
  }

  &-select {
    width: 220px;
    margin-bottom: 12px;

    @include mq() {
      margin-bottom: 15px;
    }
  }
}

// 12-1-8-2, 12-6-17
.creatorMypage-characterDetail {
  &-list {
    margin: 0 -17px -20px;

    @include mq() {
      margin: 0 0 -20px;
    }
  }

  &-item {
    display: flex;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    &:last-of-type {
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
  }

  &-label {
    width: 130px;
    padding: 20px 10px 20px 17px;

    @include mq() {
      width: 160px;
    }
  }

  &-text {
    flex: 1;
    padding: 18px 17px 18px 0;
    font-size: 15px;

    .p-wordList-secondary {
      margin: -2px -6px 0 0;
      li {
        color: palette('scheme', 'default');
        font-size: 15px;
      }
    }

    @include mq() {
      .p-wordList-secondary {
        margin: -2px -8px 0 0;
      }
    }
  }
}

// 12-1-9
.creatorMypage-novelPublic {
  max-width: 600px;
  margin: 0 auto;

  &-header {
    display: flex;
    &-text {
      position: relative;
      width: 50%;
      padding-right: 20px;

      @include mq() {
        font-size: 15px;
      }
    }
    &-select {
      width: 50%;
      margin-left: 20px;
      text-align: right;
    }
  }

  &-list {
    margin: 15px -17px 0;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin: 20px 0 0;
    }
  }

  &-item {
    padding: 8px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    &.is-private {
      background: palette('scheme', 'custom-gray');
      .creatorMypage-novelPublic-item-toggle-button {
        background: #e0e0e9;
      }
    }

    @include mq() {
      padding: 10px 17px;
      font-size: 15px;
    }

    &-row01 {
      display: flex;
    }

    &-title {
      width: 40%;
      padding-right: 20px;
    }

    &-action {
      width: 60%;
      padding-left: 20px;
      text-align: right;
    }

    &-row02 {
      display: flex;
      margin-top: 8px;
    }

    &-date {
      width: 70%;
      padding: 0 20px 0 24px;
    }

    &-count {
      width: 30%;
      padding-left: 20px;
      text-align: right;
    }
  }

  &-item-toggle {
    display: inline-block;
    margin-right: 8px;
    cursor: pointer;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .creatorMypage-novelPublic-item-toggle-button {
        background: palette('scheme', 'check-green');
        border-color: palette('scheme', 'check-green');
        &::after {
          left: 10px;
          box-shadow: 0 2px 6px rgba(#000, .05);
        }
      }
    }
    &-label {
      margin-right: 5px;
    }
    &-button {
      position: relative;
      top: 1px;
      display: inline-block;
      width: 28px;
      height: 18px;
      background: #fff;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 16px;
      font-size: 0;
      vertical-align: top;
      transition: .25s ease;
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 16px;
        height: 16px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(#000, .1);
        transition: .25s ease;
      }
    }

    @include mq() {
      &-input:checked + .creatorMypage-novelPublic-item-toggle-button::after {
        left: 12px;
      }
      &-button {
        width: 32px;
        height: 20px;
        &::after {
          width: 18px;
          height: 18px;
        }
      }
    }
  }

  &-action {
    max-width: 480px;
    margin: 30px auto 50px;

    @include mq() {
      margin: 50px auto;
    }

    &-text {
      margin-bottom: 10px;

      @include mq() {
        margin-bottom: 15px;
        font-size: 15px;
      }
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &-col {
      width: calc(33.33334% - 4px);
    }
  }
}

// 12-2-3, 12-6-15
.creatorMypage-public {
  max-width: 600px;
  margin: 0 auto;

  &-row {
    display: flex;
    align-items: center;
    &-label {
      width: 50%;
      margin-right: auto;
      padding-right: 20px;
      font-size: 15px;
    }
    &-action {
      position: relative;
      flex: 1;
      text-align: right;
    }

    &.-illustDelete {
      position: relative;
      .creatorMypage-public-row-label {
        width: 100%;
        padding: 7px 0 0;
      }
      .creatorMypage-public-row-action {
        position: absolute;
        top: 0;
        right: 0;
      }

      @include mq() {
        .creatorMypage-public-row-label {
          width: 70%;
          padding: 0 20px 0 0;
        }
        .creatorMypage-public-row-action {
          position: static;
        }
      }
    }
  }

  &-alert {
    margin: 15px 0 0 1em;
    color: palette('scheme', 'theme');
    font-size: 15px;
    font-weight: 700;
    text-indent: -1em;

    @include mq() {
      margin: 0 0 0 1em;
    }
  }

  &-cautionBlock {
    margin-top: 20px;
  }

  &-caution {
    font-size: 12px;
    color: palette('scheme', 'theme');
  }

  &-button {
    padding: 7px 24px;
  }
}

// 12-2-1
.creatorMypage-illust {
  &-add {
    margin: 0 auto 30px;

    @include mq() {
      margin: 0 auto 50px;
    }
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    // margin: -25px -5px 34px;

    @include mq() {
      // margin: -50px -15px 0;
    }
  }

  &-item {
    width: calc(33.33334% - 10px);
    margin: 25px 5px 0;

    @include mq() {
      width: calc(33.33334% - 30px);
      margin: 50px 15px 0;
    }
  }

  &-image {
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      display: block;
    }
    img {
      width: 100%;
    }
  }

  &-title {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 12px;
      font-size: 17px;
    }
  }
}

// 12-2-2
.creatorMypage-illustEdit-image {
  position: relative;
  &-upload {
    position: absolute;
    top: -5px;
    right: 0;
  }
  &-preview {
    margin-top: 20px;
    text-align: center;
    img {
      border: 1px solid palette('scheme', 'medium-gray');
    }
  }
  &-none {
    padding: 20px 0 10px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    text-align: center;
  }

  @include mq() {
    &-none {
      padding: 20px 0 40px;
    }
  }
}

.creatorMypage-illustEdit-section {
  margin-top: 32px;
  padding-top: 32px;
  border-top: 1px solid #EBEBEF;

  &-title {
    padding-bottom: 12px;
    font-size: 18px;
    font-weight: bold;

    @include mq() {
      padding-bottom: 2px;
    }

    &.-genre {
      margin-bottom: 50px;

      @include mq(md, max) {
        margin-bottom: 35px;
      }
    }
  }

  &:last-of-type {
    padding-bottom: 32px;
    border-bottom: 1px solid #EBEBEF;
  }
}

// 12-3
.creatorMypage-revenue-all {
  &-list {
    width: 100%;
    th {
      width: 40%;
      padding: 10px;
      background: #333;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: 17px;
      text-align: center;
      vertical-align: middle;
      white-space: nowrap;
    }
    td {
      padding: 10px;
      background: palette('scheme', 'custom-gray');
      border-bottom: 1px solid #fff;
      font-size: 14px;
    }

    @include mq() {
      th {
        padding: 17px 10px;
      }
      td {
        padding: 17px;
      }
    }
  }

  &-labelNote {
    display: block;
    margin: 0 -5px;
    font-size: 11px;

    @include mq(xs) {
      font-size: 12px;
    }
  }

  &-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    &:first-child {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid palette('scheme', 'medium-gray');
    }

    @include mq() {
      padding: 0 10px;
      &:first-child {
        margin-bottom: 8px;
        padding-bottom: 8px;
      }
    }
  }

  &-detailAmount {
    font-size: 17px;
    font-family: $ff-en;
    font-weight: 700;
    line-height: 1;
    &.-noTax {
      font-size: 21px;
    }
  }
}
.creatorMypage-revenue-summary {
  margin-top: 30px;

  @include mq() {
    margin-top: 50px;
  }

  &-title {
    font-size: 17px;

    @include mq() {
      font-size: 22px;
    }
  }

  &-list {
    table-layout: fixed;
    width: 100%;
    margin-top: 20px;
    th {
      padding: 8px 5px;
      background: #333;
      border: 1px solid #fff;
      color: #fff;
      font-size: 14px;
      text-align: center;
      vertical-align: middle;
      &:nth-child(1) {
        width: 18%;
      }
    }
    td {
      padding: 10px 8px;
      background: palette('scheme', 'custom-gray');
      border: 1px solid #fff;
      font-size: 15px;
      font-family: $ff-en;
      font-weight: 700;
      line-height: 1;
      text-align: right;
      &:nth-child(1) {
        padding: 10px 5px;
        text-align: center;
      }
    }

    @include mq(xs) {
      td {
        padding: 10px 12px;
        font-size: 17px;
      }
    }
    @include mq() {
      margin-top: 30px;
      th {
        padding: 12px 5px;
        font-size: 17px;
      }
      td {
        padding: 12px 17px;
      }
    }
  }

  &-more {
    margin-top: 30px;
    text-align: center;
  }
}
.creatorMypage-revenue-nav {
  display: flex;
  max-width: 480px;
  margin: 30px auto;
  padding: 2px;
  background: palette('scheme', 'light-gray-primary');
  border-radius: 5px;
  box-shadow: 0 6px 20px rgba(#000, .1);
  li {
    width: 50%;
  }
  &-anchor {
    display: block;
    padding: 6px 5px;
    border-radius: 5px;
    text-decoration: none;
    text-align: center;
    &.is-active {
      background: #fff;
    }
  }

  @include mq() {
    margin: 80px auto 40px;
  }
  @include mq(lg) {
    &-anchor:not(.is-active):hover {
      background: rgba(#fff, .4);
    }
  }
}
.creatorMypage-revenue-salesHistory {
  &-filter {

    @include mq() {
      display: flex;
      max-width: 600px;
      font-size: 15px;
    }
  }

  &-filterLabel {
    margin-bottom: 7px;

    @include mq() {
      margin: 0 20px 0 0;
      padding-top: 5px;
    }
  }

  &-filterSelect {
    display: flex;
    &-select {
      width: calc(33.33334% - 24px);
      &-body {
        padding: 3px 10px;
      }
    }
    &-label {
      display: inline-block;
      width: 24px;
      padding-top: 10px;
      line-height: 1;
      text-align: center;
      vertical-align: top;
    }

    @include mq() {
      flex: 1;
    }
  }

  &-list {
    margin-top: 40px;
  }

  &-item {
    margin-top: 8px;
    padding: 15px 17px;
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 6px 20px rgba(#000, .1);

    @include mq() {
      margin-top: 20px;
      padding: 20px 35px;
      font-size: 15px;
    }
  }

  &-row {
    display: flex;
    &:not(:first-child) {
      margin-top: 10px;
    }
  }

  &-label {
    width: 80px;
    padding-right: 15px;
    font-weight: 700;

    @include mq() {
      width: 100px;
    }
  }

  &-text {
    flex: 1;
  }

  &-amount {
    display: flex;
    align-items: center;
    &:first-child {
      margin-bottom: 6px;
    }
  }

  &-amountLabel {
    margin-right: 10px;
    padding: 4px 5px;
    background: palette('scheme', 'custom-gray');
    font-size: 11px;
    line-height: 1;

    @include mq() {
      font-size: 13px;
    }
  }

  &-more {
    margin-top: 30px;
    text-align: center;

    @include mq() {
      margin-top: 50px;
    }
  }
}
.creatorMypage-revenue-unpaid {
  &-title {
    font-size: 17px;

    @include mq() {
      font-size: 22px;
    }
  }

  &-list {
    table-layout: fixed;
    width: 100%;
    margin-top: 20px;
    th,
    td {
      width: 50%;
      border: 1px solid #fff;
      text-align: center;
      vertical-align: middle;
    }
    th {
      padding: 8px 5px;
      background: #333;
      color: #fff;
      font-size: 14px;
    }
    td {
      padding: 10px 8px;
      background: palette('scheme', 'custom-gray');
      font-size: 17px;
      font-family: $ff-en;
      font-weight: 700;
      line-height: 1;
    }

    @include mq() {
      margin-top: 30px;
      th {
        padding: 12px 5px;
        font-size: 17px;
      }
      td {
        padding: 12px 17px;
        font-size: 21px;
      }
    }
  }
}
.creatorMypage-revenue-transferHistory {
  &-head {
    position: relative;
    margin-top: 30px;
    padding-top: 25px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin-top: 50px;
      padding-top: 40px;
    }
  }

  &-filter {

    @include mq() {
      display: flex;
      font-size: 15px;
    }
  }

  &-filterLabel {
    margin-bottom: 7px;

    @include mq() {
      margin: 0 20px 0 0;
      padding-top: 5px;
    }
  }

  &-filterSelect {
    &-select {
      width: 90px;
      &-body {
        padding: 3px 10px;
      }
    }
    &-label {
      display: inline-block;
      padding: 10px 0 0 3px;
      line-height: 1;
      text-align: center;
      vertical-align: top;
    }
  }

  &-button {
    position: absolute;
    bottom: -2px;
    right: 0;
    width: 165px;
  }

  &-list {
    margin-top: 30px;

    @include mq() {
      display: flex;
      flex-wrap: wrap;
      margin: 20px -10px 0;
    }
  }

  &-item {
    width: 100%;
    margin-top: 20px;
    th {
      width: 40%;
      padding: 8px 5px;
      background: #333;
      border-bottom: 1px solid #fff;
      color: #fff;
      font-size: 14px;
    }
    thead th {
      width: 100%;
    }
    td {
      width: 60%;
      padding: 10px;
      background: palette('scheme', 'custom-gray');
      border-bottom: 1px solid #fff;
      font-size: 14px;
    }
    tbody {
      tr:nth-child(2),
      tr:nth-child(3) {
        td {
          padding: 10px 15px 10px 10px;
          font-size: 17px;
          font-family: $ff-en;
          font-weight: 700;
          line-height: 1;
          text-align: right;
        }
      }
    }

    @include mq() {
      width: calc(50% - 20px);
      margin: 20px 10px 0;
      th {
        padding: 12px 5px;
        font-size: 15px;
      }
      thead th {
        padding: 8px 5px;
        font-size: 17px;
      }
      td {
        padding: 17px 10px;
      }
      tbody {
        tr:nth-child(2),
        tr:nth-child(3) {
          td {
            padding: 17px 20px 17px 10px;
          }
        }
      }
    }
  }

  &-detail {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5px;
    &:first-child {
      margin-bottom: 5px;
      padding-bottom: 5px;
      border-bottom: 1px solid palette('scheme', 'medium-gray');
    }

    @include mq() {
      padding: 0 10px;
      &:first-child {
        margin-bottom: 8px;
        padding-bottom: 8px;
      }
    }
  }

  &-detailAmount {
    font-size: 17px;
    font-family: $ff-en;
    font-weight: 700;
    line-height: 1;
    &.-noTax {
      font-size: 21px;
    }
  }
}

// 12-3-1
.creatorMypage-paymentRecord {
  &-title {
    margin: 13px -17px 30px;
    padding: 0 17px 13px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: .075em;

    @include mq() {
      margin: 80px 0 40px;
      padding: 0 0 24px;
      font-size: 22px;
    }
  }

  &-list {
    width: 100%;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 700;
    th {
      width: 40%;
      padding: 8px 5px;
      background: #333;
      border-bottom: 1px solid #fff;
      color: #fff;
    }
    thead th {
      width: 100%;
    }
    td {
      width: 60%;
      padding: 10px 17px;
      background: palette('scheme', 'custom-gray');
      border-bottom: 1px solid #fff;
    }

    @include mq() {
      margin-top: 40px;
      font-size: 15px;
      th {
        padding: 12px 5px;
      }
      thead th {
        padding: 8px 5px;
        font-size: 17px;
      }
      td {
        padding: 17px 20px;
      }
    }
  }

  &-list02 {
    tbody {
      tr:nth-child(3),
      tr:nth-child(4) {
        td {
          font-size: 17px;
          font-family: $ff-en;
          line-height: 1;
          text-align: right;
        }
      }
    }
  }

  &-list03 {
    margin-top: 40px;

    @include mq() {
      margin-top: 80px;
    }
  }

  &-note {
    margin-top: 20px;
    padding: 15px;
    background: palette('scheme', 'custom-gray');
    font-size: 14px;
    &-head {
      margin: 0 0 5px -.5em;
    }
    &-body p:not(:first-child) {
      margin-top: .25em;
    }

    @include mq() {
      margin-top: 40px;
      padding: 20px;
      font-size: 15px;
    }
  }
}

.creatorMypage-tieupManage-work {
  display: flex;

  &-image {
    width: 38.75%;
    min-width: 100px;
    max-width: 160px;
    &-inner {
      position: relative;
      min-height: 100px;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      width: 40%;
      max-width: 200px;
      flex: 1 0 auto;
    }
  }

  &-upload {
    display: block;
    z-index: 2;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    font-size: 0;
    cursor: pointer;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
    }

    @include mq(lg) {
      &:hover {
        background: rgba(#fff, .6);
      }
    }
  }

  &-detail {
    flex: 1;
    margin-left: 20px;

    @include mq() {
      display: flex;
      flex-direction: column;
      &-inner {
        padding-bottom: 30px;
      }
    }
  }

  &-label {
    margin-bottom: 10px;
    font-size: 0;
    li {
      display: inline-block;
      min-width: 60px;
      padding: 2px 5px;
      border: 1px solid palette('scheme', 'black');
      font-size: 12px;
      line-height: 1.2;
      text-align: center;
      &:first-child {
        background: palette('scheme', 'black');
        color: #fff;
      }
    }

    @include mq() {
      margin-bottom: 20px;
      li {
        min-width: 72px;
        padding: 4px 5px;
        font-size: 13px;
      }
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 14px;
    }
  }

  &-title {
    margin-top: 3px;
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 22px;
    }
  }

  &-genre {
    margin-top: 6px;
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 7px 8px 0 0;
    }
  }

  &-tag.p-wordList-secondary {
    margin-top: 6px;
  }

  &-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }

  &-action01 {
    display: none;
    @include mq() {
      display: grid;
      grid-gap: 11px;
      grid-template-columns: repeat(auto-fill,156px);
      li {
        width: 156px;
        &.-disabled {
          pointer-events: none;
          > a {
            cursor: not-allowed;
            background-color: #e5e5ea;
            border: none;
          }
        }
      }
    }
    @include mq(lg) {
      grid-template-columns: 1fr 1fr 1fr 1fr;
    }
  }

  &-action02 {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 20px;
    padding-bottom: 30px;

    li {
      width: calc(50% - 4px);
      &:nth-child(n+3) {
        margin-top: 15px;
      }
      .creatorMypage-tieupManage-work-button {
        padding: 7px;
      }
      &.-disabled {
        pointer-events: none;
        > a {
          cursor: not-allowed;
          background-color: #e5e5ea;
          border: none;
        }
      }
    }

    @include mq() {
      display: none;
    }
  }

  &-action03 {
    margin: 20px -17px 0;

    @include mq() {
      display: none;
    }
  }
}
.creatorMypage-tieupManage-frontispiece {
  &-head {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
  }

  &-title {
    width: 50%;
    margin-right: auto;
    padding: 10px 0;
    font-size: 13px;
    font-weight: 400;

    @include mq() {
      margin: 0 auto 0 20px;
      font-size: 15px;
    }
  }

  &-body {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    margin: 5px -10px 0;

    @include mq() {
      justify-content: flex-start;
    }
  }

  &-image {
    position: relative;
    width: calc(20% - 20px);
    min-width: 80px;
    max-width: 160px;
    margin: 20px 10px 0;
    border: 1px solid palette('scheme', 'light-gray-primary');
  }

  &-remove {
    display: inline-block;
    position: absolute;
    top: -8px;
    right: -8px;
    z-index: 2;
    width: 16px;
    height: 16px;
    background: palette('scheme', 'theme');
    border-radius: 50%;
    font-size: 0;
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 10px;
      height: 2px;
      background: #fff;
    }
    &::before {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    &::after {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    @include mq(lg) {
      &:hover {
        background: palette('scheme', 'theme-hover');
      }
    }
  }
}
.creatorMypage-tieupManage-toc {
  margin: 20px -17px 0;
  border-top: 1px solid palette('scheme', 'light-gray-primary');

  @include mq() {
    margin: 40px 0 0;
  }

  &-item {
    padding: 10px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
  }

  &-header {
    display: flex;

    @include mq() {
      font-size: 15px;
    }
  }

  &-label {
    width: 50%;

    @include mq() {
      width: 60%;
    }
  }

  &-anchor {
    display: block;
    margin: -10px 0 -10px -17px;
    padding: 10px 0 10px 17px;
    text-decoration: none;

    @include mq(lg) {
      &:hover {
        opacity: .6;
      }
    }
  }

  &-action {
    width: 50%;
    padding-left: 20px;
    text-align: right;

    @include mq() {
      width: 40%;
    }
  }

  &-body {
    display: flex;
    margin: 15px -4px;

    @include mq() {
      margin: 15px -7px;
    }
  }

  &-image {
    width: calc(16.66667% - 8px);
    margin: 0 4px;
    border: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      width: calc(16.66667% - 14px);
      margin: 0 7px;
    }
  }

  &-imageMore {
    display: flex;
    align-items: flex-end;
    padding-left: 5px;
    font-size: 20px;
    line-height: 1;
  }

  &-footer {
    text-align: right;
    p {
      display: inline-block;
    }

    @include mq() {
      font-size: 15px;
    }
  }
}
.creatorMypage-tieupManage-status {
  max-width: 530px;
  margin: 30px auto 0;

  @include mq() {
    margin-top: 64px;
    margin-bottom: -26px;
  }

  &-note {
    text-align: center;
    font-size: 13px;
    line-height: calc(22/13);
    color: #2B2B2B;
  }

  &-list {
    padding: 14px 24px;
    margin: 16px 0;
    justify-content: center;
    background-color: #F5F5F5;

    @include mq() {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 40px;
    }
  }

  &-item {
    display: flex;
    padding: 5px 0;
    font-size: 14px;
    color: #2B2B2B;

    @include mq() {
      width: calc(50% - 20px);
      padding: 6px 0;
      font-size: 13px;
    }
  }

  &-label {
    width: 100px;
    padding-right: 15px;
  }

  &-text {
    position: relative;
    flex: 1;
    padding-left: 20px;
    color: palette('scheme', 'theme');
    &::before {
      content: '\f06a';
      position: absolute;
      top: 0;
      left: 0;
      color: palette('scheme', 'theme');
      font-family: 'Font Awesome 5 Free';
      font-size: 15px;
      font-weight: 900;
    }
    &.is-valid {
      color: palette('scheme', 'default');
      &::before {
        content: '\f00c';
        top: -1px;
        color: palette('scheme', 'check-green');
      }
    }

    @include mq() {
      &.is-valid::before {
        top: 0;
      }
    }
  }

  &-title {
    margin: 0;
    padding: 4px 17px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 13px;
    font-weight: 400;
    text-align: center;
    span {
      position: relative;
      display: inline-block;
    }

    &.is-disabled {
      background: #919191;
      span {
        color: #FFFFFF;
      }
    }

    @include mq() {
      margin: 0;
    }
  }

  &-button-col {
    &:nth-child(1) {
      width: 108px;
    }
    &:nth-child(2) {
      flex: 1;
      width: auto;
      margin-left: 7px;
    }
  }
}
.creatorMypage-tieupManage-workData {
  &-head {
    display: flex;
    margin-top: 30px;
  }

  &-title {
    width: 70%;
    padding-right: 20px;
    font-size: 17px;
    font-weight: 700;
  }

  &-edit {
    width: 30%;
    padding: 3px 0 0 20px;
    text-align: right;
  }

  &-body {
    margin-top: 5px;
    font-size: 14px;

    @include mq() {
      margin-top: 10px;
      font-size: 15px;
    }
  }

  &-link {
    margin: 30px -17px 0;

    @include mq() {
      margin: 30px 0 0;
    }
  }

  &-meta {
    display: flex;
    margin: 8px 0 -20px;

    @include mq() {
      margin: 10px 17px -50px;
    }
  }

  &-status {
    width: 50%;
    padding-right: 20px;
    color: palette('scheme', 'theme');
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-update {
    width: 50%;
    padding: 2px 0 0 20px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    text-align: right;

    @include mq() {
      font-size: 13px;
    }
  }
}
.creatorMypage-tieupManage-share,
.creatorMypage-tieupManage-bonus {
  position: relative;
  margin-top: 64px;
  &::before {
    content: '';
    position: absolute;
    top: -34px;
    left: -17px;
    width: calc(100% + 34px);
    height: 14px;
    background: palette('scheme', 'light-gray-primary');
  }

  @include mq() {
    margin-top: 60px;
    padding-top: 60px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    &::before {
      content: none;
    }
  }

  &-title {
    font-size: 17px;
    text-align: center;
    margin-bottom: 25px;

    @include mq() {
      text-align: left;
    }
  }

  &-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 25px;

    @include mq() {
      justify-content: flex-start;
    }
  }

  &-link-input {
    max-width: auto;
    margin-bottom: 20px;

    @include mq() {
      margin-right: 20px;
      margin-bottom: 0;
    }
  }
}

.creatorMypage-tieupManage-bonus-summary {
  font-size: 15px;
  margin-bottom: 20px;
}

.creatorMypage-tieupManage-bonusPreview {
  display: flex;
  justify-content: flex-start;
  @include mq(md, max) {
    justify-content: center;
  }

  & img {
    max-height: 400px;
    @include mq(md, max) {
      max-height: 368px;
    }
  }
}

.creatorMypage-tieupManage-shareImage,
.creatorMypage-tieupManage-bonusImage {
  position: relative;
  margin: 25px 0 -5px;

  &-buttonWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 19px;

    @include mq(md, max) {
      justify-content: center;
    }

    @include mq(xs, max) {
      justify-content: space-between;
    }
  }

  &-previewWrapper {
    position: relative;
  }

  &-previewImage {
    max-height: 400px;
    border: 1px solid palette('scheme', 'light-gray-primary');

    @include mq(md, max) {
      max-height: 368px;
    }
  }

  &-cancelButton {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #030303;
    z-index: 100;
    &:hover {
      opacity: 0.6;
    }

    @include mq(md, max) {
      top: 5px;
      right: 5px;
    }

    > i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      font-size: 24px;
    }
  }
}

.creatorMypage-tieupManage-shareImage-text {
  margin: 0 150px 30px 0;

  @include mq() {
    margin: 0 150px 30px 0;
    font-size: 15px;
  }
}

.creatorMypage-tieupManage-bonusImage-text {
  margin: 0 20px 0 0;

  @include mq(md, max) {
    display: none;
  }
}

.creatorMypage-tieupManage-shareImage-upload {
  position: absolute;
  top: -5px;
  right: 0;
}

.creatorMypage-tieupManage-bonusImage-upload {
  display: flex;
  margin-right: 16px;
}

.creatorMypage-tieupManage-bonusImage-clear {
  display: flex;
}

// 12-6-3
.creatorMypage-tieupData {
  margin-top: 30px;

  @include mq() {
    margin-top: 50px;
  }
  @include mq(xl) {
    margin-top: 80px;
  }

  &-list {
    margin: 60px -17px 0;

    @include mq() {
      margin: 60px 0 0;
    }
  }

  &-switch {
    padding: 14px 17px;
    align-items: center;
    &-label {
      width: 30%;
    }
    &-text {
      width: 70%;
    }
    .c-select-primary {
      max-width: 300px;
      margin-left: auto;
    }
  }

  &-star {
    position: relative;
    top: -1px;
    display: inline-block;
    font-size: 0;
    .c-star {
      margin-right: 2px;
      font-size: 12px;
    }

    @include mq() {
      .c-star {
        font-size: 14px;
      }
    }
  }
}

// 12-6-9
.creatorMypage-workRequest {
  margin: 50px -17px 0;

  @include mq() {
    width: 600px;
    margin: 50px auto 0;
  }
  @include mq(xl) {
    margin: 80px auto 0;
  }

  &-status {
    margin-bottom: 20px;
    padding: 4px 17px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    line-height: 1.4;
    text-align: center;

    @include mq() {
      margin-bottom: 30px;
    }
  }

  &-note {
    padding: 0 16px;
    margin-top: 48px;
    margin-bottom: 10px;
    color: #2B2B2B;
    line-height: calc(22 / 13);
    @include mq() {
      padding: 0;
    }
  }

  &-row {
    display: flex;
    align-items: center;
    padding: 10px 17px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    &.is-disabled {
      position: relative;
      background: palette('scheme', 'custom-gray');
      pointer-events: none;
      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
      }
      .creatorMypage-workRequest-row-label,
      .creatorMypage-workRequest-row-action {
        opacity: .5;
      }
    }
    &-label {
      display: flex;
      align-items: center;
      width: 50%;
      margin-right: auto;
      padding-right: 20px;
      font-size: 15px;
      &-innerLabel {
        margin-left: 16px;
      }
      &.-publicApplication {
        width: auto;
      }
    }
    &-action {
      position: relative;
      flex: 1;
      text-align: right;
    }

    @include mq() {
      padding: 15px 17px;
    }
  }
}

// 12-6-10
.creatorMypage-series {
  .l-single {
    padding: 0;
    @include mq() {
      max-width: 970px;
      padding: 0 35px 80px;
    }
  }
  &-header {
    background: #ededed;
  }
  &-header-inner {
    position: relative;
    padding: 11px 20px;
    max-width: 970px;
    margin: 0 auto;
    @include mq() {
      padding: 11px 35px;
    }
  }
  &-header-link {
    font-size: 16px;
    text-align: left;
    text-decoration: none;
    font-weight: bold;
    position: absolute;
    top: 10px;
    @include mq() {
      position: relative;
      top: auto;
    }
    &::before {
      color: palette('scheme', 'black');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      content: '\f104';
    }
  }
  &-header-link-text {
    display: inline-block;
    padding-left: 6px;
  }

  &-heading-title {
    font-size: 16px;
    text-align: center;
    @include mq() {
      font-size: 23px;
      font-weight: bold;
      text-align: left;
      margin-top: 28px;
      margin-left: 0;
    }
  }

  &-list {
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-auto-rows: 1fr;
    grid-gap: 48px 20px;
    background: #e5e5ea 0% 0% no-repeat padding-box;
    padding: 48px 20px;
    @include mq() {
      grid-template-columns: repeat(3, 1fr);
      margin-top: 80px;
      grid-gap: 84px 32px;
      padding: 48px 50px;
    }
    @include mq(single) {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  &-image {
    width: 100%;
    height: auto;
    box-shadow: 0px 3px 8px #00000066;
    border-radius: 4px;
    &-anchor {
      display: block;
      position: relative;
    }
    img {
      width: 100%;
      border-radius: 4px;
    }
  }

  &-number {
    z-index: 3;
    width: 40px;
    height: 27px;
    background: #000 0% 0% no-repeat padding-box;
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -8px;
    left: -8px;
  }

  &-title {
    margin-top: 8px;
    font-size: 16px;
    font-weight: 700;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-line-clamp: 2;
    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &-button-container {
    position: relative;
    display: block;
    &:before {
      content: "";
      display: block;
      padding-top: 144%;
      @include mq() {
        padding-top: 142%;
      }
    }
  }

  &-add-button {
    background: #c5c5c5 0% 0% no-repeat padding-box;
    width: 100%;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    left: 0;
    > i {
      font-size: 32px;
      font-weight: 900;
      line-height: 1;
      color: #fff;
      border: 4px solid #fff;
      border-radius: 50%;
      padding: 17px 18px;
    }
    > span {
      color: #fff;
      font-weight: bold;
      font-size: 14px;
      margin-top: 6px;
    }

    &[disabled] {
      pointer-events: none;
      opacity: .3;
    }
    &:hover {
      opacity: .6;
    }
  }
}


.creatorMypage-series {
  &-button {
    &::before {
      content: '\f067';
      position: absolute;
      top: 50%;
      left: 50%;
      color: #fff;
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      transform: translate(-50%, -50%);
    }
  }
}

// 12-6-11
.creatorMypage-tieupParticipants {
  margin-top: 30px;

  @include mq() {
    margin-top: 50px;
  }
  @include mq(xl) {
    margin-top: 80px;
  }

  &-title {
    display: block;
    font-size: 17px;
    margin-bottom: 11px;
    text-align: center;

    @include mq() {
      margin-bottom: 31px;
      font-size: 22px;
      text-align: left;
    }
    @include mq(xl) {
      margin-bottom: 51px;
    }
  }

  &-list {
    margin: 0 -17px;

    @include mq() {
      margin: 0;
    }
  }

  &-item {
    display: flex;
    padding: 19px 17px 20px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 19px 17px 24px;
    }
  }

  &-image {
    width: 60px;
    padding-top: 5px;
    img {
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 100px;
    }
  }

  &-detail {
    position: relative;
    flex: 1;
    min-width: 0;
    padding-left: 20px;

    @include mq() {
      padding: 10px 0 0 20px;
    }
  }

  &-name {
    margin-right: 85px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-meta {
    margin-top: 4px;
    color: palette('scheme', 'gray');
  }

  &-status {
    display: inline-block;
    position: absolute;
    top: 2px;
    right: 0;
    padding: 2px 6px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 12px;
    line-height: 1.4;

    @include mq() {
      top: 12px;
    }
  }
}

// 12-7
.creatorMypage-profile {
  margin-top: 30px;

  @include mq() {
    margin-top: 50px;
  }
  @include mq(xl) {
    margin-top: 80px;
  }

  &-section01-title {
    margin-bottom: 30px;
    font-size: 17px;
    font-weight: 700;
    text-align: center;

    @include mq() {
      font-size: 22px;
    }
  }

  &-avatar {
    position: relative;
    width: 200px;
    margin: 0 auto;
    img {
      border-radius: 50%;
    }
    &-upload {
      position: absolute;
      bottom: 0;
      right: 0;
      z-index: 2;
      width: 60px;
      height: 60px;
      border-radius: 50%;
      font-size: 0;
      overflow: hidden;
      cursor: pointer;
      &-input {
        position: absolute;
        z-index: -1;
        opacity: 0;
        visibility: hidden;
      }
    }

    @include mq(lg) {
      &-upload:hover {
        background: rgba(#fff, .6);
      }
    }
  }

  &-list {
    margin: 25px -17px 0;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    &.-favGenres {
      border: none;
      padding: 0 17px;

      @include mq() {
        padding: 0;
      }
    }

    @include mq() {
      margin: 40px 0 0;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    padding: 8px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 12px 17px;
    }
  }

  &-label {
    width: 130px;
    padding: 2px 10px 0 0;

    @include mq() {
      width: 180px;
      font-size: 15px;
    }
  }

  &-text {
    flex: 1;
    input[type="text"] {
      width: 100%;
    }
    input[type="url"] {
      width: 100%;
    }
  }

  &-detail {
    margin-top: 15px;

    @include mq() {
      margin: 30px 17px 0;

      &.-mypage {
        margin:  45px 0;
      }
    }
  }

  &-section02 {
    position: relative;
    margin-top: 64px;
    &::before {
      content: '';
      position: absolute;
      top: -34px;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }

    @include mq() {
      margin-top: 60px;
      padding-top: 60px;
      border-top: 1px solid palette('scheme', 'light-gray-primary');
      &::before {
        content: none;
      }
    }

    &-title {
      margin-bottom: 20px;
      font-size: 17px;
      font-weight: 700;
      text-align: center;

      @include mq() {
        font-size: 22px;
      }
    }
  }

  &-request-block {
    padding-top: 10px;
  }

  &-request {
    display: flex;
    max-width: 480px;
    margin-top: 15px;
    padding: 2px;
    background: palette('scheme', 'light-gray-primary');
    border-radius: 5px;
    box-shadow: 0 6px 20px rgba(#000, .1);

    &-item {
      position: relative;
      display: block;
      width: 33.33334%;
      height: 100%;
      cursor: pointer;
    }

    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .creatorMypage-profile-request-text {
        background: #fff;
      }
    }

    &-text {
      position: relative;
      z-index: 2;
      display: block;
      padding: 6px 5px;
      border-radius: 5px;
      text-align: center;
    }

    &-item:nth-child(2) {
      z-index: 1;
      .creatorMypage-profile-request-text {
        &::before,
        &::after {
          content: '';
          position: absolute;
          top: 3px;
          width: 1px;
          height: calc(100% - 6px);
          background: palette('scheme', 'light-gray-secondary');
        }
        &::before {
          left: -1px;
        }
        &::after {
          right: -1px;
        }
      }
      .creatorMypage-profile-request-input:checked + .creatorMypage-profile-request-text {
        &::before,
        &::after {
          content: none;
        }
      }
    }

    @include mq(lg) {
      &-item:hover .creatorMypage-profile-request-input:not(:checked) + .creatorMypage-profile-request-text {
        background: rgba(#fff, .4);
      }
    }
  }

  &-favorite li:not(:first-child) {
    margin-top: 15px;
  }

  &-textarea01 {
    height: 180px;
    min-height: 180px;

    @include mq() {
      height: 280px;
      min-height: 280px;
    }
  }

  &-textarea02 {
    height: 90px;
    min-height: 90px;
  }

  &-caution {
    margin-top: 30px;
    color: #8e8e93;
    text-align: center;
  }
}

// 12-8-2
.creatorMypage-tieupRequest {
  &-nav {
    display: flex;
    max-width: 480px;
    margin: 0 auto 40px;
    padding: 2px;
    background: palette('scheme', 'light-gray-primary');
    border-radius: 5px;
    box-shadow: 0 6px 20px rgba(#000, .1);
    li {
      width: 50%;
    }
    &-anchor {
      display: block;
      padding: 6px 5px;
      border-radius: 5px;
      text-decoration: none;
      text-align: center;
      &.is-active {
        background: #fff;
      }
    }

    @include mq(lg) {
      &-anchor:not(.is-active):hover {
        background: rgba(#fff, .4);
      }
    }
  }

  &-list {
    margin: -24px -17px 0;

    @include mq() {
      margin: 0;
    }
  }

  &-item {
    padding: 19px 17px 24px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 19px 17px 24px;
    }
  }

  &-profile {
    display: flex;
  }

  &-image {
    width: 60px;
    padding-top: 5px;
    &-anchor {
      display: inline-block;
    }
    img {
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 50%;
    }

    @include mq() {
      width: 100px;
    }
  }

  &-detail {
    position: relative;
    flex: 1;
    min-width: 0;
    padding-left: 20px;
  }

  &-date {
    color: palette('scheme', 'gray');
    font-family: $ff-en;
  }

  &-status-name {
    display: flex;
    align-items: flex-start;
    flex-direction: row-reverse;
  }

  &-status {
    position: relative;
    top: 1px;
    margin-left: 15px;
    padding: 4px 5px;
    background: palette('scheme', 'light-theme');
    color: palette('scheme', 'theme');
    font-size: 12px;
    line-height: 1;
  }

  &-name {
    flex: 1;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-work {
    margin-top: 3px;
    overflow: hidden;
    &-label {
      float: left;
      color: palette('scheme', 'gray');
    }
    &-title {
      color: palette('scheme', 'theme');
      overflow: hidden;
    }
  }

  &-action01 {
    display: none;

    @include mq() {
      display: flex;
      margin: 15px 0 0;
    }
  }

  &-action02 {
    margin-top: 15px;

    @include mq() {
      display: none;
    }
  }
}

// 12-8-3
.creatorMypage-tieupRequestDetail {

  @include mq(xl) {
    margin-top: 100px;
  }

  &-head {
    display: flex;

    @include mq() {
      align-items: center;
    }
  }

  &-image {
    width: 100px;
    img {
      border-radius: 50%;
    }

    @include mq() {
      width: 200px;
    }
  }

  &-detail {
    flex: 1;
    padding: 5px 0 0 20px;

    @include mq() {
      padding: 0 0 0 20px;
    }
  }

  &-detail-row {


    @include mq() {
      display: flex;
    }
  }

  &-detail-col01 {

    @include mq() {
      flex: 1;
      margin-right: 20px;
    }
  }

  &-detail-col02 {

    @include mq() {
      text-align: right;
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;

    @include mq() {
      font-size: 22px;
    }
  }

  &-id {
    margin: 2px 0 0 3px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      font-size: 14px;
    }
  }

  &-link {
    display: inline-flex;
    flex-wrap: wrap;
    margin-left: -10px;
    li {
      margin: 10px 0 0 10px;
    }
    &-button {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      &.-pixiv {
        background: palette('scheme', 'pixiv');
      }
      &.-syosetu {
        width: auto;
        height: 36px;
        padding: 0 6px;
        background: palette('scheme', 'syosetu');
        border-radius: 18px;
      }
    }

    @include mq() {
      margin: -10px 0 0 -10px
    }
    @include mq(lg) {
      &-button.-pixiv:hover,
      &-button.-syosetu:hover {
        opacity: .6;
      }
    }
  }

  &-more {
    max-width: 225px;
    margin-top: 20px;
  }

  &-chat {
    margin: 30px -17px 0;
    border-top: 14px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      margin: 50px 0 0;
      border-top-width: 1px;
    }
  }
}
.creatorMypage-tieupRequestDetail-work {
  margin: 30px -17px 0;
  padding: 10px 17px;
  border-top: 1px solid palette('scheme', 'light-gray-primary');
  border-bottom: 1px solid palette('scheme', 'light-gray-primary');

  @include mq() {
    margin: 40px 0 0;
  }

  &-title {
    font-size: 14px;

    @include mq() {
      font-size: 17px;
    }
  }

  &-date {
    color: palette('scheme', 'gray');
    font-size: 12px;

    @include mq() {
      font-size: 14px;
    }
  }

  &-time {
    font-family: $ff-en;
  }
}

.creatorMypage-tieupRequestDetail-illustration {
  margin: 40px 0 0;
  padding: 10px 17px;
  border-top: 1px solid #e5e5ea;
  border-bottom: 1px solid #e5e5ea;

  @include mq(md, max) {
    margin: 30px -17px 0;
  }

  &-title {
    font-size: 17px;

    @include mq(md, max) {
      font-size: 14px;
    }
  }

  &-illustTitle {
    margin-top: 5px;
    font-size: 16px;
  }

  &-wrapper {
    margin-top: 12px;
    width: 180px;

    @include mq(md, max) {
      width: 140px;
    }
  }
}

.creatorMypage-tieupRequestDetail-message {
  margin-top: 30px;

  @include mq() {
    margin: 40px 17px 0;
  }

  &-title {
    font-size: 17px;

    @include mq() {
      font-size: 22px;
    }
  }

  &-contents {
    margin-top: 5px;
    font-size: 14px;
    line-height: 1.8;

    @include mq() {
      margin-top: 10px;
      font-size: 16px;
    }
  }

  &-note {
    margin-top: 30px;
    color: palette('scheme', 'theme');
  }
}

// 12-8-4
.creatorMypage-tieupRequestAgree {

  @include mq(xl) {
    margin-top: 100px;
  }

  &-image {
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    margin: 0 auto;
    img {
      width: 100px;
      border-radius: 50%;
    }

    @include mq() {
      max-width: 370px;
      img {
        width: 150px;
      }
    }
  }

  &-title {
    margin-top: 30px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;

    @include mq() {
      margin-top: 60px;
      font-size: 22px;
      br {
        display: none;
      }
    }
  }

  &-text {
    margin-top: 20px;
    font-size: 14px;
    line-height: 1.8;
    text-align: center;

    @include mq() {
      font-size: 16px;
    }
  }
}

.creatorMypage-tieupRequestDetail-bnr {
  margin: 40px auto 0;
  max-width: 600px;

  a {
    display: block;
  }

  img {
    &:last-child {
      display: none;
    }
  }

  @include mq() {
    margin-top: 65px;
    max-width: 728px;

    img {
      &:first-child {
        display: none;
      }
      &:last-child {
        display: inline;
      }
    }
  }
}

// 12-9
.creatorMypage-notice {
  max-width: 600px;
  margin: 30px auto 0;

  @include mq() {
    margin: 50px auto 0;
  }
  @include mq(xl) {
    margin: 80px auto 0;
  }

  &-inner {
    margin: 0 -17px;

    @include mq() {
      margin: 0;
    }
  }

  &-block {
    margin-top: 30px;

    @include mq() {
      margin-top: 50px;
    }
  }

  &-title {
    padding: 0 17px 20px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 17px;
    text-align: center;

    @include mq() {
      padding: 0 17px 30px;
      font-size: 22px;
    }
  }

  &-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 15px;
  }
}

.p-creatorMypage-bnr {
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;

  a {
    display: block;
  }

  @include mq(xl) {
    display: none;
  }
}
