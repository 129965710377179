.error {
  margin-top: 30px;
  text-align: center;

  @include mq() {
    margin-top: 60px;
  }

  &-title {
    color: palette('scheme', 'theme');
    font-family: $ff-en;
    font-size: 20px;
    font-weight: 400;
    line-height: 1;
    letter-spacing: .175em;
    span {
      display: block;
      margin-bottom: 8px;
      font-size: 34px;
      font-weight: 700;
      letter-spacing: .175em;
    }

    @include mq() {
      font-size: 26px;
      span {
        font-size: 46px;
      }
    }
  }

  &-subtitle {
    margin-top: 30px;
    font-size: 17px;
    font-weight: 700;

    @include mq() {
      margin-top: 40px;
      font-size: 22px;
    }
  }

  &-text {
    display: inline-block;
    margin-top: 10px;
    font-size: 14px;
    text-align: left;

    @include mq() {
      margin-top: 15px;
      font-size: 16px;
    }
  }

  &-link {
    margin-top: 30px;

    @include mq() {
      margin-top: 60px;
    }
  }
}