body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  overflow-x: hidden;
  background: #fff;
  color: palette('scheme', 'default');
  font-family: $ff-ja;
  font-size: 13px;
  line-height: 1.618;
  font-weight: 400;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

_:-ms-fullscreen, :root body {
  font-family: Meiryo, sans-serif;
}
h1, h2, h3, h4, h5, h6 {
  font-weight: 700;
  line-height: 1.4;
}
img {
  max-width: 100%;
  height: auto;
  backface-visibility: hidden;
}
a {
  color: palette('scheme', 'default');
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
  img {
    pointer-events: none;
  }

  @include mq(lg) {
    &:hover img {
      opacity: .6;
    }
  }
}
::selection {
  background-color: palette('scheme', 'theme');
  color: #fff;
  text-shadow: none;
}
::-webkit-input-placeholder {
  color: palette('scheme', 'medium-gray');
  font-size: 13px;

  @include mq() {
    font-size: 16px;
  }
}
::-moz-placeholder {
  color: palette('scheme', 'medium-gray');
  font-size: 13px;

  @include mq() {
    font-size: 16px;
  }
}
:-ms-input-placeholder {
  color: palette('scheme', 'medium-gray');
  font-size: 13px;

  @include mq() {
    font-size: 16px;
  }
}
