.global-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 201;
  width: 81%;
  height: 100%;
  max-width: 304px;
  padding-top: 64px;
  background: #fff;
  box-shadow: 0 6px 20px rgba(#000, .1);
  transition: transform .3s ease;
  transform: translateX(-120%);
  &.is-show {
    transform: translateX(0);
  }

  @include mq() {
    display: none;
  }

  &-inner {
    height: 100%;
    padding: 0 17px 37px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  &-close {
    position: absolute;
    top: 13px;
    right: 9px;
  }

  &-message {
    padding: 30px 0 15px;
    &-text {
      font-weight: 700;
      text-align: center;
    }
    &-link {
      width: 170px;
      margin: 30px auto 0;
      li {
        margin-top: 10px;
      }
    }
  }

  &-avatar {
    display: flex;
    align-items: center;
    &-image {
      width: 60px;
      margin-right: 12px;
      img {
        width: 60px;
        height: 60px;
        border-radius: 50%;
      }
    }
    &-text {
      flex: 1;
    }
    &-name {
      font-size: 17px;
      font-weight: 700;
      line-height: 1.2;
    }
    &-id {
      margin: 2px 0 0 3px;
      color: palette('scheme', 'gray');
      font-size: 12px;
      font-weight: 700;
    }
  }

  &-list01 {
    display: flex;
    flex-wrap: wrap;
    margin-top: 25px;
    li {
      width: 50%;
      margin-top: 10px;
    }
    &-anchor {
      position: relative;
      display: block;
      padding-left: 22px;
      font-size: 12px;
      text-decoration: none;
      &::before {
        position: absolute;
        top: 3px;
        left: 0;
        color: palette('scheme', 'gray');
        font-family: 'Font Awesome 5 Free';
        font-size: 14px;
        font-weight: 900;
        line-height: 1;
      }
      &.-mypage::before {
        content: '\f4fe';
      }
      &.-bookshelf::before {
        content: '\f02d';
        left: 2px;
      }
      &.-message::before {
        content: '\f0e0';
      }
      &.-notice::before {
        content: '\f0f3';
        left: 2px;
      }
      &.-purchased::before {
        content: '\f03a';
        left: 2px;
      }
      &.-works::before {
        content: '\f044';
      }
    }
  }

  &-list02 {
    margin: 30px -17px 0;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    li {
      border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    }
    &-anchor {
      position: relative;
      display: block;
      padding: 9px 42px 9px 17px;
      text-decoration: none;
      &::after {
        content: '\f105';
        position: absolute;
        top: 50%;
        right: 18px;
        color: palette('scheme', 'theme');
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        line-height: 1;
        transform: translateY(-50%);
      }
      &.-disabled {
        background-color: #e5e5ea;
        color: #fff;
        cursor: not-allowed;
        pointer-events: none;
        &:after {
          color: #fff;
        }
      }
    }
    &-pdf-icon {
      padding-left: 4px;
      max-width: 24px;
      height: auto;
    }
  }

  &-logout {
    position: relative;
    display: block;
    width: calc(100% + 34px);
    margin: 0 -17px;
    padding: 10px 10px 10px 40px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 13px;
    text-align: left;
    &::before {
      content: '\f2f5';
      position: absolute;
      top: 50%;
      left: 19px;
      color: palette('scheme', 'gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 15px;
      font-weight: 900;
      line-height: 1;
      transform: translateY(-50%);
    }
  }

  &-overlay {
    position: fixed;
    top: -10px;
    right: 0;
    bottom: -10px;
    left: 0;
    z-index: 200;
    background: rgba(#000, .125);
    backface-visibility: hidden;
    opacity: 0;
    visibility: hidden;
    transition: opacity .25s ease, visibility .25s ease;
    &.is-show {
      opacity: 1;
      visibility: visible;
    }

    @include mq() {
      display: none;
    }
  }
  &-change-button-wrapper {
    position: absolute;
    left: 9px;
    top: 16px;
    display: inline-block;
  }

  &-change-button {
    position: relative;
    padding: 4px 28px 4px 18px;
    display: block;
    height: 100%;
    background: #2A2A2A 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    text-decoration: none;
    text-align: center;
    border-radius: 14px;
    font-weight: bold;
    font-size: 12px;
    &:hover {
      opacity: 0.8;
    }
    &:after {
      top: 7px;
      right: 13px;
      width: 11px;
      height: 11px;
      content: "";
      position: absolute;
      background-image: url(/img/icon_external.png);
      background-size: cover;
    }
  }
}

.global-fixedNav {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 57px;
  border-top: 1px solid palette('scheme', 'light-gray-primary');
  background: #fff;
  & + .global-footer {
    padding-bottom: 57px;
  }
  &-inner {
    max-width: 600px;
    margin: 0 auto;
  }
  &-list {
    display: flex;
    li {
      width: 25%;
    }
  }
  &-anchor {
    position: relative;
    display: block;
    height: 56px;
    padding-top: 35px;
    color: palette('scheme', 'medium-gray');
    font-size: 10px;
    text-decoration: none;
    text-align: center;
    &::before {
      position: absolute;
      top: 13px;
      left: 50%;
      color: palette('scheme', 'medium-gray');
      font-family: 'Font Awesome 5 Free';
      font-size: 19px;
      font-weight: 900;
      line-height: 1;
      transform: translateX(-50%);
    }
    &.has-active::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 5px;
      height: 5px;
      background: palette('scheme', 'red');
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    &.-home::before {
      content: '\f015';
    }
    &.-works::before {
      content: '\f044';
      margin: -1px 0 0 2px;
    }
    &.-message::before {
      content: '\f0e0';
    }
    &.-message.has-active::after {
      margin: -14px 0 0 15px;
    }
    &.-mypage::before {
      content: '\f4fe';
    }
    &.is-current {
      color: palette('scheme', 'gray');
      &::before {
        color: palette('scheme', 'gray');
      }
    }
  }

  @include mq() {
    display: none;
    & + .global-footer {
      padding-bottom: 0;
    }
  }
}
