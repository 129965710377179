.l-single {
  padding: 0 17px 50px;

  @include mq() {
    max-width: 1270px;
    margin: 0 auto;
    padding: 0 35px 80px;
    &.-medium {
      max-width: 970px;
    }
    &.-small {
      // max-width: 850px;
      width: 780px;
    }
  }
}

.l-column {
  width: 100%;
  padding: 0 17px 50px;

  @include mq() {
    max-width: 1270px;
    margin: 0 auto;
    padding: 0 35px 80px;
  }
  @include mq(xl) {
    display: flex;
  }

  &-main {

    @include mq() {
      width: 100%;
      max-width: 780px;
      margin: 0 auto;
    }
    @include mq(xl) {
      flex: 1;
      margin: 80px auto 0 0;
    }
  }

  &-side {
    display: none;

    @include mq(xl) {
      display: block;
      width: 300px;
      margin-top: 180px;
    }
  }
}
