.global-header {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #EFF0EB;

  @include mq() {
    position: relative;
  }

  &-inner {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    padding: 16px 12px;
    @include mq() {
      padding: 16px 30px;
    }
  }

  &-logo {
    display: flex;
    align-items: center;
    width: auto;
    padding: 0;
    a {
      display: block;
    }

    svg {
      height: 20px;
      fill:#272729;
      @at-root .-creatorMypage & {
        fill: #fff;
      }
    }

    @include mq() {
      margin: 0 auto 0 0;
    }
  }

  &-navTrigger {
    position: relative;
    width: 32px;
    height: 32px;
    @include mq(){
      width: 36px;
      height: 36px;
      top: 2px;
      left: 2px;
    }
    &-bar {
      display: inline-block;
      position: absolute;
      top: 7px;
      left: 4px;
      width: 24px;
      height: 2px;
      background: #535660;;
      &::before,
      &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 2px;
        background: #535660;
      }
      &::before {
        top: 8px;
      }
      &::after {
        top: 16px;
      }
    }

    &:hover{
      opacity: .6;
    }

  }

  &-contents {
    padding-left: 20px;
    flex: auto 1 1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    @include mq() {
      justify-content: flex-end;
    }
  }

  &-iconNav {
    display: flex;
    margin-right: 16px;
    li:not(:last-child) {
      margin-right: 16px;
    }
    @include mq(){
      margin-right: 24px;
      li:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  &-icon {
    position: relative;
    display: block;
    width: 24px;
    height: auto;
    text-decoration: none;
    &:hover {
      opacity: .6;
    }
    @include mq(){
      width: 24px;
      height: 24px;
    }
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      color:#535660;
      font-family: 'Font Awesome 5 Free';
      font-size: 24px;
      font-weight: 900;
      line-height: 1;
      transform: translate(-50%, -50%);
      @include mq(){
        font-size: 28px;
      }
    }

    &.-message {
      display: block;
      &::before {
        content: '\f0e0';
      }
    }

    &.-notice {
      &::before {
        content: '\f0f3';
      }
    }

    &.-search {
      &::before {
        content: '\f002';
      }
    }
    &-counter{
      display: none;
      &.-display{
        content: '';
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        color: #ffffff;
        font-size: 10px;
        font-weight: bold;
        top: 0;
        right: 0;
        height: 15px;
        padding: 5px;
        background: palette('scheme', 'red');
        border-radius: 8px;
        transform: translate(25%,-25%);
      }
    }
  }

  &-avatar {
    flex: auto 0 0;
    position: relative;
    display: block;
    width: 32px;
    margin-right: 16px;
    @include mq(){
      width: 36px;
      margin-right:24px;
    }
    &-button img {
      width:32px;
      height: 32px;
      border-radius: 50%;
      &:hover {
        opacity: .6;
      }
      @include mq(){
        width: 36px;
        height: 36px;
      }
    }
  }

  &-avatarDetail {
    display: none;

    @include mq() {
      display: block;
      position: absolute;
      top: calc(100% + 17px);
      right: 10px;
      z-index: 2;
      width: 304px;
      padding: 61px 17px 0;
      background: #fff;
      box-shadow: 0 6px 20px rgba(#000, .1);
      opacity: 0;
      visibility: hidden;
      transition: opacity .25s ease, visibility .25s ease;
      &.is-show {
        opacity: 1;
        visibility: visible;
      }
      &::before {
        content: '';
        position: absolute;
        top: -24px;
        right: 30px;
        width: 0;
        height: 0;
        border-top: 12px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 12px solid #fff;
        border-left: 10px solid transparent;
      }
      &-close {
        position: absolute;
        top: 13px;
        right: 9px;
      }
      &-data {
        display: flex;
        align-items: center;
      }
      &-image {
        width: 60px;
        margin-right: 12px;
        img {
          width: 60px;
          height: 60px;
          border-radius: 50%;
        }
      }
      &-text {
        flex: 1;
      }
      &-name {
        font-size: 17px;
        font-weight: 700;
        line-height: 1.2;
      }
      &-id {
        margin: 2px 0 0 3px;
        color: palette('scheme', 'gray');
        font-size: 12px;
        font-weight: 700;
      }
      &-nav01 {
        display: flex;
        flex-wrap: wrap;
        margin-top: 25px;
        li {
          width: 50%;
          margin-top: 10px;
        }
        &-anchor {
          position: relative;
          display: block;
          padding-left: 22px;
          font-size: 12px;
          text-decoration: none;
          &::before {
            position: absolute;
            top: 3px;
            left: 0;
            color: palette('scheme', 'gray');
            font-family: 'Font Awesome 5 Free';
            font-size: 14px;
            font-weight: 900;
            line-height: 1;
          }
          &.-mypage::before {
            content: '\f4fe';
          }
          &.-bookshelf::before {
            content: '\f02d';
          }
          &.-message::before {
            content: '\f0e0';
          }
          &.-notice::before {
            content: '\f0f3';
            left: 2px;
          }
          &.-purchased::before {
            content: '\f03a';
            left: 2px;
          }
          &.-works {
            cursor: pointer;
            &::before {
              content: '\f044';
            }
          }
        }
      }
      &-nav02 {
        margin: 30px -17px 0;
        border-top: 1px solid palette('scheme', 'light-gray-primary');
        li {
          border-bottom: 1px solid palette('scheme', 'light-gray-primary');
        }
        &-anchor {
          position: relative;
          display: block;
          padding: 9px 42px 9px 18px;
          text-decoration: none;
          &::after {
            content: '\f105';
            position: absolute;
            top: 50%;
            right: 18px;
            color: palette('scheme', 'theme');
            font-family: 'Font Awesome 5 Free';
            font-size: 17px;
            font-weight: 900;
            line-height: 1;
            transform: translateY(-50%);
          }
          &.-disabled {
            background-color: #e5e5ea;
            color: #fff;
            cursor: not-allowed;
            pointer-events: none;
            &:after {
              color: #fff;
            }
          }
        }
        &-pdf-icon {
          padding-left: 4px;
          max-width: 24px;
          height: auto;
        }
      }
      &-logout {
        position: relative;
        display: block;
        width: calc(100% + 34px);
        margin: 0 -17px;
        padding: 10px 10px 10px 40px;
        font-size: 13px;
        text-align: left;
        &::before {
          content: '\f2f5';
          position: absolute;
          top: 50%;
          left: 19px;
          color: palette('scheme', 'gray');
          font-family: 'Font Awesome 5 Free';
          font-size: 15px;
          font-weight: 900;
          line-height: 1;
          transform: translateY(-50%);
        }
      }
      &-change-button-wrapper {
        position: absolute;
        top: 13px;
        display: inline-block;
      }

      &-change-button {
        position: relative;
        padding: 4px 28px 4px 18px;
        display: block;
        height: 100%;
        background: #2A2A2A 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        color: #fff;
        text-decoration: none;
        text-align: center;
        border-radius: 14px;
        font-weight: bold;
        font-size: 12px;
        &:hover {
          opacity: 0.8;
        }
        &:after {
          top: 7px;
          right: 13px;
          width: 11px;
          height: 11px;
          content: "";
          position: absolute;
          background-image: url(/img/icon_external.png);
          background-size: cover;
        }
      }
    }
    @include mq(lg) {
      &-nav01-anchor:hover,
      &-nav02-anchor:hover,
      &-logout:hover {
        opacity: .6;
      }
    }
  }

  &-regist {
    display: block;
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 32px;
      background: #FF6160;
      border-radius: 24px;
      font-size: 13px;
      line-height: calc(20/14);
      font-weight: 700;
      text-decoration: none;
      text-align: center;
      color: #ffffff;
      &:hover{
        opacity: .6;
      }
      @include mq() {
        font-size: 14px;
        width: 104px;
      }
    }
  }

  &-authList {
    display: flex;
    align-items: center;
    >*:not(:first-child){
      margin-left: 12px;
    }
  }

  &-login {
    display: block;
    &-button {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4px 0;
      font-size: 14px;
      line-height: calc(20/14);
      font-weight: 700;
      text-decoration: none;
      text-align: center;
      color: #2B2B2B;
      &:hover{
        opacity: .6;
      }
      >*:not(:first-child){
        margin-left: 4px;
      }
      &-icon {
        font-size: 16px;
        color: #8E8E93;
      }
      @at-root .-creatorMypage &{
        color: #fff;
        &-icon {
          color: #fff;
        }
      }
    }

    &-button-notAuth {
      display: inline-block;
      padding: 8px 16px;
      background: palette('scheme', 'custom-gray');
      border-radius: 24px;
      font-size: 14px;
      font-weight: 700;
      text-decoration: none;
      text-align: center;
      position: relative;
      margin: auto;
      width: 180px;
      &:hover {
        background: #fff;
      }
      &::before {
        content: '\f2f6';
        position: relative;
        top: -3px;
        left: -8px;
        color: palette('scheme', 'black');
        font-family: 'Font Awesome 5 Free';
        font-size: 18px;
        font-weight: 900;
        line-height: 1;
        transform: translateY(-50%);
      }
    }
  }



  &-search{
    display: none;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    padding: 12px 15px;
    background-color: #fff;
    width: 100%;
    z-index: 2;

    &.-opened {
      display: flex;
    }

    @include mq(){
      position: relative;
      display: block;
      width: 320px;
      margin-right: 16px;
      padding: 0;
      background-color: inherit;
    }

    @include mq('lg'){
      width: 400px;
      margin-right: 16px;
      background-color: inherit;
    }

    &-trigger {
      display: flex;
      margin-right: 12px;
      &-icon {
        font-weight: 900;
        font-size: 24px;
        color: #535660;
        text-decoration: none;
        @at-root .-creatorMypage &{
          color: #fff;
        }
      }
      &.-isLoggedIn {
        margin-right: 16px;
      }
      @include mq() {
        display: none;
      }
    }

    &-form {
      position: relative;
      display: flex;
      width: 100%;
      margin-right: 16px;

      @include mq() {
        margin-right: 0;
      }

      &-search-icon {
        font-size: 20px;
        font-weight: 900;
        color: #FF6160;
        @include mq(){
          font-size: 20px;
        }
      }

      &-input {
        width: 100%;
        background: #EFF0EB;
        border-radius: 24px;
        padding: 8px 36px 8px 16px;
        font-size: 16px;
        line-height: calc(20/14);
        text-overflow: ellipsis;
        @include mq(){
          line-height: calc(20/16);
        }
      }

      &-submit {
        position: absolute;
        right: 16px;
        bottom: 50%;
        transform: translateY(50%);
        display: block;
        width: 24px;
        height: 24px;
        &:hover{
          opacity: .6;
        }
      }

      &-close {
        display: flex;
        align-items: center;
        width: 20px;
        height: 20px;
        &-icon {
          font-size: 24px;
          line-height: 1;
        }
        @include mq(){
          display: none;
        }
      }
    }
  }
}

.global-header.-creatorMypage {
  background: palette('scheme', 'black');
  border-bottom-color: palette('scheme', 'glay');

  .global-header {
    &-navTrigger-bar {
      &,
      &::before,
      &::after {
        background: #fff;
      }
    }
    &-icon::before {
      color: #fff;
    }

    &-search-input{
      background: #fff;
    }
  }
}
