.c-button-primary,
.c-button-secondary {
  display: inline-block;
  padding: 7px 15px;
  border-radius: 34px;
  color: #fff;
  font-size: 17px;
  line-height: 1.2;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  &.-full {
    display: block;
    width: 100%;
  }
  &.-spacing-sm {
    padding: 7px 10px;
    letter-spacing: -.15em;
  }
  &[disabled],
  &.is-disabled {
    background: palette('scheme', 'medium-gray');
    border: 1px solid palette('scheme', 'medium-gray');
    color: #fff;
    cursor: not-allowed;
    pointer-events: none;
  }
  &.-outline[disabled],
  &.-outline.is-disabled {
    background: #fff;
    border: 1px solid palette('scheme', 'medium-gray');
    color: palette('scheme', 'medium-gray');
    cursor: not-allowed;
    pointer-events: none;
  }
  &.-share {
    padding-left: 13px;
    background-color: palette('scheme', 'twitter');
    border: 1px solid palette('scheme', 'twitter');

    @include mq() {
      padding-left: 25px;
    }
    &:before {
      position: relative;
      top: 1px;
      left: -5px;
      color: #ffffff;
      content: "\f099";
      font-family: "Font Awesome 5 Brands";
      font-weight: 900;
      font-size: 18px;
    }
    &:hover {
      background-color: palette('scheme', 'twitter');
        color: #ffffff;
        &:before {
          color: #ffffff;
        }

      @include mq(lg) {

        background-color: #ffffff;
        color: palette('scheme', 'twitter');
        &:before {
        color: palette('scheme', 'twitter');
      }
      }
    }
  }
  &.-responsive{
    @include mq(md,max){
      font-size: 12px;
      padding: 5px 0;
    }
  }

  &.-addHashtag {
    padding: 6px 24px;
    font-size: 15px;

    @include mq(sm, max){
      width: 100%;
    }
  }

  @include mq() {
    &.-spacing-sm {
      letter-spacing: -.075em;
    }
  }
}

.c-button-primary {
  $color: palette('scheme', 'black');

  background: $color;
  border: 1px solid $color;
  &.-outline {
    background: #fff;
    color: $color;
  }

  @include mq(lg) {
    &:hover {
      background: #fff;
      color: $color;
    }
    &.-outline:hover {
      background: $color;
      color: #fff;
    }
  }
}

.c-button-secondary {
  $color: palette('scheme', 'theme');

  background: $color;
  border: 1px solid $color;
  &.-outline {
    background: #fff;
    color: $color;
  }

  @include mq(lg) {
    &:hover {
      background: #fff;
      color: $color;
    }
    &.-outline:hover {
      background: $color;
      color: #fff;
    }
  }

  &.-disabled {
    pointer-events: none;
    background-color: #e5e5ea;
    color: #fff;
    border: none;
  }
}

.c-upload-button {
  position: relative;
  display: inline-block;
  border: 1px solid palette('scheme', 'light-gray-secondary');
  border-radius: 5px;
  overflow: hidden;
  cursor: pointer;
  &-input {
    position: absolute;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
  }
  &-text {
    position: relative;
    display: inline-block;
    padding: 8px 8px 8px 24px;
    font-size: 13px;
    line-height: 1.2;
    &::after {
      content: '\f1c5';
      position: absolute;
      top: calc(50% - 1px);
      left: 9px;
      font-family: 'Font Awesome 5 Free';
      font-weight: 900;
      transform: translateY(-50%);
    }

    @include mq(md, max) {
      display: flex;
      justify-content: center;
    }
  }

  @include mq(lg) {
    &:hover {
      background: palette('scheme', 'light-gray-primary');
      border-color: palette('scheme', 'light-gray-primary');
    }
  }

  @include mq(xs, max) {
    width: calc(100vw / 2 - 30px);
  }
}

.c-clear-button {
  position: relative;
  padding: 5px 8px;
  display: inline-block;
  width: 137px;
  border: 1px solid #363636;
  border-radius: 5px;
  background-color: #363636;
  color: #ffffff;
  overflow: hidden;
  cursor: pointer;
  font-size: 13px;

  &:disabled {
    background-color: #c7c7cc;
    border-color: #c7c7cc;
    &:hover {
      background-color: #c7c7cc;
      border-color: #c7c7cc;
      color: #ffffff;
    }
  }

  @include mq(lg) {
    &:hover {
      background: palette('scheme', 'light-gray-primary');
      border-color: #c7c7cc;
      color: #363636;
    }
  }

  @include mq(xs, max) {
    width: calc(100vw / 2 - 30px);
  }
}

.c-volumeRegister-button {
  position: relative;
  display: inline-block;
  padding: 8px 8px 8px 24px;
  border: 1px solid palette('scheme', 'light-gray-secondary');
  border-radius: 5px;
  font-size: 13px;
  line-height: 1.2;
  &::after {
    content: '\f02d';
    position: absolute;
    top: 50%;
    left: 8px;
    font-family: 'Font Awesome 5 Free';
    font-weight: 900;
    transform: translateY(-50%);
  }
}

.c-close-button {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 32px;
  &-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    display: inline-block;
    width: 18px;
    height: 2px;
    background: palette('scheme', 'gray');
    transform: translate(-50%, -50%) rotate(45deg);
    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: inline-block;
      width: 18px;
      height: 2px;
      background: palette('scheme', 'gray');
      transform: translate(-50%, -50%) rotate(-90deg);
    }
  }
  &-label {
    position: absolute;
    z-index: -1;
    clip: rect(0 0 0 0);
    overflow: hidden;
    width: 1px;
    height: 1px;
  }

  @include mq(lg) {
    &:hover {
      opacity: .6;
    }
  }
}
