.p-searchNav {
  display: flex;
  justify-content: center;
  padding: 15px 17px 0;

  @include mq(lg) {
    padding: 25px 17px 0;
  }

  &-item {
    width: 50%;
    max-width: 170px;

    @include mq(lg) {
      width: auto;
      max-width: inherit;
      min-width: 185px;
    }
  }

  &-anchor {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 8px 5px 6px;
    font-size: 15px;
    line-height: 1.2;
    text-align: center;
    text-decoration: none;
    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
      visibility: hidden;
    }
    &.is-active::after {
      opacity: 1;
      visibility: visible;
    }

    @include mq(lg) {
      padding: 8px 30px 6px;
      font-size: 17px;
      &:hover::after {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}

.p-searchForm {
  background: palette('scheme', 'light-gray-primary');

  &-inner {
    padding: 14px 17px;
  }

  &-textfield {
    display: block;
    width: 100%;
    max-width: 340px;
    margin: 0 auto;

    @include mq(lg) {
      max-width: 400px;
    }
  }

  &-nav {
    margin-top: 12px;
    text-align: center;
    &-item {
      display: inline-block;
      margin: 0 4px;
    }
    &-anchor {
      color: palette('scheme', 'gray');
    }
  }
}

.p-searchResult {
  &-header {
    margin: 0 0 22px;

    @include mq() {
      margin: 65px 0 40px;
    }
    @include mq(lg) {
      margin: 65px 0 74px;
      &-inner {
        display: flex;
      }
    }
  }

  &-col01 {
    &-inner {
      position: relative;
      margin-top: 14px;
      padding: 6px 120px 6px 0;
    }

    @include mq() {
      text-align: center;
      &-inner {
        position: relative;
        display: inline-block;
        margin-top: 25px;
        padding: 6px 120px 6px 0;
      }
    }
    @include mq(lg) {
      width: 50%;
      text-align: left;
      &-inner {
        margin: 15px 0 0;
        padding: 0;
      }
      &-select {
        display: none;
      }
    }
  }

  &-col02 {
    &-select {
      display: none;
    }

    @include mq(lg) {
      margin-left: auto;
      &-select {
        position: relative !important;
        display: inline-block;
      }
    }
  }

  &-title {
    margin: 0 -17px;
    padding: 13px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      margin: 0;
      padding: 0;
      border-bottom: none;
      font-size: 22px;
    }
    @include mq(lg) {
      text-align: left;
    }
  }

  &-detail {

    @include mq() {
      min-width: 220px;
      text-align: left;
    }
  }

  &-select {
    position: absolute;
    top: 0;
    right: 0;
    width: 100px;
    &::after {
      content: '\f160';
      position: absolute;
      top: 50%;
      left: 13px;
      font-family: 'Font Awesome 5 Free';
      font-size: 13px;
      font-weight: 900;
      transform: translateY(-50%);
      pointer-events: none;
    }
    &-body {
      width: calc(100% + 26px);
      margin: -4px 0 -4px -13px;
      padding: 6px 15px 6px 40px;
      border: 1px solid palette('scheme', 'light-gray-secondary');
      border-radius: 5px;
      transform: scale(.8);
    }

    @include mq(lg) {
      &-body:hover {
        background: palette('scheme', 'light-gray-primary');
        border-color: palette('scheme', 'light-gray-primary');
      }
    }
  }

  &-nav {
    margin-top: 15px;
    text-align: center;
    li {
      display: inline-block;
      margin: 0 4px;
    }

    @include mq(lg) {
      display: inline-block;
      margin: 5px 10px 0 0;
      vertical-align: top;
    }
  }

  &-toggle {
    position: relative;
    display: block;
    margin: 15px -17px -22px;
    padding: 10px 17px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    cursor: pointer;
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .p-searchResult-toggle-button {
        background: palette('scheme', 'check-green');
        border-color: palette('scheme', 'check-green');
        &::after {
          left: 12px;
          box-shadow: 0 2px 6px rgba(#000, .05);
        }
      }
    }
    &-button {
      position: absolute;
      top: calc(50% - 1px);
      right: 17px;
      display: inline-block;
      width: 32px;
      height: 20px;
      background: #fff;
      border: 1px solid palette('scheme', 'light-gray-primary');
      border-radius: 16px;
      font-size: 0;
      vertical-align: top;
      transition: .25s ease;
      transform: translateY(-50%);
      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 0 2px 6px rgba(#000, .1);
        transition: .25s ease;
      }
    }

    @include mq() {
      display: block;
      max-width: 250px;
      margin: 15px auto -20px;
      padding: 9px 0;
      border-top: none;
      border-bottom: none;
      &-button {
        right: 0;
      }
    }
    @include mq(lg) {
      margin: 5px 0 -10px auto;
    }
  }

  &-nothing {
    padding-top: 45px;
    font-size: 16px;
    text-align: center;
    @include mq() {
      padding: 0;
    }
  }
}

.p-searchDetail {
  &-header {
    margin: 25px 0;

    @include mq() {
      margin: 60px 0 45px;
    }
  }

  &-title {
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      font-size: 22px;
    }
  }

  &-note {
    padding: 5px 0 10px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    text-align: center;

    @include mq() {
      padding: 10px 0 15px;
      font-size: 13px;
    }
  }

  &-action {
    display: flex;
    max-width: 340px;
    margin: 34px auto 0;
  }
  &-clear {
    width: 108px;
    &-button {
      letter-spacing: -.15em;
    }
  }
  &-submit {
    flex: 1;
    margin-left: 7px;
  }
}

.p-searchCharacter {
  &-list {
    margin: 0 -17px -14px;

    @include mq() {
      margin: 0;
    }
    @include mq(lg) {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }
  }

  &-item {
    display: flex;
    padding: 14px 17px;
    border-top: 1px solid palette('scheme', 'light-gray-primary');

    @include mq() {
      padding: 14px 20px;
    }
    @include mq(lg) {
      width: calc(50% - 10px);
      margin: 0 5px;
    }
  }

  &-label {
    width: 115px;
    padding: 6px 15px 0 0;

    @include mq() {
      width: 45%;
      max-width: 200px;
      padding: 6px 20px 0 0;
    }
  }

  &-body {
    flex: 1;
  }

  &-gender {
    display: flex;
    margin: 0 -4px;
    &-item {
      position: relative;
      width: calc(33.33334% - 8px);
      margin: 0 4px;
      cursor: pointer;
    }
    &-input {
      position: absolute;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      &:checked + .p-searchCharacter-gender-text {
        background: palette('scheme', 'light-gray-primary');
        border-color: palette('scheme', 'light-gray-primary');
      }
    }
    &-text {
      position: relative;
      display: block;
      height: 32px;
      border: 1px solid palette('scheme', 'light-gray-secondary');
      border-radius: 5px;
      font-size: 0;
      &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: 'Font Awesome 5 Free';
        font-size: 17px;
        font-weight: 900;
        transform: translate(-50%, -50%);
      }
      &.-male::after {
        content: '\f183';
        color: palette('scheme', 'sky-blue');
      }
      &.-female::after {
        content: '\f182';
        color: palette('scheme', 'theme');
      }
      &.-child::after {
        content: '?';
        // top: calc(50% + 2px);
        // color: palette('scheme', 'corn-yellow');
        font-size: 14px;
      }
    }

    @include mq() {
      &-text:hover {
        background: palette('scheme', 'light-gray-primary');
        border-color: palette('scheme', 'light-gray-primary');
      }
    }
  }
}

.p-searchNovalResult {
  &-list {
    margin: -17px -17px 0;

    @include mq() {
      display: flex;
      flex-wrap: wrap;
      margin: -30px -5px 0;
    }
  }

  &-item {
    display: flex;
    align-items: flex-start;
    padding: 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    &:last-child {
      border-bottom: none;
    }

    @include mq() {
      width: calc(50% - 10px);
      margin: 0 5px;
      padding: 30px 10px 30px 0;
      &:last-child {
        border-bottom: 1px solid palette('scheme', 'light-gray-primary');
      }
    }
  }

  &-image {
    width: 30%;
    min-width: 100px;
    max-width: 160px;
    &-anchor {
      position: relative;
      display: block;
    }
    img {
      width: 100%;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }

    @include mq() {
      width: 33%;
      max-width: 165px;
      &-label {
        letter-spacing: 0;
      }
    }
  }

  &-novel {
    &-creator {
      position: relative;
      display: block;
      margin: 10px 0 7px;
      text-align: center;
      &::before {
        content: '\f304';
        position: relative;
        top: 1px;
        left: -3px;
        color: palette('scheme', 'gray');
        font-family: 'Font Awesome 5 Free';
        font-size: 13px;
        font-weight: 900;
      }
      &-anchor {
        color: palette('scheme', 'gray');
        font-size: 14px;
      }

      @include mq() {
        margin: 10px 0 7px;
        &::before {
          font-size: 14px;
        }
        &-anchor {
          font-size: 15px;
        }
      }
    }

    &-bookmark {
      font-weight: normal;
      text-align: center;
      color: #f15353;
      line-height: 1.3;
    }
  }

  &-detail {
    position: relative;
    top: -2px;
    flex: 1;
    margin-left: 15px;

    @include mq() {
      top: 0;
      margin-left: 18px;
      padding-top: 5px;
    }
  }

  &-copy {
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-title {
    font-size: 15px;
    font-weight: 700;

    @include mq() {
      font-size: 17px;
    }
  }

  &-genre {
    display: flex;
    flex-wrap: wrap;
    > li {
      margin: 7px 8px 0 0;
      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  &-genre,
  &-tag {
    margin-top: 6px;

    @include mq() {
      margin-top: 6px;
    }
  }

  &-creator {
    margin-top: 12px;
    li {
      position: relative;
      display: inline-block;
      margin-top: 3px;
      padding-left: 14px;
      &::before {
        position: absolute;
        top: 2px;
        left: 0;
        color: palette('scheme', 'gray');
        font-family: 'Font Awesome 5 Free';
        font-size: 11px;
        font-weight: 900;
      }
      &:first-child {
        margin-right: 16px;
      }
    }
    &-type01::before {
      content: '\f304';
    }
    &-type02::before {
      content: '\f53f';
    }
    &-anchor {
      color: palette('scheme', 'gray');
      font-size: 12px;
    }

    @include mq() {
      margin-top: 14px;
      li {
        padding-left: 16px;
        &::before {
          top: 1px;
          font-size: 12px;
        }
      }
      &-anchor {
        font-size: 13px;
      }
    }
  }
}

.p-searchIllustResult {
  &-list {
    display: flex;
    flex-wrap: wrap;
    margin: -25px -5px 34px;

    @include mq() {
      margin: -50px -15px 0;
    }
  }

  &-item {
    width: calc(33.33334% - 10px);
    margin: 25px 5px 0;

    @include mq() {
      width: calc(33.33334% - 30px);
      margin: 50px 15px 0;
    }
    @include mq(lg) {
      width: calc(20% - 30px);
    }
  }

  &-image {
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      display: block;
    }
    img {
      width: 100%;
    }
  }

  &-title {
    margin-top: 5px;
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 12px;
      font-size: 17px;
    }
  }
}


//グランドトップのサーチフォーム周り
.p-topSearch{
  background: #1E1E1E;
  padding: 40px 20px;

  &-form{
    display: flex;
    justify-content: center;
    align-items: stretch;
    padding-bottom:50px;
    @include mq(){
      padding-bottom: 40px;
    }
  }

  &-textWrapper{
    position: relative;
    flex: 1 1 100%;
    max-width: 340px;
    margin-right: 20px;

    @include mq(lg) {
      max-width: 420px;
    }
  }

  &-text{
    display: block;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 8px;
    font-size: 16px;
    line-height: 1;
    padding: 14px 32px 14px 13px;
    @include mq(lg) {
      padding: 16px 14px 12px 12px;
    }
  }

  &-submitLabel{
    flex:0 0 auto;
    display:inline-flex;
    justify-content: center;
    align-items: center;
    background: #F15352;
    border-radius: 8px;
    padding: 13px 24px;
    cursor: pointer;

    &:hover{
      opacity: 0.7;
    }
    >span{
      display: none;
      @include mq(){
        display: block;
        flex: 1 0 auto;
        color: #ffffff;
        font-weight: bold;
        font-size: 16px;
        margin-left: 8px;
      }
    }
    &::before {
      content: '\f002';
      display: block;
      color: #ffffff;
      font-family: 'Font Awesome 5 Free';
      font-size: 24px;
      font-weight: 900;
      line-height: 1;
    }
  }

  &-nav {
    position: absolute;
    top: 100%;
    left: 0;
    margin-top: 24px;
    text-align: center;
    @include mq(){
      margin-top: 13px;
    }
    &-item {
      display: inline-block;
      margin: 0 4px;
      &:hover{
        opacity: 0.7;
      }
    }
    &-anchor {
      color: #ffffff;
      text-decoration: none;
      display: flex;
      align-items: center;
      > *:not(:first-child){
        margin-left: 8px;
      }
    }
  }

}

// クリエイタートップ サーチフォーム
.p-creatorSearch {
  display: flex;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  @include mq(md) {
    flex-direction: row;
    align-items: flex-start;
  }

  > .p-creatorSearch-freeword,
  > .p-creatorSearch-submit {
    margin-top: 32px;
    @include mq(md) {
      margin-top: 0;
      margin-left: 22px;
    }
  }

  &-category {
    flex: 1 1 auto;
    width: 100%;
    max-width: 488px;
    @include mq(md) {
      max-width: 284px;
    }
    &-radio {
      margin-top: 8px;
      background-color: #d6d6d6;
      border-radius: 8px;
      padding: 16px 46px 16px 30px;
      display: flex;
      justify-content: space-between;
      @include mq(md) {
        padding: 16px 22px;
        justify-content: initial;
      }
      @include mq(single) {
        padding: 16px 38px;
      }
      .c-radio-secondary {
        &-input {
          visibility: visible;
        }
      }
      .c-radio-secondary + .c-radio-secondary {
        @include mq(md) {
          margin-left: 48px;
        }
      }
      .c-radio-secondary-input:focus-visible + .c-radio-secondary-text:before {
        outline: -webkit-focus-ring-color auto 1px;
      }

      .c-radio-secondary-text {
        padding-left: 32px;
        @include mq(md) {
          padding-left: 28px;
        }
      }

      .c-radio-secondary-text::before {
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 6px #00000029;
        border: none;
        width: 24px;
        height: 24px;
        @include mq(md) {
          width: 20px;
          height: 20px;
        }
      }

      .c-radio-secondary-text::after {
        width: 12px;
        height: 12px;
        top: 6px;
        left: 6px;
        @include mq(md) {
          width: 10px;
          height: 10px;
          left: 5px;
        }
      }

      .c-radio-secondary-text {
        font-size: 16px;
      }

      .c-radio-secondary + .c-radio-secondary {
        margin-left: 48px;
      }
    }
  }

  &-freeword {
    flex: 1 1 auto;
    width: 100%;
    max-width: 488px;
    @include mq(md) {
      min-width: 230px;
    }
    &-textfield {
      margin-top: 8px;
      display: block;
      .c-textfield-primary {
        height: 56px;
        background: #fff 0% 0% no-repeat padding-box;
        box-shadow: 0px 3px 8px #00000029;
        border: none;
        border-radius: 8px;
      }
    }
  }

  &-submit {
    width: 100%;
    max-width: 284px;
    @include mq(md) {
      max-width: 182px;
      padding-top: 32px;
    }
    &-button {
      width: 100%;
      height: 48px;
      background: #f15352 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 12px #00000029;
      border-radius: 28px;
    }
  }

  &-otherSearch {
    margin-top: 12px;
    text-align: center;
    &-item {
      display: inline-block;
      margin: 0 4px;
    }
    &-anchor {
      color: palette('scheme', 'gray');
    }
  }
}

// クリエイタートップ ログイン後のサーチフォーム
.p-creatorSearch {
  &.-type02 {
    .p-creatorSearch-category {
      @include mq(single) {
        max-width: 354px;
      }
      &-radio {
        @include mq(md) {
          padding: 16px;
        }
        @include mq(single) {
          padding: 16px 38px;
        }
      }
    }
    .p-creatorSearch-freeword {
      @include mq(md) {
        max-width: 590px;
      }
    }
    .p-creatorSearch-submit {
      @include mq(md) {
        max-width: 150px;
      }
    }
  }
}
