// 4-1
.mypage-index-avatar {
  @include mq() {
    position: relative;
    padding-left: 220px;
  }

  &-image {
    width: 200px;
    margin: 0 auto;
    text-align: center;
    img {
      border-radius: 50%;
    }

    @include mq() {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-row {
    padding-top: 17px;
    text-align: center;

    @include mq() {
      padding-top: 20px;
      text-align: left;
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;

    @include mq() {
      font-size: 22px;
    }
  }

  &-id {
    margin-top: 2px;
    color: palette("scheme", "gray");
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      margin: 2px 0 0 3px;
      font-size: 14px;
    }
  }

  &-link01 {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    li {
      margin: 10px 5px 0;
    }
    &-button {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      &.-pixiv,
      &.-syosetu {
        width: auto;
        height: 36px;
        padding: 0 6px;
        border-radius: 18px;
      }
      &.-pixiv {
        background: palette("scheme", "pixiv");
      }
      &.-syosetu {
        background: palette("scheme", "syosetu");
      }
    }

    @include mq() {
      li {
        margin: 10px 0 0 10px;
      }
    }
    @include mq(lg) {
      &-button.-pixiv:hover,
      &-button.-syosetu:hover {
        opacity: 0.6;
      }
    }
  }

  &-user {
    margin-top: 17px;
    color: palette("scheme", "gray");
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        color: palette("scheme", "medium-gray");
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        transform: translateY(-50%);
      }
    }

    @include mq() {
      margin: 10px 0 0 3px;
      text-align: left;
    }
  }

  &-follow {
    padding-left: 15px;
    &::before {
      content: "\f007";
      font-size: 13px;
    }
  }

  &-follower {
    margin-left: 12px;
    padding-left: 22px;
    &::before {
      content: "\f500";
      font-size: 15px;
    }
  }

  &-userCount {
    color: palette("scheme", "gray");
    font-weight: 700;
  }

  &-profile {
    margin-top: 30px;
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-update {
    margin-top: 5px;
    color: palette("scheme", "gray");
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-edit {
    @include mq() {
      margin: 30px auto 0;
    }
  }

  &-link02 {
    margin: 40px -17px 0;

    @include mq() {
      margin: 40px auto 0;
    }
  }
}
// 4-3
.mypage-follow {
  &-list {
    margin: 0 -17px;

    @include mq() {
      margin: 0;
    }
  }

  &-item {
    position: relative;
    border-bottom: 1px solid palette("scheme", "light-gray-primary");
  }

  &-anchor {
    display: flex;
    padding: 20px 17px;
    text-decoration: none;

    @include mq(lg) {
      &:hover {
        opacity: 0.6;
        .mypage-follow-image img {
          opacity: 1;
        }
      }
    }
  }

  &-image {
    width: 60px;
    img {
      border: 1px solid palette("scheme", "light-gray-primary");
      border-radius: 50%;
    }

    @include mq() {
      width: 100px;
    }
  }

  &-detail {
    position: relative;
    flex: 1;
    min-width: 0;
    padding: 0 0 0 12px;

    @include mq() {
      padding: 15px 0 0 20px;
    }
  }

  &-name,
  &-id {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &-name {
    margin-right: 115px;
    font-size: 17px;
    font-weight: 700;
    line-height: 1.4;

    @include mq() {
      margin-right: 145px;
    }
  }

  &-id {
    margin: 2px 115px 0 0;
    color: palette("scheme", "gray");
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      margin: 2px 145px 0 0;
    }
  }

  &-status {
    display: inline-block;
    margin-top: 5px;
    padding: 1px 5px;
    background: palette("scheme", "light-gray-primary");
    color: palette("scheme", "gray");
    font-size: 11px;
    font-weight: 700;
  }

  &-action {
    position: absolute;
    top: 20px;
    right: 17px;
    z-index: 2;
    width: 105px;

    @include mq() {
      top: 37px;
      width: 130px;
    }
  }

  &-button {
    padding: 5px 10px;
    font-size: 14px;
    letter-spacing: -0.1em;

    @include mq() {
      font-size: 15px;
      letter-spacing: -0.075em;
    }
  }
}
