.global-footer {
  margin-top: auto;
  position: relative;
  border-top: 1px solid #c5c5c5;
  background: #EFF0EB;

  &-pagetop-anchor {
    position: absolute;
    display: block;
    bottom: calc(100% + 20px);
    right: 15px;
    width: 40px;
    height: 40px;
    background: #535660;
    border-radius: 50%;
    font-size: 0px;
    text-decoration: none;

    &::before{
      content: "";
      position: absolute;
      bottom: 12px;
      left: 0;
      right: 0;
      margin: auto;
      vertical-align: middle;
      width: 11px;
      height: 11px;
      border-top: 2px solid #ffffff;
      border-left: 2px solid #ffffff;
      transform: rotate(45deg);
    }

    &:hover {
      background: #333;
    }
  }

  &-inner {
    width: 100%;
    margin: 0 auto;
    padding: 0 15px;
    @include mq(){
      max-width: 1170px;
      padding: 0 35px;
    }
  }

  &-inner-upper {
    margin: 32px 0;
    @include mq(){
      display: flex;
      flex-wrap: wrap;
    }
  }

  &-guid{
    @include mq(){
      display: flex;
    }
    > ul {
      @include mq(){
        padding-right: 24px;
      }

      &:not(:first-child){
        margin-top: 24px;
        @include mq(){
          margin-top: 0;
          padding-left: 24px;
          border-left: 1px solid #c5c5c5;
        }
      }
      > li {
        &:not(:first-child){
          margin-top: 24px;
          @include mq(){
            margin-top: 11px;
          }
        }
        font-size: 14px;
        line-height: calc(19.5/14);

        &.--size-large {
          font-size: 15px;
          @include mq(){
            font-size: 14px;
          }
        }

        a{
          text-decoration: none;
          &:hover{
            opacity: .6;
          }
        }
      }
    }
  }

  &-banners {
    width: 100%;
    margin: 24px 0 0;

    a {
      display: block;
      margin-top: 8px;
    }
    a:first-child {
      margin-top: 0;
    }

    @include mq() {
      flex: auto 1 1;
      width: 100%;
      margin: 0 0 0 auto;
      max-width: 255px;
    }
  }

  &-common{
    width: 100%;
    margin: 32px 0 0;
    @include mq(){
      margin: 22px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &-horizon{
    background-color: #c5c5c5;
    border: none;
    height: 1px;
    @include mq(){
      width: 100vw;
      margin: 0 calc(50% - 50vw);
    }
  }

  &-policy{
    @include mq(){
      flex: auto 0 1;
    }
    >ul{
      @include mq(){
        margin-top: -10px;
        max-width: 800px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        border: none;
      }
      >li {
        flex: auto 0 0;
        font-size: 14px;
        line-height: calc(19.5/14);
        @include mq(md,max,true){
          &:not(:first-child){
            margin-top: 24px;
          }
        }
        @include mq(){
          font-size: 14px;
          margin-top: 10px;
          padding: 0 10px;
          &:first-child{
            padding-left: 0;
          }
          &:not(:first-child){
            border-left: 1px solid #c5c5c5;
          }
        }
        &.--size-large {
          font-size: 15px;
          @include mq(){
            font-size: 14px;
          }
        }
        a{
          text-decoration: none;
          &:hover{
            opacity: .6;
          }
          .global-footer-policy-pdf-icon {
            padding-left: 4px;
            max-width: 24px;
            height: auto;
            vertical-align: sub;
          }
        }
      }
    }
  }


  &-copyright {
    margin-top: 56px;
    display: block;
    font-family: $ff-en;
    font-size: 12px;
    letter-spacing: .1em;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    @include mq(){
      margin: 0;
      flex: auto 0 0;
    }

    svg{
      color:#2B2B2B;
      width: 163px;
      @include mq(){
        max-width:138px;
      }
    }
    small{
      margin-top: 4px;
      color:#535660;
      font-size: 11px;
      line-height: calc(19.5/12);
      letter-spacing: 0px;
      @include mq(){
        margin: 4px 0 0 auto;
      }
    }
  }

  &-commonWrapper{
    width: 100%;
    padding: 32px 15px 39px;
    @include mq(){
      padding: 32px calc(100% / 2 - 1200px / 2);
      @media screen and ( max-width:calc(1200px + 10px * 2)) {
        padding: 32px 10px;
      }
    }
  }

}
