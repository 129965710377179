.c-input-password {
  position: relative;

  > .far {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
    font-size: 20px;
    cursor: pointer;
  }
}
