.c-corner-label {
  width: 92px;
  height: 92px;
  overflow: hidden;
  top: -3px;
  left: -3px;
  z-index: 1;
  position: absolute;
  @include mq() {
    width: 106px;
    height: 108px;
    top: -2px;
    left: -3px;
  }
}

.c-corner-label > p {
  font-size: 12px;
  line-height: 1.1em;
  position: relative;
  top: 28px;
  left: -28px;
  width: 134px;
  padding: 4px 21px;
  transform: rotate(-45deg);
  text-align: center;
  letter-spacing: 0.5px;
  color: #ffffff;
  background-color: #ea352d;
  box-shadow: 0 4px 6px rgb(0 0 0 / 10%);
  @include mq() {
    font-size: 1em;
    line-height: 1.2em;
    top: 26px;
    left: -34px;
    width: 150px;
    padding: 7px 21px;
  }

  &:before, &:after {
    position: absolute;
    bottom: -4px;
    content: '';
    border-width: 4px 4px 0 4px;
    border-style: solid;
    border-color: #b3241c transparent transparent transparent;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }
}
