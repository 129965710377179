html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
* {
  margin: 0;
  padding: 0;
}
*,
::before,
::after {
  box-sizing: inherit;
}
::before,
::after {
  text-decoration: inherit;
  vertical-align: inherit;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}
audio,
canvas,
progress,
video {
  display: inline-block;
}
audio:not([controls]) {
  display: none;
  height: 0;
}
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: bottom;
}
img {
  border-style: none;
}
svg {
  fill: currentColor;
}
svg:not(:root) {
  overflow: hidden;
}
progress {
  vertical-align: baseline;
}
template,
[hidden] {
  display: none;
}
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
}
abbr[title] {
  border-bottom: 1px dotted;
  text-decoration: none;
}
hr {
  overflow: visible;
}
b,
strong {
  font-weight: bolder;
}
dfn {
  font-style: italic;
}
mark {
  background-color: #ff0;
  color: #000;
}
small {
  font-size: 83.33333%;
}
sub, sup {
  position: relative;
  font-size: 83.33333%;
  line-height: 0;
  vertical-align: baseline;
}
sub {
  bottom: -.25em;
}
sup {
  top: -.5em;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
legend {
  display: table;
  max-width: 100%;
  border: none;
  color: inherit;
  white-space: normal;
}
fieldset {
  border: 1px solid #bdc3c7;
  margin: 0 2px;
  padding: .35em .625em .75em;
}
ul[class],
ol[class],
nav ol,
nav ul {
  list-style: none;
}
button,
input,
optgroup,
select,
textarea {
  font: inherit;
}
button,
input,
select,
textarea {
  background-color: transparent;
  border-style: none;
  border-radius: 0;
  color: inherit;
  -webkit-appearance: none;
  -moz-appearance: none;
  vertical-align: bottom;
}
input,
textarea {
  border-radius: 0;
}
button {
  overflow: visible;
  text-transform: none;
}
select {
  -webkit-appearance: none;
  text-transform: none;
}
select::-ms-expand {
  display: none;
}
select::-ms-value {
  color: currentColor;
}
optgroup {
  font-weight: bold;
}
textarea {
  overflow: auto;
  resize: vertical;
}
button,
html [type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: 0;
  padding: 0;
}
button:-moz-focusring,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  outline: 1px dotted ButtonText;
}
[type="number"] {
  width: auto;
}
[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}
[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}
::-webkit-input-placeholder {
  color: inherit;
  opacity: 1;
}
::-moz-placeholder {
  color: inherit;
  opacity: 1;
}
:-ms-input-placeholder {
  color: inherit;
  opacity: 1;
}
input,
textarea,
select,
option,
button {
  font-size: 16px;
}
a[href],
label[for],
button,
input[type="button"],
input[type="submit"],
input[type="reset"],
input[type="image"],
select,
input[type="radio"],
input[type="checkbox"] {
  cursor: pointer;
}
button,
input,
optgroup,
select,
textarea {
  &[disabled] {
    cursor: not-allowed;
  }
}
[aria-busy="true"] {
  cursor: progress;
}
[aria-controls] {
  cursor: pointer;
}
[aria-disabled] {
  cursor: default;
}
a {
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
  -webkit-tap-highlight-color: rgba(0,0,0,.25);
}
a:active,
a:hover {
  outline-width: 0;
}
@media screen {
  [hidden~="screen"] {
    display: inherit;
  }
  [hidden~="screen"]:not(:active):not(:focus):not(:target) {
    position: absolute !important;
    clip: rect(0 0 0 0) !important;
  }
}
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: .01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: .01ms !important;
    scroll-behavior: auto !important;
  }
}