// 5-1
.userpage-index {
  width: 100%;
  margin-top: 30px;

  @include mq() {
    max-width: 1006px;
    margin-top: 50px;
  }
  @include mq(xl) {
    margin-top: 80px;
  }
}
.userpage-index-avatar {

  @include mq() {
    position: relative;
    padding-left: 220px;
  }

  &-image {
    width: 200px;
    margin: 0 auto;
    img {
      border-radius: 50%;
    }

    @include mq() {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &-row {
    padding-top: 17px;
    text-align: center;

    @include mq() {
      display: flex;
      padding-top: 20px;
      text-align: left;
    }
  }

  &-col01 {

    @include mq() {
      flex: 1;
      margin-right: 20px;
    }
  }

  &-col02 {

    @include mq() {
      text-align: right;
    }
  }

  &-name {
    font-size: 17px;
    font-weight: 700;
    line-height: 1.2;

    @include mq() {
      font-size: 22px;
    }
  }

  &-id {
    margin-top: 2px;
    color: palette('scheme', 'gray');
    font-size: 12px;
    font-weight: 700;

    @include mq() {
      margin: 2px 0 0 3px;
      font-size: 14px;
    }
  }

  &-link01 {
    display: inline-flex;
    flex-wrap: wrap;
    margin: 0 -5px;
    li {
      margin: 10px 5px 0;
    }
    &-button {
      display: inline-block;
      width: 36px;
      height: 36px;
      border-radius: 50%;
      color: #fff;
      font-size: 11px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
      text-decoration: none;
      &.-pixiv {
        background: palette('scheme', 'pixiv');
      }
      &.-syosetu {
        width: auto;
        height: 36px;
        padding: 0 6px;
        background: palette('scheme', 'syosetu');
        border-radius: 18px;
      }
    }

    @include mq() {
      margin: -10px 0 0 -10px;
      li {
        margin: 10px 0 0 10px;
      }
    }
    @include mq(lg) {
      &-button.-pixiv:hover,
      &-button.-syosetu:hover {
        opacity: .6;
      }
    }
  }

  &-user {
    margin-top: 17px;
    color: palette('scheme', 'gray');
    text-align: center;
    li {
      position: relative;
      display: inline-block;
      &::before {
        position: absolute;
        top: 50%;
        left: 0;
        color: palette('scheme', 'medium-gray');
        font-family: 'Font Awesome 5 Free';
        font-weight: 900;
        transform: translateY(-50%);
      }
    }

    @include mq() {
      margin: 10px 0 0 3px;
      text-align: left;
    }
  }

  &-follow {
    padding-left: 15px;
    &::before {
      content: '\f007';
      font-size: 13px;
    }
  }

  &-follower {
    margin-left: 12px;
    padding-left: 22px;
    &::before {
      content: '\f500';
      font-size: 15px;
    }
  }

  &-userCount {
    font-weight: 700;
  }

  &-profile {
    margin-top: 30px;
    font-size: 14px;

    @include mq() {
      font-size: 15px;
    }
  }

  &-update {
    margin-top: 5px;
    color: palette('scheme', 'gray');
    font-size: 12px;

    @include mq() {
      font-size: 13px;
    }
  }

  &-followButton {

    @include mq() {
      margin: 30px auto 0;
    }
  }

  &-link02 {
    margin: 40px -17px 0;

    @include mq() {
      margin: 40px auto 0;
    }
  }
}
.userpage-index-detail {
  position: relative;
  margin-top: 40px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -17px;
    width: calc(100% + 34px);
    height: 1px;
    background: palette('scheme', 'light-gray-primary');
  }

  @include mq() {
    margin-top: 80px;
    padding-top: 0;
    &::before {
      content: none;
    }
  }

  &-nav {
    position: relative;
    margin: 0 -17px;
    padding: 0 17px;
    text-align: left;
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: palette('scheme', 'light-gray-primary');
    }
    li {
      display: inline-block;
    }

    @include mq() {
      margin: 0;
      padding: 0;
    }
  }

  &-nav-anchor {
    position: relative;
    display: block;
    padding: 12px;
    font-size: 13px;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    > a {
      text-decoration: none;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      z-index: 2;
      left: 0;
      width: 100%;
      height: 2px;
      background: palette('scheme', 'theme');
      opacity: 0;
    }
    &.is-active::after {
      opacity: 1;
    }

    @include mq(lg) {
      padding: 12px 16px;
      &:hover::after {
        opacity: 1;
      }
    }
  }
}
