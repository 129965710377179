.withdrawal {
  &-header-title {
    padding: 13px 17px;
    border-bottom: 1px solid palette('scheme', 'light-gray-primary');
    font-size: 17px;
    font-weight: 700;
    text-align: center;
    letter-spacing: .075em;

    @include mq() {
      padding: 65px 35px 0;
      border-bottom: none;
      font-size: 22px;
    }
  }

  &-section {
    margin-top: 30px;
    padding: 0 17px 50px;
    font-size: 14px;
    & + & {
      padding-top: 30px;
      border-top: 14px solid palette('scheme', 'light-gray-primary');
    }
    &-inner {
      max-width: 600px;
      margin: 0 auto;
    }
    &-title {
      margin-bottom: 30px;
      font-size: 17px;
      font-weight: 700;
      text-align: center;
    }
    &-message {
      margin-bottom: 30px;
      font-size: 17px;
      font-weight: 400;
      line-height: 1.618;
      text-align: center;
    }

    @include mq() {
      margin-top: 60px;
      padding: 0 35px 80px;
      font-size: 15px;
      & + & {
        position: relative;
        padding-top: 60px;
        border-top: none;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 50%;
          width: 600px;
          height: 1px;
          background: palette('scheme', 'light-gray-primary');
          transform: translateX(-50%);
        }
      }
      &-title {
        font-size: 22px;
      }
    }

    &-notice{
      max-width: 600px;
      margin: 30px auto 0;

      @include mq() {
        margin: 50px auto 0;
      }
      @include mq(xl) {
        margin: 80px auto 0;
      }

      &-inner {
        margin: 0 -17px;

        @include mq() {
          margin: 0;
        }
      }

      &-block {
        margin-top: 30px;

        @include mq() {
          margin-top: 50px;
        }
      }

      &-title {
        padding: 0 17px 20px;
        border-bottom: 1px solid palette('scheme', 'light-gray-primary');
        font-size: 17px;
        text-align: center;

        @include mq() {
          padding: 0 17px 30px;
          font-size: 22px;
        }
      }

      &-row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 17px;
        border-bottom: 1px solid palette('scheme', 'light-gray-primary');
        font-size: 15px;
      }
    }
  }
}
