.creatorIndex-section {
  padding-top: 26px;
  & + & {
    position: relative;
    margin-top: 10px;
    padding-top: 40px;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: -17px;
      width: calc(100% + 34px);
      height: 14px;
      background: palette('scheme', 'light-gray-primary');
    }
  }

  @include mq() {
    padding-top: 50px;
    & + & {
      margin-top: 20px;
      padding-top: 80px;
      &::before {
        left: -35px;
        width: calc(100% + 70px);
      }
    }
  }
  @include mq(lg) {
    padding-top: 60px;
    & + & {
      margin: 0;
      padding-top: 90px;
      &::before {
        content: none;
      }
    }
  }

  &-header {
    display: flex;
    align-items: center;
  }

  &-title {
    flex: 1;
    padding-right: 15px;
    font-size: 17px;
    font-weight: 700;

    @include mq() {
      font-size: 22px;
    }
  }

  &-more-anchor {
    position: relative;
    display: block;
    width: 40px;
    height: 24px;
    font-size: 0;
    text-decoration: none;
    &::before {
      content: '\f105';
      position: absolute;
      top: 50%;
      right: 0;
      color: palette('scheme', 'theme');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      transform: translateY(-50%);
    }

    @include mq() {
      width: auto;
      height: auto;
      font-size: 15px;
      color: palette('scheme', 'theme');
      text-decoration: underline;
      &::before {
        content: none;
      }
    }
  }
}

.creatorIndex-invitation {
  margin: 58px auto 76px;
  padding-bottom: 0;
  text-align: center;
  @include mq() {
    margin: 40px auto 76px;
  }
  &-title {
    font-size: 24px;
    margin-bottom: 38px;
    @include mq() {
      font-size: 28px;
      margin-bottom: 45px;
    }
  }
  &-paragraph {
    font-size: 15px;
    line-height: 24px;
    text-align: left;
    margin: 0 10px;
    @include mq() {
      margin: 0;
      text-align: center;
    }
    & + .creatorIndex-invitation-paragraph {
      margin-top: 15px;
    }
  }
  &-link {
    margin-top: 38px;
    &-button{
      color: #fff;
      font-size: 15px;
      font-weight: bold;
      width: 284px;
      height: 48px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent linear-gradient(71deg, #F15352 42%, #FB9C10 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 6px 12px #00000029;
      border: none;
      border-radius: 24px;
      text-decoration: none;
      &:hover {
        opacity: 0.6;
      }
    }
  }
}

.creatorIndex-search {
  background-color: #ebebeb;
  padding: 80px 0;
}

.creatorIndex-sectionHeading {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
  position: relative;
  padding-bottom: 10px;
  &:after {
    content: "";
    display: block;
    width: 182px;
    height: 4px;
    margin: 0 auto;
    background: #F15352 0% 0% no-repeat padding-box;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
  }
  &.-wide:after {
    width: 194px;
  }
}

.creatorIndex-searchForm {
  margin: 64px auto 0;
  max-width: 1044px;
  padding: 0 22px;
}

.creatorIndex-latestWorks {
  margin: 80px auto 128px;
  max-width: 1202px;
  padding: 0 45px;
  box-sizing: content-box;
  @include mq() {
    margin: 80px auto 20px;
    padding: 0 20px;
  }
  &-list {
    margin-top: 64px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px 0;
    @include mq() {
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 0 22px;
    }
  }
}

.creatorIndex-workCard {
  &-image {
    border-radius: 16px;
    &-anchor {
      display: block;
      padding: 29px 36px;
      background: transparent linear-gradient(22deg, #d8d8d8 0%, #ebebeb 100%) 0% 0% no-repeat padding-box;
      border-radius: 16px;
      &:hover {
        opacity: 0.8;
      }
      img {
        width: 100%;
        height: auto;
        box-shadow: 6px 12px 12px #00000066;
      }
    }
  }
  &-detail {
    margin: 24px 5px 0;
    display: flex;
    flex-direction: column;
    @include mq() {
      margin: 24px 10px 0;
      min-height: 164px;
    }
  }
  &-title {
    font-size: 16px;
    margin-bottom: 12px;
  }
  &-anchor {
    text-decoration: none;
    &:hover {
      opacity: 0.6;
      text-decoration: underline;
    }
  }
  &-copy {
    font-size: 14px;
    @include mq() {
      margin-bottom: 38px;
    }
  }
}

.creatorIndex-loggedIn {
  max-width: 1202px;
  margin: 32px auto 0;
  padding: 0 20px;
  box-sizing: content-box;
  display: grid;
  grid-template-areas: "post"
                       "search"
                       "info";
  grid-gap: 24px 21px;
  @include mq(md) {
    grid-template-areas: "post info"
                         "search search";
    grid-template-columns: 386px 1fr;
    grid-template-rows: 1fr auto;
    grid-gap: 36px 22px;
    margin: 40px auto 20px;
  }
  @include mq(single) {
    grid-template-rows: 469px auto;
  }

  &-section {
    background: #ebebeb 0% 0% no-repeat;
    border-radius: 16px;
    padding: 40px 16px;
    @include mq() {
      padding: 40px 32px;
    }

    .creatorIndex-sectionHeading {
      margin-bottom: 40px;
      @include mq() {
        text-align: left;
        &:after {
          margin: 0;
        }
      }
    }
  }
}

.creatorIndex-loggedIn-post {
  grid-area: post;
  &-buttonWrapper {
    width: 100%;
  }
  &-button {
    + .creatorIndex-loggedIn-post-button {
      margin-top: 16px;
    }
    .c-button-secondary {
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      @include mq() {
        width: 322px;
      }
    }
    &-icon {
      font-size: 24px;
    }
    &-text {
      font-size: 15px;
      font-weight: bold;
      padding-left: 8px;
    }
  }
  &-bnr {
    width: 100%;
    margin: 40px 0 0;
    &-link {
      display: block;
    }
  }
}

.creatorIndex-loggedIn-info {
  grid-area: info;
  @include mq() {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &-button {
    font-size: 15px;
    font-weight: bold;
    width: 150px;
    height: 48px;
    padding: 0;
    background: #f15352 0% 0% no-repeat padding-box;
    box-shadow: 0px 6px 12px #00000029;
    border-radius: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    @include mq(md) {
      width: 100%;
      margin-bottom: 40px;
    }
    @include mq(single) {
      width: 150px;
      margin-bottom: 0;
    }
  }

  &-list {
    width: 100%;

    &-item {
      background: #fff 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-bottom: 16px;
      &:last-child {
        margin-bottom: 0;
      }
    }

    &-link {
      padding: 9px 12px 14px;
      text-decoration: none;
      display: block;
      position: relative;
      &:hover {
        opacity: .6;
      }
    }

    &-label {
      display: inline-block;
      padding: 5px 8px;
      background: #808080 0% 0% no-repeat padding-box;
      border-radius: 4px;
      font-size: 14px;
      font-weight: bold;
      color: #fff;
    }

    &-date {
      display: inline-block;
      font-size: 16px;
      font-weight: bold;
      padding-left: 16px;
    }

    &-title {
      font-size: 18px;
      font-weight: bold;
      margin-top: 11px;
      padding-right: 25px;
    }

    &-icon {
      position: absolute;
      top: 0;
      bottom: 0;
      margin: auto;
      right: 16px;
      width: 20px;
      height: 20px;
      background: #030303 0% 0% no-repeat padding-box;
      border-radius: 50%;
      color: #fff;
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.creatorIndex-loggedIn-search {
  grid-area: search;
}

.creatorIndex-works {
  &-image {
    width: 100%;
    border: 1px solid palette('scheme', 'light-gray-primary');
    &-anchor {
      position: relative;
      display: block;
    }
    img {
      width: 100%;
    }
    &-label {
      position: absolute;
      bottom: -1px;
      left: -1px;
      z-index: 2;
      width: calc(100% + 2px);
      padding: 1px 0 2px;
      background: palette('scheme', 'theme');
      color: #fff;
      font-size: 11px;
      letter-spacing: -.05em;
      text-align: center;
    }

    @include mq() {
      &-label {
        padding: 0 0 1px;
        font-size: 13px;
      }
    }
  }

  &-detail {
    margin-top: 5px;

    @include mq() {
      margin-top: 12px;
    }
  }

  &-title {
    font-size: 13px;
    font-weight: 700;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      font-size: 15px;
    }
    @include mq(lg) {
      font-size: 17px;
    }
  }

  &-copy {
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include mq() {
      margin-top: 3px;
      font-size: 14px;
      line-height: 1.4;
      white-space: normal;
      text-overflow: inherit;
      overflow: visible;
    }
  }
}

.creatorIndex-works01 {
  margin: 20px -17px 0 0;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  @include mq() {
    margin: 20px -35px 0 0;
  }
  @include mq(lg) {
    margin: 0;
    overflow: visible;
  }

  &-inner {
    display: flex;
    margin: 0 -7px;
    padding-bottom: 15px;

    @include mq() {
      margin: 0 -10px;
      padding-bottom: 30px;
    }
    @include mq(lg) {
      flex-wrap: wrap;
      margin: 0 -10px;
    }
  }

  .creatorIndex-works-item {
    flex: 0 0 calc(26.66667vw + 14px);
    max-width: 220px;
    padding: 0 7px;
    overflow: hidden;
    &:last-child {
      flex: 0 0 calc(26.66667vw + 24px);
      padding-right: 17px;
    }

    @include mq() {
      flex: 0 0 220px;
      padding: 0 10px;
      &:last-child {
        flex: 0 0 245px;
        max-width: 245px;
        padding-right: 35px;
      }
    }
    @include mq(lg) {
      flex: inherit;
      width: calc(16.66667% - 20px);
      margin: 25px 10px 0;
      padding: 0;
      &:last-child {
        flex: inherit;
        padding: 0;
      }
    }
  }
}

.creatorIndex-works02 {
  &-inner {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -10px;

    @include mq() {
      margin: -25px -15px 0;
    }
    @include mq(lg) {
      margin: -25px -10px 0;
    }
  }

  .creatorIndex-works-item {
    width: calc(33.33334% - 20px);
    margin: 25px 10px 0;

    @include mq() {
      width: calc(25% - 30px);
      margin: 50px 15px 0;
    }
    @include mq(lg) {
      width: calc(16.66667% - 20px);
      margin: 50px 10px 0;
    }
  }

  .creatorIndex-works-copy {
    display: none;

    @include mq() {
      display: block;
    }
  }
}
