/*---------------------------------
  react-modal
---------------------------------*/

.ReactModal__Overlay--after-open {
  z-index: 999;
}

/*---------------------------------
  DraftEditor / 本文
---------------------------------*/
// 画面外の領域もカーソルを合わせられるようにする
.DraftEditor-root,
.DraftEditor-editorContainer,
.public-DraftEditor-content {
  height: 100% !important;
  @media screen and (max-width: 959px) {
    height: auto !important;
  }
}

// テキストを選択させる
.DraftEditor-root * {
  -webkit-user-select: text !important;
  user-select: text !important;
}

.DraftEditor-root {
  display: flex;
  height: calc(100vh - 336px) !important;
  flex-direction: row-reverse;
  writing-mode: vertical-rl;
  line-height: 1.8;

  overflow-x: scroll;
  overflow-x: -moz-scrollbars-horizontal;
  overflow-y: hidden;

  /*スクロールバー全体*/
  scrollbar-width: 10px;
  &::-webkit-scrollbar {
    width: 10px;
  }

  /*スクロールバーの軌道*/
  &::-webkit-scrollbar-track {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, .1);
  }

  /*スクロールバーの動く部分*/
  &::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 50, .5);
  border-radius: 10px;
  box-shadow:0 0 0 1px rgba(255, 255, 255, .3);
  }

  @media screen and (max-width: 959px) {
    height: calc(100vh - 270px) !important;
    display: block;
    writing-mode: initial;
    overflow-x: initial;
    overflow-y: auto;
  }

  figure {
    margin: 0 10px;
  }

  // 地の文と同じスタイルになるように修正
  h1, h2, h3{
    font-weight: initial;
    font-size: inherit;
    line-height: 1.5;
    padding: 0;
    display: inline-block;

    > .public-DraftStyleDefault-block {
      margin: 0;
    }
  }

  ol, ul {
    margin: 0;
    list-style-type: none
  }

  li {
    margin-left: 0 !important;
    &::before {
      display: none;
    }
  }
}

.public-DraftStyleDefault-block {
  margin: 0 10px;
  span {
    line-height: 1.5;
    padding: 1px 0;
    display: inline-block;
    word-break: break-all;

    &.isFocus {
      background-color: #efefef;
    }
  }

  @media screen and (max-width: 959px) {
    font-size: 16px;
    margin: 0;
  }
}

.DraftEditor-editorContainer,
.public-DraftEditor-content {
  // overflow-x: scroll;
  line-height: 1.5;

  blockquote {
    color: #A5A5A5;
    &:nth-of-type(odd) {
      position: relative;
      margin: 0 0 0 10px;
      padding: 10px 5px 0 0;
      background-size: contain;
      background-repeat: no-repeat;
      border-right: 2px dotted #e5e5ea;
      border-top: 2px dotted #e5e5ea;
      border-bottom: 2px dotted #e5e5ea;
      // background-position-x: 5px;
      &:after {
        position: absolute;
        padding: 10px 0;
        content: "挿絵開始";
        bottom: 10px;
        right: 10px;
        background-color: #e5e5ea;
        color: #000000;
        @media screen and (max-width: 959px) {
          padding: 2px 7px;
          bottom: 0;
        }
      }
      @media screen and (max-width: 959px) {
        margin: 10px 0;
        padding: 10px 0 0 10px;
        border-right: 2px dotted #e5e5ea;
        border-left: 2px dotted #e5e5ea;
        border-top: 2px dotted #e5e5ea;
        border-bottom: none;
      }
    }

    &:nth-of-type(even) {
      position: relative;
      margin: 0 10px;
      padding: 10px 0 0 5px;
      background-size: contain;
      background-repeat: no-repeat;
      border-left: 2px dotted #e5e5ea;
      border-top: 2px dotted #e5e5ea;
      border-bottom: 2px dotted #e5e5ea;
      &:after {
        position: absolute;
        padding: 10px 0;
        content: "挿絵終了";
        bottom: 10px;
        right: 0px;
        background-color: #e5e5ea;
        color: #000000;
        @media screen and (max-width: 959px) {
          padding: 2px 7px;
          bottom: 5px;
          right: 5px;
        }
      }
      @media screen and (max-width: 959px) {
        margin: 10px 0;
        padding: 0 0 10px 10px;
        border-top: none;
        border-right: 2px dotted #e5e5ea;
        border-left: 2px dotted #e5e5ea;
        border-bottom: 2px dotted #e5e5ea;
      }
    }
  }
  @media screen and (max-width: 959px) {
    text-indent: 0;
  }
}

.isSelect {
  .public-DraftStyleDefault-block > span {
    opacity: 0.3;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }

  blockquote::after {
    opacity: 0.3;
    &:hover {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.isSelect_Safari {
  .public-DraftStyleDefault-block {
    &:hover {
     cursor: pointer;
     color: #eee;
   }
  }
}

.public-DraftEditorPlaceholder-root {
  writing-mode: vertical-rl;
  top: 2px;
  left: -8px;
  @media screen and (max-width: 959px) {
    writing-mode: initial;
    top: -4px;
    padding-left: 12px;
  }
}
.isHorizontal {
    display: block;
    writing-mode: initial;
    flex-direction: initial;
    overflow-x: hidden;
    overflow-y: scroll;
  .public-DraftEditorPlaceholder-root {
    top: 0;
    left: 0;
    writing-mode: initial;
  }
  .DraftEditor-editorContainer {
    width: 100%;

    blockquote {
      &:nth-of-type(odd) {
        margin: 5px 10px 5px 0;
        padding: 0 5px;
        border-left: 2px dotted #e5e5ea;
        border-bottom: none;
        &:after {
          padding: 0 0;
          bottom: -2px;
        }
      }
      &:nth-of-type(even) {
        margin: 5px 10px 5px 0;
        padding: 0 5px;
        border-top: none;
        border-left: 2px dotted #e5e5ea;
        border-right: 2px dotted #e5e5ea;
        &:after {
          padding: 0 0;
          bottom: 6px;
          right: 10px;
        }
      }
    }
  }
  .public-DraftStyleDefault-block {
    margin-left: 0;
  }
}


/*---------------------------------
  DraftEditor / タイトル
---------------------------------*/
.editorTitle {
  .DraftEditor-root,
  .DraftEditor-editorContainer,
  .public-DraftEditor-content {
    height: inherit !important;
  }

  .DraftEditor-root {
    font-size: 24px;
    @media screen and (max-width: 959px) {
      height: inherit !important;
    }
  }
  .public-DraftStyleDefault-block {
    margin: 0;
    span {
      line-height: 1.5;
    }
  }

  // placeholder
  .public-DraftEditorPlaceholder-root {
    writing-mode: vertical-rl;
    top: 2px;
    left: 2px;
    font-size: 24px;
    @media screen and (max-width: 959px) {
      writing-mode: initial;
      font-size: 18px;
      padding-left: 4px;
    }
  }

  .isHorizontal {
    .public-DraftEditorPlaceholder-root {
      top: 0;
      left: 0;
      writing-mode: initial;
    }
  }
}

