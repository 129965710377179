#__next {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 57px); /* ヘッダーの高さを差し引く */

  @media screen and (min-width: 768px) {
    min-height: 100vh;
  }
}

.-error {
  color: #E60012 !important;
}

// バリデーションエラー
.c-note-primary.-error {
  color: #E60012 !important;
}

input.-error, textarea.-error {
  border-color: #E60012 !important;
}

.p-creatorMypage-sideNav-anchor[aria-disabled="true"] {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
}

.p-creatorMypage-headerNav-anchor[aria-disabled="true"] {
  pointer-events: none;
  cursor: default;
  opacity: 0.5;
}

.p-dataList.-empty {
  padding: 70px 10px;
  margin-top: 0;
  border-top: none;
  border-bottom: 1px solid #e5e5ea;
  font-size: 15px;
  text-align: center;

  @media screen and (max-width: 768px) {
    padding: 50px 10px;
  }
}

.p-modal-action.-plate {
  width: 340px;

  @media screen and (max-width: 959px) {
    width: 100%;
  }
}

.p-modal-action-col.-plate {
  width: 180px;
}

.p-creatorNovelNotes-empty, .p-creatorNovelCharacters-empty {
  font-size: 15px;
}

.p-page-header {
  &-inner {
    position: relative;
  }

  &-icon {
    display: block;
    position: absolute;
    top: 50%;
    width: 32px;
    height: 32px;
    text-decoration: none;
    transform: translateY(-50%);
    &::before {
      position: absolute;
      top: 50%;
      left: 50%;
      color: palette('scheme', 'black');
      font-family: 'Font Awesome 5 Free';
      font-size: 17px;
      font-weight: 900;
      line-height: 1;
      transform: translate(-50%, -50%);
    }
    &.-back {
      left: 6px;
      &::before {
        content: '\f104';
      }
    }

    @media screen and (min-width: 768px) {
      &.-back {
        left: 24px;
      }

      &:hover {
        opacity: .6;
      }

    }
  }

  &.-mypage-edit-profile {
    margin-bottom: 0;
  }
}

/* イラスト詳細、小説詳細など、ヘッダがないページ用のスタイリング */
.p-mainColumn-without-header {
  margin-top: 80px;

  @media screen and (max-width: 959px) {
    margin-top: 30px;
  }
}

.creatorMypage-sideButtonWrapper {
  margin: 60px 0 0;

  .p-button {
    width: 300px;
  }
}

/** クリエイターTOPのバナー **/
.creatorIndex-bnrArea {
  padding-top: 40px;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    padding-top: 20px;
  }
}
.creatorIndex-bnrImage {
  width: 280px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &:last-of-type {
    margin-left: 20px;
  }

  > a {
    display: inline-block;
  }
}

.carousel-root {
  background: #1e1f22 !important;
}

.carousel .slide {
  padding: 0 5px;
  background: #1e1f22 !important;
}

.creatorMypage-chara {
  &-image {
    width: 38.75%;
    min-width: 100px;
    max-width: 160px;
    margin-bottom: 30px;
    &-inner {
      position: relative;
      min-height: 100px;
      border: 1px solid palette('scheme', 'light-gray-primary');
    }

    @media screen and (min-width: 768px) {
      width: 40%;
      max-width: 200px;
    }
  }
}

.creatorMypage-characterDetail-image {
  width: 38.75%;
  min-width: 100px;
  max-width: 160px;
  margin-bottom: 20px;

  @media screen and (min-width: 768px) {
    width: 40%;
    max-width: 200px;
  }
}

/* Rate Componentのスタイル */
.rc-rate {
  line-height: 1;
  .rc-rate-star {
    line-height: 1;
    margin-right: 4px;
  }
  .rc-rate-star-half .rc-rate-star-first,
  .rc-rate-star-full .rc-rate-star-second {
    color: #ffbb00;
  }
  .rc-rate-star-zero,
  .rc-rate-star-half .rc-rate-star-second {
    color: #c5c5c5;
  }
}
