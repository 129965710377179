.wrapper {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* モーダルよりも前面に表示 */

  > div {
    @media screen and (max-width: 768px) {
      transform: translateY(-113px);
    }
  }
}

.wrapper_initialized {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* モーダルよりも前面に表示 */
}
